import React, { useEffect, useState, useRef } from 'react';
import authService from '../api-authorization/AuthorizeService'
import ItemsList from '../ItemsList.js';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import utils from '../../utils';
import InputGroup from 'react-bootstrap/InputGroup';
import { NumericFormat } from 'react-number-format';
import Button from 'react-bootstrap/Button';


const UnitCosts = () => {
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [units, setUnits] = useState([]);
    const [modalTitle, setModalTitle] = useState('');
    const [unitCost, setUnitCost] = useState({});
    const updateList = useRef(true);
    const [validated, setValidated] = useState(false);
    const [costCodeValid, setCostCodeValid] = useState(true);
    const [disableInput, setDisableInput] = useState(true);

    useEffect(() => {
        if (loading) {
            const getItems = async () => {
                const token = await authService.getAccessToken();
                const response = await fetch(`api/settings/items`, {
                    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                });

                const data = await response.json();
                
                await setUnits(data.units);

                await setLoading(false);
            };
            getItems();
        }
    }, [loading]);

    const columns = [
        { id: 'costCode', name: 'Cost Code', sortable: true, filter: "search", selectId: null, labelFormat: 'clickToItem', aggregate: false },
        { id: 'description', name: 'Description', sortable: true, filter: "search", selectId: null, labelFormat: null, aggregate: false },
        { id: 'unit', name: 'Unit', sortable: true, filter: "select", selectId: "unitId", labelFormat: null, aggregate: false },
        { id: 'unitCost', name: 'Cost', sortable: true, filter: null, selectId: null, labelFormat: 'dollar', aggregate: false },
        { id: 'active', name: 'Active', sortable: true, filter: "select", selectId: "active", labelFormat: null, aggregate: false },
        // { id: 'userModified', name: 'User Modified', sortable: true, filter: "select", selectId: 'userModified', labelFormat: null, aggregate: false }
    ];

    const mapFunction = (q) => {
        return {
            id: q.id,
            costCode: q.costCode,
            description: q.description,
            unitId: q.unitId,
            unit: q.unit,
            unitCost: q.unitCost,
            active: q.active ? "Yes" : "No",
            userModified: q.userModified ? "Yes" : "No"
        }
    };   

    const shouldUpdateList = () => {
        if (updateList.current) {
            updateList.current = false;
            return true;
        }
        return false;
    };

    const handleEditItem = async (id) => {
        const token = await authService.getAccessToken();
        const response = await fetch(`api/settings/unitCost?id=${id}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });

        const data = await response.json();
        await setUnitCost(data);
        await setModalTitle('Edit Unit Cost');
        await setValidated(false);
        await setCostCodeValid(true);
        await setDisableInput(true);
        await setShowModal(true);
    };

    const handleAddItem = async () => {
        await setUnitCost({
            costCode: '',
            description: '',
            unitID: '',
            cost: '',
            active: true,
            userModified: true
        });
        await setModalTitle('Add Unit Cost');
        await setValidated(false);
        await setCostCodeValid(true);
        await setDisableInput(false);
        await setShowModal(true);
    };

    const handleSave = async (event) => {
        const form = event.currentTarget;

        event.preventDefault();

        if (form.checkValidity() === false) {
            event.stopPropagation();
            await setValidated(true);
            return;
        } 

        //const valid = await validateCostCode();
        //await setCostCodeValid(valid);
        //if (valid === false) {
        //    event.stopPropagation();
        //    await setValidated(true);
        //    return;
        //} 

        const token = await authService.getAccessToken();
        await fetch('api/settings/unitCost', {
            method: unitCost.hasOwnProperty('id') ? 'PUT' : 'POST',
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(unitCost),
        });

        updateList.current = true;
        await setShowModal(false);
    };

    const handleCancel = async () => {
        await setShowModal(false);
    };

    const validateCostCode = async () => {
        if (unitCost.costCode === '') return 'Cost Code';

        const token = await authService.getAccessToken();

        const response = await fetch(`api/settings/checkCostCode?costCode=${unitCost.costCode}`, {
            method: 'GET',
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        });
        const valid = await response.json();
        return valid;        
    };

    return (
        <React.Fragment>
            <Row className="align-items-center mb-3">
                <Col className="col-6">
                    <Card.Subtitle>
                        Unit Costs
                    </Card.Subtitle>
                </Col>
                <Col className="col-6 text-end">
                    <Button type="button" onClick={handleAddItem}>Add Unit Cost</Button>
                </Col>
            </Row>            
            <ItemsList columns={columns}
                itemName="Unit Cost"
                addItemUrl=""
                mapFunction={mapFunction}
                getItemsUrl="api/settings/unitCosts"
                uniqueIdColumn="id"
                showHeader={false}
                itemUrlBase=""
                shouldUpdate={true}
                editItemMethod={handleEditItem}
                shouldUpdateList={shouldUpdateList}
            />
            <Modal show={showModal} onHide={() => { setShowModal(false) }} backdrop="static">
                <Form noValidate validated={validated} onSubmit={handleSave}>
                    <Modal.Header closeButton>
                        <Modal.Title>{modalTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container>
                            <Row className="align-items-center">
                                <Col className="fw-bold col-4 p-2">Cost Code</Col>
                                <Col className="col-8 p-2">
                                    <Form.Control type="text" defaultValue={unitCost.costCode} required disabled={disableInput} onChange={(e) => {
                                        const item = utils.getCopy(unitCost);
                                        item.costCode = e.target.value;
                                        item.userModified = true;
                                        setUnitCost(item);
                                    }} />
                                </Col>
                                <Col className="fw-bold col-4 p-2">Description</Col>
                                <Col className="col-8 p-2">
                                    <Form.Control as="textarea" rows={2} defaultValue={unitCost.description} required disabled={disableInput} onChange={(e) => {
                                        const item = utils.getCopy(unitCost);
                                        item.description = e.target.value;
                                        item.userModified = true;
                                        setUnitCost(item);
                                    }} />
                                </Col>
                                <Col className="fw-bold col-4 p-2">Unit</Col>
                                <Col className="col-8 p-2">
                                    <Form.Select required value={unitCost.unitID} disabled={disableInput} onChange={async (e) => {
                                        const item = utils.getCopy(unitCost);
                                        item.unitID = parseInt(e.target.value);
                                        item.userModified = true;
                                        await setUnitCost(item);
                                    }} >
                                        <option disabled key="none" value="">Select a Unit</option>
                                        {units.map(q => <option key={q.id} value={q.id}>{q.name}</option>)}
                                    </Form.Select>
                                </Col>
                                <Col className="fw-bold col-4 p-2">Cost</Col>
                                <Col className="col-8 p-2">
                                    <InputGroup>
                                        <InputGroup.Text>$</InputGroup.Text>
                                        <NumericFormat className="form-control" decimalScale={2} fixedDecimalScale={true} required value={unitCost.cost}
                                            thousandSeparator="," onValueChange={async (values, sourceInfo) => {
                                                const item = utils.getCopy(unitCost);
                                                item.cost = values.floatValue;
                                                item.userModified = true;
                                                await setUnitCost(item);
                                            }}
                                        />                                    
                                    </InputGroup>
                                </Col>
                                <Col className="fw-bold col-4 p-2">Active</Col>
                                <Col className="col-8 p-2">
                                    <Form.Check type="switch" checked={unitCost.active} onChange={async (e) => {
                                        const item = utils.getCopy(unitCost);
                                        item.active = e.target.checked;
                                        item.userModified = true;
                                        await setUnitCost(item);
                                    }} />
                                </Col>
                                {unitCost.userModified && < Col className="col-12 p-2">This unit cost has been edited</Col>}
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Container>
                            <Row className="align-items-center">
                                <Col className="col-6">
                                    <Button type="submit">Save Changes</Button>
                                </Col>
                                <Col className="col-6 text-end">
                                    <Button type="button" variant="link" onClick={handleCancel}>Cancel</Button>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Footer>
                </Form>
            </Modal>
        </React.Fragment>
    );
}

export default UnitCosts;