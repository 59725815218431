export class Utils {
    getDollarString(number) {
        if (number == null) number = 0;
        number = Math.round(number);
        let formatting_options = {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
        };
        if (number < 0) {
            number *= -1;
            let formatted = '(' + number.toLocaleString("en-US", formatting_options) + ')'
            return (<div style={{ color: 'red' }} dangerouslySetInnerHTML={{ __html: formatted }} />)
        }
        return number.toLocaleString("en-US", formatting_options);
    }

    getPercentString(number) {
        if (number == null) number = 0;
        let formatting_options = {
            style: 'percent',
            minimumFractionDigits: 2,
        };
        return number.toLocaleString("en-US", formatting_options);
    }

    getSum(values) {
        let sum = 0;
        if (values.length > 0) {
            sum = values.reduce(function (a, b) {
                return a + b;
            });
        }
        return sum;
    }

    getCopy(item) {
        return JSON.parse(JSON.stringify(item));
    }

    getDateString(date) {
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();
        return `${year}-${month >= 10 ? month : '0' + month}-${day >= 10 ? day : '0' + day}`;
    }

    sortByName(a, b) {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    }
}

const utils = new Utils();

export default utils;

