import React, { useEffect, useState, useRef, useReducer } from 'react';
import authService from './api-authorization/AuthorizeService'
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import ImageUploading from "react-images-uploading";
import { HouseDoor } from 'react-bootstrap-icons';
import { BuildingFill } from 'react-bootstrap-icons';
import { CurrencyDollar } from 'react-bootstrap-icons';
import { CardChecklist } from 'react-bootstrap-icons';
import { Calendar3 } from 'react-bootstrap-icons';
import { GraphUpArrow } from 'react-bootstrap-icons';
import { ClockHistory } from 'react-bootstrap-icons';
import { Paperclip } from 'react-bootstrap-icons';
import { Building } from 'react-bootstrap-icons';
import './Asset.css';
import Stack from 'react-bootstrap/Stack';
import FormControl from 'react-bootstrap/FormControl';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import Modal from 'react-bootstrap/Modal';
import { AssetRecurringCosts } from './AssetRecurringCosts';
import { AssetRequirements } from './AssetRequirements';
import AssetSystemCosts from './AssetSystemCosts';
import AssetScenarioPlanning from './AssetScenarioPlanning';
import AssetPastProjects from './AssetPastProjects';
import { NumericFormat } from 'react-number-format';
import DatePicker from 'react-datepicker';
import utils from '../utils';

const Asset = () => {

    const [asset, setAsset] = useState(null);
    const [loading, setLoading] = useState(true);
    const { id } = useParams();
    const navigate = useNavigate();
    const [editing, setEditing] = useState(false);
    const [adding, setAdding] = useState(false);
    const [campuses, setCampuses] = useState(null);
    const [besUses, setBesUses] = useState(null);
    const [facilityUses, setFacilityUses] = useState(null);
    const [orientations, setOrientations] = useState(null);
    const [validated, setValidated] = useState(false);
    const [images, setImages] = useState([]);
    const maxImages = 1;
    const [validatedCRV, setValidatedCRV] = useState(false);
    const [user, setUser] = useState(null);
    const inputRef = useRef(null);
    const [crvRevisionHistories, setCrvRevisionHistories] = useState(null);
    const [attachmentTypes, setAttachmentTypes] = useState(null);
    const [attachments, setAttachments] = useState([]);
    const [currentAttachment, setCurrentAttachment] = useState(null);
    const [validatedAttachment, setValidatedAttachment] = useState(false);
    const [attachmentTypeIDs, setAttachmentTypeIDs] = useState([]);
    const [modifyingAttachment, setModifyingAttachment] = useState(false);
    const [viewingAttachment, setViewingAttachment] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
    const [queryParameters] = useSearchParams();
    const [key, setKey] = useState(queryParameters.get("tabKey") != null ? queryParameters.get("tabKey") : 'overview');

    const [attachmentsForDisplay, setAttachmentsForDisplay] = useState([]);
    const [attachmentPages, setAttachmentPages] = useState(1);
    const [attachmentPageNumber, setAttachmentPageNumber] = useState(1);
    const [attachmentPageSize, setAttachmentPageSize] = useState(10);

    const [minYear, setMinYear] = useState(0);
    const [maxYear, setMaxYear] = useState(0);

    const [assetSummaryReportUrl, setAssetSummaryReportUrl] = useState('');
    const [alertModalMessage, setAlertModalMessage] = useState('');
    const [showAlertModal, setShowAlertModal] = useState(false);

    useEffect(() => {
        if (loading) {
            async function populateAssetData() {
                if (id === 'add' || id === 'Add') {
                    var data = {
                        id: '',
                        globalAssetCode: '',
                        facilityName: '',
                        facilityCode: '',
                        initialCRV: 0,
                        facilityUseID: '',
                        campusID: '',
                        floorCount: 0,
                        footPrint: 0,
                        besTypeID: '',
                        besUseID: '',
                        yearBuilt: 0,
                        grossSquareFootage: 0,
                        currentCRV: 0,
                        visibility: true,
                        buildingNarrative: '',
                        addressLine1: '',
                        city: '',
                        state: '',
                        zip: '',
                        longitude: 0,
                        latitude: 0,
                        orientationID: '',
                    };

                    await setAsset(data);
                    await setEditing(true);
                    await setAdding(true);
                } else {
                    const token = await authService.getAccessToken();
                    const response = await fetch('api/assets/' + id, {
                        headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                    });

                    const data = await response.json();

                    var image;
                    if (data.imageUri !== null) {
                        image = { data_url: data.imageUri };

                        await setImages([image]);
                    }else if (data.image !== null) {
                        image = { data_url: "data:image/jpeg;base64," + data.image };

                        await setImages([image]);
                    }

                    if (data.assetCRVRevisionHistories !== null && data.assetCRVRevisionHistories !== undefined && data.assetCRVRevisionHistories.length > 0) {
                        let { 0: first, [data.assetCRVRevisionHistories.length - 1]: last } = data.assetCRVRevisionHistories;

                        //data.initialCRV = first.crv;
                        data.currentCRV = last.crv;
                    }

                    if (data.attachments !== null && data.attachments !== undefined) {
                        data.attachments.forEach((attachment) => {
                            if (attachment.dataUri != null) { attachment.data_url = attachment.dataUri }
                            else { attachment.data_url = attachment.dataType + 'base64,' + attachment.data }
                        }); 
                        await setAttachments(data.attachments);
                        await populateAttachmentsForDisplay(data.attachments, 1, attachmentPageSize, null);
                    } else {
                        data.attachments = [];
                    }

                    if (data.fiscalYearStartMonth === null ||
                        data.fiscalYearStartMonth === undefined ||
                        data.fiscalYearStartMonth < 1 ||
                        data.fiscalYearStartMonth > 12) {

                        // data.fiscalYearStartMonth = data.facilityUse.fiscalYearStartMonth;
                        // data.drRequirementsPercent = data.facilityUse.drRequirementsPercent;
                        // data.recurringCostsPercent = data.facilityUse.recurringCostsPercent;
                        // data.deteriorationRatePercent = data.facilityUse.deteriorationRatePercent;
                        // data.costEscalationRatePercent = data.facilityUse.costEscalationRatePercent;

                        // if (data.fiscalYearStartMonth < 1 ||
                        //    data.fiscalYearStartMonth > 12) {
                            data.fiscalYearStartMonth = 1;
                        //}
                    }
                    data.revisedCRV = data.currentCRV;
                    data.revisedCRVSF = (data.currentCRV / data.grossSquareFootage).toFixed(2);
                    await setAsset(data);

                    await setCrvRevisionHistories(data.assetCRVRevisionHistories)

                    const response2 = await fetch('api/assets/reportUrl', {
                        headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                    });
                    
                    const url = await response2.text();
                    await setAssetSummaryReportUrl(`${url}%27${id}%27`);
                }
            }

            async function populateCampuses() {
                const token = await authService.getAccessToken();
                const response = await fetch('api/campus/', {
                    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                });

                const data = await response.json();
                await setCampuses(data);
            }

            async function populateBesUses() {
                const token = await authService.getAccessToken();
                const response = await fetch('api/besuses/', {
                    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                });

                const data = await response.json();
                await setBesUses(data);
            }

            async function populateFacilityUses() {
                const token = await authService.getAccessToken();
                const response = await fetch('api/facilityuses/', {
                    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                });

                const data = await response.json();
                await setFacilityUses(data);
            }

            async function populateOrientations() {
                const token = await authService.getAccessToken();
                const response = await fetch('api/orientations/', {
                    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                });

                const data = await response.json();
                await setOrientations(data);
            }

            async function populateAttachmentTypes() {
                const token = await authService.getAccessToken();
                const response = await fetch('api/attachmenttypes/', {
                    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                });

                const data = await response.json();
                await setAttachmentTypes(data);

                var ids = data.map(at => (at.id));

                await setAttachmentTypeIDs(ids);
            }

            async function populateData() {
                await Promise.all([populateCampuses(), populateBesUses(), populateFacilityUses(),
                    populateOrientations(), populateAttachmentTypes(), populateAssetData()]);

                setLoading(false);
            }

            async function getAuth() {
                const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()]);

                if (!isAuthenticated) {
                    setUser('Invalid');
                } else {
                    setUser(user.name);
                }
            }

            const d = new Date();
            setMinYear(1800);
            setMaxYear(d.getFullYear());

            populateData();

            getAuth();
        }

    }, [loading]);

    const handleEditAsset = () => {
        setEditing(true);
    };

    const handleSaveAsset = (event) => {
        async function saveAssetData() {
            const token = await authService.getAccessToken();
            await fetch('api/assets/' + asset.id, {
                method: 'PUT',
                headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
                body: JSON.stringify(asset),
            });

            setValidated(false);
            setEditing(false);
            setLoading(true);
        };

        async function addAssetData() {
            const token = await authService.getAccessToken();
            const response = await fetch('api/assets', {
                method: 'POST',
                headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
                body: JSON.stringify(asset),
            });

            const data = await response.json();

            asset.id = data.id;

            var result = await saveCrvValue(asset.initialCRV, 'Initial CRV');
            var result2 = await saveCrvValue(asset.currentCRV, 'Current CRV');

            let path = "/assets/" + data.id;
            await navigate(path);

            await setEditing(false);
            await setAdding(false);
            await setLoading(true);
        };

        const form = event.currentTarget;

        event.preventDefault();

        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
            return;
        }

        asset.assetCRVRevisionHistories = [];
        asset.facilitySystems = [];
        asset.attachments = [];
        asset.image = null;
        asset.imageUri = null;

        if (images.length > 0) {
            if (images[0].data_url.split("base64,").length > 1) {
                asset.image = images[0].data_url.split("base64,")[1];
            }
            else {
                asset.imageUri = images[0].data_url;
            }            
        }

        if (adding) {
            addAssetData();
        } else {
            saveAssetData();
        }
    };

    const handleCancel = () => {
        setValidated(false);
        setEditing(false);

        if (adding) {
            let path = "/assets";
            navigate(path);

            setAdding(false)
        } else {
            setLoading(true);
        }
    };

    const onImagesChange = (imageList, addUpdateIndex) => {
        setImages(imageList);
    };

    const handleSaveCRV = async (event) => {
        const form = event.currentTarget;

        event.preventDefault();

        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidatedCRV(true);
            return;
        }

        // ensure that a component is selected
        if (asset.revisedCRV <= 0) {
            await setAlertModalMessage('Revised CRV/SF must be greater than $0.00');
            await setShowAlertModal(true);
            return;
        }

        saveCrvValue(asset.revisedCRV, asset.crvNotes, form);
    };

    const saveCrvValue = (crv, notes, form) => {
        async function addCrvData(crvHistory, form) {
            const token = await authService.getAccessToken();
            const response = await fetch('api/assets/crv', {
                method: 'POST',
                headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
                body: JSON.stringify(crvHistory),
            });

            const data = await response.json();

            if (!adding) {
                await asset.assetCRVRevisionHistories.push(data);

                let { 0: first, [asset.assetCRVRevisionHistories.length - 1]: last } = asset.assetCRVRevisionHistories;
                //asset.initialCRV = first.crv;
                asset.currentCRV = last.crv;

                let newAsset = asset;
                newAsset.crvNotes = '';
                await setAsset(newAsset);

                let newCrvs = asset.assetCRVRevisionHistories;
                await setCrvRevisionHistories([...newCrvs]);

                await setValidatedCRV(false);

                form.reset();
            }
        };

        const crvHistory = {
            assetID: asset.id,
            revisedBy: user,
            notes: notes,
            crv: crv,
            asset: asset
        }

        addCrvData(crvHistory, form);
    };

    const uploadedAttachment = (event) => {
        currentAttachment.name = event.target.files[0].name;

        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onloadend = function () {
            var base64data = reader.result;

            var newAttachment = currentAttachment;
            newAttachment.data_url = base64data;

            setCurrentAttachment(newAttachment);
        }
    }

    const handleCancelModifyAttachment = () => {
        setModifyingAttachment(false);
    }

    const handleDeleteClose = () => setShowDelete(false);
    const handleDeleteShow = () => setShowDelete(true);

    const handleDeleteAttachment = () => {
        handleDeleteClose();
        deleteAttachment(currentAttachment);
    }

    const deleteAttachment = (attachment) => {
        async function deleteAttachmentData(id) {
            const token = await authService.getAccessToken();
            await fetch('api/attachments/' + id, {
                method: 'DELETE',
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
            });

            let newAttachments = await attachments.filter(a => a.id !== id);

            await setAttachments([...newAttachments]);
            await populateAttachmentsForDisplay([...newAttachments], 1, attachmentPageSize, attachmentTypeIDs);

            await setModifyingAttachment(false);
        };

        deleteAttachmentData(attachment.id);
    };

    const handleAddAttachment = () => {

        var data = {
            id: 0,
            data: '',
            dataType: '',
            description: '',
            name: '',
            attachmentTypeID: 1,
        };

        setCurrentAttachment(data);
        setModifyingAttachment(true);
    }

    const handleSaveAttachment = (event) => {
        const form = event.currentTarget;

        event.preventDefault();

        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidatedAttachment(true);
            return;
        }

        setValidatedAttachment(false);

        saveAttachment(currentAttachment);
    };

    const saveAttachment = (attachment) => {
        async function saveAttachmentData(attachment) {
            const token = await authService.getAccessToken();
            await fetch('api/attachments/' + attachment.id, {
                method: 'PUT',
                headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
                body: JSON.stringify(attachment),
            });

            await setModifyingAttachment(false);
        };

        async function addAttachment(attachment) {

            const token = await authService.getAccessToken();
            const response = await fetch('api/attachments', {
                method: 'POST',
                headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
                body: JSON.stringify(attachment),
            });

            const data = await response.json();
            data.data_url = data.dataType + 'base64,' + data.data;

            await asset.attachments.push(data);

            let newAsset = asset;
            await setAsset(newAsset);

            let newAttachments = asset.attachments;
            await setAttachments([...newAttachments]);
            await populateAttachmentsForDisplay([...newAttachments], 1, attachmentPageSize, attachmentTypeIDs);
            await setModifyingAttachment(false);
        };

        var splitData = attachment.data_url.split("base64,");

        attachment.assetID = asset.id;
        attachment.data = splitData[1];
        attachment.dataType = splitData[0];

        if (attachment.id === 0) {
            addAttachment(attachment);
        } else {
            saveAttachmentData(attachment);
        }
    };

    const handleView = (attachment) => (event) => {
        setCurrentAttachment(attachment);
        setModifyingAttachment(true);
        setViewingAttachment(true);
    }

    const handleBackGallery = () => {
        setViewingAttachment(false);
        setModifyingAttachment(false);
    }

    const handleEditAttachment = () => {
        setViewingAttachment(false);
    }

    const getLocalDateString = (date) => {
        var localDate = new Date(date);
        return localDate.toLocaleString();
    };

    /*const assetUpdate = () => {
        forceUpdate();
    }*/

    const goToAttachmentPage = async (pageNumber) => {
        await setAttachmentPageNumber(pageNumber);
        await populateAttachmentsForDisplay(attachments, pageNumber, attachmentPageSize, attachmentTypeIDs);
    };

    const updateAttachmentPageSize = async (event) => {
        await populateAttachmentsForDisplay(attachments, 1, event.target.value, attachmentTypeIDs);
    };

    const populateAttachmentsForDisplay = async (attachmentSource, pageNumber, pageSize, typeIDs) => {
        await setAttachmentsForDisplay(attachmentSource.filter((a) => typeIDs == null || typeIDs.includes(a.attachmentTypeID)).slice(pageSize * (pageNumber - 1), pageSize * pageNumber));
        await setAttachmentPageSize(pageSize);
        await setAttachmentPageNumber(pageNumber);
        if (typeIDs != null) {
            await setAttachmentTypeIDs(typeIDs);
        }
        await setAttachmentPages(Math.ceil(attachmentSource.filter((a) => typeIDs == null || typeIDs.includes(a.attachmentTypeID)).length / pageSize));
    };

    let minPage = attachmentPageNumber === 1 ? 1 : attachmentPageNumber - 1;
    const maxPage = attachmentPages < minPage + 2 ? attachmentPages : minPage + 2;
    if (maxPage === attachmentPageNumber && maxPage > 2) {
        minPage = maxPage - 2;
    }
    let attachmentPageNumbers = [];
    for (let i = minPage - 1; i < maxPage; i++) {
        attachmentPageNumbers.push(i + 1);
    }
    return loading ? (
        <p><em>Loading...</em></p>
    ) : (
        <React.Fragment>
            <div>
                <Card>
                    <Card.Body>
                        <Card.Title>
                            <Breadcrumb>
                                <Breadcrumb.Item href="/assets">Assets</Breadcrumb.Item>
                                {adding ?
                                    <Breadcrumb.Item active>Add Asset</Breadcrumb.Item>
                                    :
                                    <Breadcrumb.Item active>{asset.facilityName}</Breadcrumb.Item>
                                }
                            </Breadcrumb>
                        </Card.Title>
                        <Container>
                            <Row>
                                <Col xs="auto">
                                    <Table borderless>
                                        <tbody>
                                            <tr>
                                                <th>Facility Code</th>
                                                <td style={{ minWidth: '100px' }}>{!adding && asset.facilityCode}</td>
                                                <th>Initial CRV</th>
                                                <td style={{ minWidth: '100px' }}>{!adding && utils.getDollarString(asset.initialCRV)}</td>
                                                <th>Gross SF</th>
                                                <td style={{ minWidth: '100px' }}>{!adding && (asset.grossSquareFootage).toLocaleString()}</td>
                                                <th>Year Built</th>
                                                <td style={{ minWidth: '100px' }}>{!adding && asset.yearBuilt}</td>

                                            </tr>
                                            <tr>
                                                <th>Facility Use</th>
                                                <td>{!adding && asset.facilityUse.name}</td>
                                                <th>Current CRV</th>
                                                <td>{!adding && utils.getDollarString(asset.currentCRV)}</td>
                                                <th>CRV / SF</th>
                                                <td>{!adding && utils.getDollarString((asset.currentCRV / asset.grossSquareFootage))}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                    </Col>
                                    {!adding && 
                                        <Col className="text-end">
                                            <a href={assetSummaryReportUrl} rel="noreferrer" target="_blank">View Asset Detail Report</a>
                                        </Col>    
                                    }                                    
                            </Row>
                        </Container>
                    </Card.Body>
                </Card>
                <p></p>
                <Tab.Container id="left-tabs" defaultActiveKey="overview" activeKey={key} onSelect={(k) => setKey(k)}>
                    <Row>
                        <Col sm={3}>
                            <Card>
                                <Card.Body>
                                    <Nav variant="pills" className="flex-column">
                                        <Nav.Item>
                                            <Nav.Link eventKey="overview"><HouseDoor /> Overview</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="crv" disabled={adding}><BuildingFill /> CRV</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="systems" disabled={adding}><CurrencyDollar /> Systems</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="requirements" disabled={adding}><CardChecklist /> Requirements</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="recurringCosts" disabled={adding}><Calendar3 /> Recurring Costs</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="scenarioPlanning" disabled={adding}><GraphUpArrow /> Scenario Planning</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="pastProjects" disabled={adding}><ClockHistory /> Past Projects</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="photosDocs" disabled={adding}><Paperclip /> Photos and Documents</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col sm={9}>
                            <Card>
                                <Card.Body>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="overview">
                                            <Form noValidate validated={validated} onSubmit={handleSaveAsset}>
                                                <div className="d-grid gap-3">
                                                    <Row>
                                                        <Col sm={8}>
                                                            <div className={editing ? "d-grid gap-3" : "d-grid gap-1"}>
                                                                <Form.Group as={Row} controlId="formFacilityCode">
                                                                    <Form.Label column sm={4}>Facility Code</Form.Label>
                                                                    <Col sm={8}>
                                                                        <Form.Control required type="Text" plaintext={!editing} readOnly={!editing} defaultValue={asset.facilityCode}
                                                                            onChange={({ target: { value } }) => asset.facilityCode = value}
                                                                        />
                                                                    </Col>
                                                                </Form.Group>
                                                                <Form.Group as={Row} controlId="formFacilityName">
                                                                    <Form.Label column sm={4}>Facility Name</Form.Label>
                                                                    <Col sm={8}>
                                                                        <Form.Control required type="Text" plaintext={!editing} readOnly={!editing} defaultValue={asset.facilityName}
                                                                            onChange={({ target: { value } }) => asset.facilityName = value}
                                                                        />
                                                                    </Col>
                                                                </Form.Group>
                                                                {adding ?
                                                                    <div className={editing ? "d-grid gap-3" : "d-grid gap-1"}>
                                                                        <Form.Group as={Row} controlId="formInitialCRV">
                                                                            <Form.Label column sm={4}>Initial CRV</Form.Label>
                                                                            <Col sm={8}>
                                                                                <InputGroup>
                                                                                    <InputGroup.Text>$</InputGroup.Text>
                                                                                    <Form.Control required type="Text" plaintext={!editing} readOnly={!editing} defaultValue={asset.initialCRV}
                                                                                        as={NumericFormat} thousandSeparator={true} decimalScale={2} fixedDecimalScale allowNegative={false}
                                                                                        onValueChange={(values) => { const { floatValue } = values; asset.initialCRV = Number(floatValue); }}
                                                                                        pattern="(?!0\.00)^\d{1,3}(?:,\d{3})*\.\d*$"
                                                                                    />
                                                                                </InputGroup>
                                                                            </Col>
                                                                        </Form.Group>
                                                                        <Form.Group as={Row} controlId="formCurrentCRV">
                                                                            <Form.Label column sm={4}>Current CRV</Form.Label>
                                                                            <Col sm={8}>
                                                                                <InputGroup>
                                                                                    <InputGroup.Text>$</InputGroup.Text>
                                                                                    <Form.Control required type="Text" plaintext={!editing} readOnly={!editing} defaultValue={asset.currentCRV}
                                                                                        as={NumericFormat} thousandSeparator={true} decimalScale={2} fixedDecimalScale allowNegative={false}
                                                                                        onValueChange={(values) => { const { floatValue } = values; asset.currentCRV = Number(floatValue); }}
                                                                                        pattern="(?!0\.00)^\d{1,3}(?:,\d{3})*\.\d*$"
                                                                                    />
                                                                                </InputGroup>
                                                                            </Col>
                                                                        </Form.Group>
                                                                    </div>
                                                                    :
                                                                    <div></div>
                                                                }
                                                                <Form.Group as={Row} controlId="formFacilityUse">
                                                                    <Form.Label column sm={4}>Facility Use</Form.Label>
                                                                    <Col sm={8}>
                                                                            <Form.Select type="Text" required disabled={!editing} defaultValue={asset.facilityUseID}
                                                                                onChange={({ target: { value } }) => asset.facilityUseID = Number(value)}>
                                                                                <option value='' disabled>Select a Facility Use...</option>
                                                                            {facilityUses.map(facilityUse => <option value={facilityUse.id}>{facilityUse.name}</option>)}
                                                                        </Form.Select>
                                                                    </Col>
                                                                </Form.Group>
                                                                <Form.Group as={Row} controlId="formCampus">
                                                                    <Form.Label column sm={4}>Campus</Form.Label>
                                                                    <Col sm={8}>
                                                                            <Form.Select type="Text" required disabled={!editing} defaultValue={asset.campusID}
                                                                                onChange={({ target: { value } }) => asset.campusID = Number(value)}>
                                                                                <option value='' disabled>Select a Campus...</option>
                                                                            {campuses.map(campus => <option value={campus.id}>{campus.name}</option>)}
                                                                        </Form.Select>
                                                                    </Col>
                                                                </Form.Group>
                                                                <Form.Group as={Row} controlId="formFloorCount">
                                                                    <Form.Label column sm={4}>Floor Count</Form.Label>
                                                                    <Col sm={8}>
                                                                        <Form.Control required type="Text" plaintext={!editing} readOnly={!editing} defaultValue={asset.floorCount}
                                                                            onChange={({ target: { value } }) => asset.floorCount = Number(value)}
                                                                            pattern="^(0|[1-9][0-9]?)$" />
                                                                    </Col>
                                                                </Form.Group>
                                                                {editing &&
                                                                    <Form.Group as={Row} controlId="formGrossSquareFootage">
                                                                        <Form.Label column sm={4}>Gross SF</Form.Label>
                                                                        <Col sm={8}>
                                                                            <InputGroup>
                                                                                <Form.Control required type="Text" plaintext={!editing} readOnly={!editing} defaultValue={asset.grossSquareFootage}
                                                                                    onChange={({ target: { value } }) => asset.grossSquareFootage = Number(value)}
                                                                                    pattern="^[1-9]\d*$" />
                                                                                <InputGroup.Text>SF</InputGroup.Text>
                                                                            </InputGroup>
                                                                        </Col>
                                                                    </Form.Group>}
                                                                <Form.Group as={Row} controlId="formFootPrint">
                                                                    <Form.Label column sm={4}>Footprint</Form.Label>
                                                                    {editing ?
                                                                        <Col sm={8}>
                                                                            <InputGroup>
                                                                                <Form.Control required type="Text" plaintext={!editing} readOnly={!editing} defaultValue={asset.footPrint}
                                                                                    onChange={({ target: { value } }) => asset.footPrint = Number(value)}
                                                                                    pattern="^(?:(?:0{1,2}|[1-9]\d*)?\.\d{1,2}|[1-9]\d*|0)$" />
                                                                                <InputGroup.Text>SF</InputGroup.Text>
                                                                            </InputGroup>
                                                                        </Col>
                                                                        :
                                                                        <Form.Label column sm={4}>{(asset.footPrint).toLocaleString()} SF</Form.Label>
                                                                    }
                                                                </Form.Group>
                                                                <Form.Group as={Row} controlId="formBesTypeUse">
                                                                    <Form.Label column sm={4}>BES Use</Form.Label>
                                                                    <Col sm={4}>
                                                                            <Form.Select type="Text" required disabled={!editing} defaultValue={asset.besUseID}
                                                                                onChange={({ target: { value } }) => asset.besUseID = Number(value)}>
                                                                                <option value='' disabled>Select a Use...</option>
                                                                            {besUses.map(besUse => <option value={besUse.id}>{besUse.name}</option>)}
                                                                        </Form.Select>
                                                                    </Col>
                                                                </Form.Group>
                                                                <Form.Group as={Row} controlId="formYearBuilt">
                                                                    <Form.Label column sm={4}>Year Built</Form.Label>
                                                                    <Col sm={4}>
                                                                        <DatePicker className="form-control" required disabled={!editing}
                                                                            selected={new Date((asset.yearBuilt + 1).toString())} showYearPicker dateFormat="yyyy"
                                                                            minDate={new Date((minYear + 1).toString())} maxDate={new Date((maxYear + 1).toString())}
                                                                            onChange={(date) => {
                                                                                let newAsset = structuredClone(asset);
                                                                                newAsset.yearBuilt = date == null ? '' : date.getFullYear();
                                                                                setAsset(newAsset);
                                                                            }}
                                                                        />
                                                                    </Col>
                                                                </Form.Group>
                                                            </div>
                                                        </Col>
                                                        <Col sm={4}>
                                                            <ImageUploading
                                                                value={images}
                                                                onChange={onImagesChange}
                                                                maxNumber={maxImages}
                                                                dataURLKey="data_url"
                                                                acceptType={["jpg"]}
                                                            >
                                                                {({
                                                                    imageList,
                                                                    onImageUpload,
                                                                    onImageRemoveAll,
                                                                    onImageUpdate
                                                                }) => (
                                                                    <div>
                                                                        {imageList.length === 0 ?
                                                                            <div style={{
                                                                                textAlign: 'center'
                                                                            }} >
                                                                                <Building horiz color="lightgray" size={250} />
                                                                            </div>
                                                                            :
                                                                            imageList.map((image, index) => (
                                                                                <div key={index} className="image-item">
                                                                                    <Image rounded src={image.data_url} alt="" fluid height={250} />
                                                                                </div>
                                                                            ))
                                                                        }
                                                                        <p></p>
                                                                        {editing ?
                                                                            <div>
                                                                                {imageList.length === 0 ?
                                                                                    <Button type="button" onClick={onImageUpload}>Change Photo</Button>
                                                                                    :
                                                                                    <Button type="button" onClick={() => onImageUpdate(0)}>Change Photo</Button>
                                                                                }
                                                                                <Button type="button" variant="link" onClick={onImageRemoveAll}>Remove Photo</Button>
                                                                            </div>
                                                                            :
                                                                            <div></div>
                                                                        }
                                                                    </div>
                                                                )}
                                                            </ImageUploading>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Form.Group sm={10} controlId="formBuildingNarrative">
                                                            <Form.Label>Building Narrative</Form.Label>
                                                            <Form.Control as="textarea" rows={4} readOnly={!editing} defaultValue={asset.buildingNarrative}
                                                                onChange={({ target: { value } }) => asset.buildingNarrative = value}
                                                            />
                                                        </Form.Group>
                                                    </Row>
                                                    <Card>
                                                        <Card.Header>Location Information</Card.Header>
                                                        <Card.Body>
                                                            <Row>
                                                                <Col sm="6">
                                                                    <div className={editing ? "d-grid gap-3" : "d-grid gap-1"}>
                                                                        <Form.Group as={Row} controlId="formAddress1">
                                                                            <Form.Label column sm={3}>Address</Form.Label>
                                                                            <Col sm={9}>
                                                                                <Form.Control required type="Text" plaintext={!editing} readOnly={!editing} defaultValue={asset.addressLine1}
                                                                                    onChange={({ target: { value } }) => asset.addressLine1 = value}
                                                                                />
                                                                            </Col>
                                                                        </Form.Group>
                                                                        <Form.Group as={Row} controlId="formCity">
                                                                            <Form.Label column sm={3}>City</Form.Label>
                                                                            <Col sm={9}>
                                                                                <Form.Control required type="Text" plaintext={!editing} readOnly={!editing} defaultValue={asset.city}
                                                                                    onChange={({ target: { value } }) => asset.city = value}
                                                                                />
                                                                            </Col>
                                                                        </Form.Group>
                                                                        <Form.Group as={Row} controlId="formState">
                                                                            <Form.Label column sm={3}>State</Form.Label>
                                                                            <Col sm={6}>
                                                                                <Form.Control type="Text" plaintext={!editing} readOnly={!editing} defaultValue={asset.state}
                                                                                    onChange={({ target: { value } }) => asset.state = value.trim()}
                                                                                    pattern="^[a-z A-Z]*$"
                                                                                />
                                                                            </Col>
                                                                        </Form.Group>
                                                                        <Form.Group as={Row} controlId="formZip">
                                                                            <Form.Label column sm={3}>Zip</Form.Label>
                                                                            <Col sm={6}>
                                                                                <Form.Control type="Text" plaintext={!editing} readOnly={!editing} defaultValue={asset.zip}
                                                                                    onChange={({ target: { value } }) => asset.zip = value}
                                                                                    pattern="^[0-9]{5}(-[0-9]{4})?$"
                                                                                />
                                                                            </Col>
                                                                        </Form.Group>
                                                                    </div>
                                                                </Col>
                                                                <Col sm="6">
                                                                    <div className={editing ? "d-grid gap-3" : "d-grid gap-1"}>
                                                                        {editing ?
                                                                            <div className="d-grid gap-3">
                                                                                <Form.Group as={Row} controlId="formLatitude">
                                                                                    <Form.Label column sm={4}>Latitude</Form.Label>
                                                                                    <Col sm={6}>
                                                                                        <InputGroup>
                                                                                            <Form.Control type="Text" as={NumericFormat} defaultValue={asset.latitude}
                                                                                                decimalScale={6} fixedDecimalScale
                                                                                                onChange={({ target: { value } }) => asset.latitude = Number(value)}
                                                                                                pattern="^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$"
                                                                                            />
                                                                                            <InputGroup.Text>&deg;</InputGroup.Text>
                                                                                        </InputGroup>
                                                                                    </Col>
                                                                                </Form.Group>
                                                                                <Form.Group as={Row} controlId="formLongitude">
                                                                                    <Form.Label column sm={4}>Longitude</Form.Label>
                                                                                    <Col sm={6}>
                                                                                        <InputGroup>

                                                                                            <Form.Control type="Text" as={NumericFormat} defaultValue={asset.longitude}
                                                                                                decimalScale={6} fixedDecimalScale
                                                                                                onChange={({ target: { value } }) => asset.longitude = Number(value)}
                                                                                                pattern="^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$"
                                                                                            />
                                                                                            <InputGroup.Text>&deg;</InputGroup.Text>
                                                                                        </InputGroup>
                                                                                    </Col>
                                                                                </Form.Group>
                                                                            </div>
                                                                            :
                                                                            <Form.Group as={Row} controlId="formLatLong">
                                                                                <Form.Label column sm={4}>Lat/Long</Form.Label>
                                                                                <Form.Label column sm={8}>{(asset.latitude).toFixed(6)}&deg; / {(asset.longitude).toFixed(6)}&deg;</Form.Label>
                                                                            </Form.Group>
                                                                        }
                                                                        <Form.Group as={Row} controlId="formOrientation">
                                                                            <Form.Label column sm={4}>Orientation</Form.Label>
                                                                            <Col sm={8}>
                                                                                <Form.Select type="Text" disabled={!editing} required defaultValue={asset.orientationID}
                                                                                        onChange={({ target: { value } }) => asset.orientationID = Number(value)}>
                                                                                        <option value='' disabled>Select an Orientation...</option>
                                                                                    {orientations.map(orientation => <option value={orientation.id}>{orientation.name}</option>)}
                                                                                </Form.Select>
                                                                            </Col>
                                                                        </Form.Group>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Card.Body>
                                                    </Card>
                                                    <Row>
                                                        <Col>
                                                            {editing ?
                                                                <div>
                                                                    <Button type="submit">Save Asset</Button>{' '}
                                                                    <Button type="button" variant="link" onClick={handleCancel}>Cancel</Button>
                                                                </div>
                                                                :
                                                                <Button type="button" variant="primary" onClick={handleEditAsset}>Edit Asset</Button>
                                                            }
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Form>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="crv">
                                            {adding ?
                                                <div></div>
                                                :
                                                <div>
                                                    <Form>
                                                        <div className="d-grid gap-2">
                                                            <Form.Group as={Row} controlId="formGrossSF">
                                                                <Form.Label column sm={3}>Gross SF</Form.Label>
                                                                <Col sm={8}>
                                                                    <Form.Control type="Text" plaintext readOnly placeholder={(asset.grossSquareFootage).toLocaleString()} />
                                                                </Col>
                                                            </Form.Group>
                                                            <Form.Group as={Row} controlId="formInitialCRV">
                                                                <Form.Label column sm={3}>Initial CRV</Form.Label>
                                                                <Col sm={8}>
                                                                    <Form.Control type="Text" plaintext readOnly placeholder={utils.getDollarString(asset.initialCRV)} />
                                                                </Col>
                                                            </Form.Group>
                                                            <Form.Group as={Row} controlId="formCurrentCRV">
                                                                <Form.Label column sm={3}>Current CRV</Form.Label>
                                                                <Col sm={8}>
                                                                    <Form.Control type="Text" plaintext readOnly placeholder={utils.getDollarString(asset.currentCRV)} />
                                                                </Col>
                                                            </Form.Group>
                                                            <Form.Group as={Row} controlId="formCRVSF">
                                                                <Form.Label column sm={3}>CRV / SF</Form.Label>
                                                                <Col sm={8}>
                                                                    <Form.Control type="Text" plaintext readOnly placeholder={utils.getDollarString(asset.currentCRV / asset.grossSquareFootage)} />
                                                                </Col>
                                                            </Form.Group>
                                                            <Form.Group as={Row} controlId="formBenchmarkCRVSF">
                                                                <Form.Label column sm={3}>Benchmark CRV / SF</Form.Label>
                                                                <Col sm={8}>
                                                                    <Form.Control type="Text" plaintext readOnly placeholder={utils.getDollarString(asset.facilityUse.benchmarkCRV)} />
                                                                </Col>
                                                            </Form.Group>
                                                        </div>
                                                    </Form>
                                                    <p></p>
                                                    {<Card>
                                                        <Card.Header>Revise CRV</Card.Header>
                                                        <Card.Body>
                                                            <Form noValidate validated={validatedCRV} onSubmit={handleSaveCRV}>
                                                                <Row>
                                                                    <Col sm="6">
                                                                        <Form.Group as={Row} controlId="formRevisedCRVSF">
                                                                            <Form.Label column sm={6}>Revised CRV/SF</Form.Label>
                                                                            <Col sm={6}>
                                                                                <InputGroup>
                                                                                        <InputGroup.Text>$</InputGroup.Text>
                                                                                        <NumericFormat className="form-control" decimalScale={2} fixedDecimalScale={true} value={asset.revisedCRVSF}
                                                                                            thousandSeparator="," allowNegative={false} onValueChange={async (values, sourceInfo) => {
                                                                                                const item = utils.getCopy(asset);
                                                                                                item.revisedCRVSF = values.floatValue;
                                                                                                item.revisedCRV = values.floatValue * asset.grossSquareFootage;
                                                                                                await setAsset(item);
                                                                                                inputRef.current.value = utils.getDollarString(item.revisedCRV);
                                                                                            }
                                                                                            } />  
                                                                                    <InputGroup.Text>/ SF</InputGroup.Text>
                                                                                </InputGroup>
                                                                            </Col>
                                                                        </Form.Group>
                                                                        <Form.Group as={Row} controlId="formRevisedCRV">
                                                                            <Form.Label column sm={6}>Revised CRV</Form.Label>
                                                                            <Col sm={6}>
                                                                                <InputGroup>
                                                                                    <Form.Control ref={inputRef} type="Text" plaintext readOnly defaultValue={utils.getDollarString(asset.currentCRV)} />
                                                                                </InputGroup>
                                                                            </Col>
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col sm="6">
                                                                        <Form.Group as={Row} controlId="formCRVNotes">
                                                                            <Form.Control required as="textarea" rows={3} defaultValue={asset.crvNotes} placeholder="Revised CRV Notes"
                                                                                onChange={({ target: { value } }) => asset.crvNotes = value} />
                                                                        </Form.Group>
                                                                    </Col>
                                                                </Row>
                                                                <Button type="submit">
                                                                    Save Revised CRV
                                                                </Button>
                                                            </Form>
                                                        </Card.Body>
                                                    </Card>}
                                                    <p></p>
                                                    <h5>Revision History</h5>
                                                    <Table striped>
                                                        <thead>
                                                            <tr>
                                                                <th>Revision Date</th>
                                                                <th>Revised By</th>
                                                                <th>Revised CRV</th>
                                                                <th>Revision Notes</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {crvRevisionHistories.map(revision =>
                                                                <tr>
                                                                    <td>{getLocalDateString(revision.revisionDate)}</td>
                                                                    <td>{revision.revisedBy}</td>
                                                                    <td>{utils.getDollarString(revision.crv)}</td>
                                                                    <td>{revision.notes}</td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            }
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="systems">
                                            {adding ?
                                                null
                                                :
                                                <AssetSystemCosts asset={asset} />
                                            }
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="requirements">
                                            {adding ?
                                                null
                                                :

                                                <AssetRequirements assetId={id} />
                                            }
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="recurringCosts">
                                            {adding ?
                                                null
                                                :

                                                <AssetRecurringCosts assetId={id} />
                                            }
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="scenarioPlanning">
                                            {adding ?
                                                null
                                                :
                                                <AssetScenarioPlanning asset={asset} />
                                            }
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="pastProjects">
                                            {adding ?
                                                null
                                                :
                                                <AssetPastProjects asset={asset} />
                                            }
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="photosDocs">
                                            {adding ?
                                                null
                                                :
                                                <div>
                                                    {!modifyingAttachment ?
                                                        <Container>
                                                            <Row>
                                                                <Stack direction="horizontal" gap={3}>
                                                                    <ToggleButtonGroup type="checkbox" defaultValue={attachmentTypeIDs}
                                                                        onChange={async (value) => {
                                                                            await populateAttachmentsForDisplay(attachments, 1, attachmentPageSize, value);
                                                                            setAttachmentTypeIDs(value)
                                                                        }}>
                                                                        {
                                                                            attachmentTypes.map(attachmentType =>
                                                                                <ToggleButton id={"tgb" + attachmentType.id} variant="outline-primary" value={attachmentType.id}>
                                                                                    {attachmentType.name + 's'}
                                                                                </ToggleButton>
                                                                            )
                                                                        }
                                                                    </ToggleButtonGroup>
                                                                    <Button type="button" variant="primary" className="ms-auto" onClick={handleAddAttachment}>Add Attachment</Button>
                                                                </Stack>
                                                            </Row>
                                                            <p></p>
                                                            <Row xs={1} md={3} className="g-4">
                                                                {attachments.length === 0 ?
                                                                    <Building color="lightgray" size={300} />
                                                                    :
                                                                    attachmentsForDisplay.map((attachment, index) => (
                                                                        <Col key={index}>
                                                                            <Card>
                                                                                {(attachment.data_url.toLowerCase().startsWith('data:image') ||
                                                                                    attachment.data_url.toLowerCase().includes('.jpg?') ||
                                                                                    attachment.data_url.toLowerCase().includes('.jpeg?') ||
                                                                                    attachment.data_url.toLowerCase().includes('.png?') ||
                                                                                    attachment.data_url.toLowerCase().includes('.bmp?') ||
                                                                                    attachment.data_url.toLowerCase().includes('.gif?')) ?
                                                                                    <Card.Img variant="top" height="200" src={attachment.data_url} style={{
                                                                                        objectFit: 'cover'
                                                                                    }} />
                                                                                    :
                                                                                    <Card.Header className="text-center" height="200">
                                                                                        <Paperclip color="lightgray" size={183} />
                                                                                    </Card.Header>
                                                                                }
                                                                                <Card.Body>
                                                                                    <Card.Subtitle className="text-truncate">{attachment.name}</Card.Subtitle>
                                                                                    <p></p>
                                                                                    <Card.Text className="text-truncate">
                                                                                        {attachment.description}
                                                                                    </Card.Text>
                                                                                    <Card.Link href={attachment.data_url} download={attachment.name}>Download</Card.Link>
                                                                                    <Card.Link style={{ cursor: "pointer" }} onClick={handleView(attachment)}>View</Card.Link>
                                                                                </Card.Body>
                                                                            </Card>
                                                                        </Col>
                                                                    ))
                                                                }
                                                            </Row>
                                                            {attachments.length > 0 &&
                                                                <Row className="justify-content-center justify-content-sm-between align-items-sm-center mt-5">
                                                                    <div class="col-sm mb-2 mb-sm-0">
                                                                        <div class="d-flex justify-content-center justify-content-sm-start align-items-center">
                                                                            <select class="js-select form-select form-select-borderless w-auto" aria-label="" onChange={updateAttachmentPageSize} value={attachmentPageSize}>
                                                                                <option value="10">10</option>
                                                                                <option value="20">20</option>
                                                                                <option value="30">30</option>
                                                                                <option value="50">50</option>
                                                                            </select>
                                                                            <span>&nbsp;Attachments per page</span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-sm-auto">
                                                                        <nav>
                                                                            <ul class="pagination">
                                                                                <li className={`page-item ${attachmentPageNumber === 1 ? 'disabled' : ''}`} >
                                                                                    <a class="page-link" aria-label="Previous" onClick={async () => { await goToAttachmentPage(attachmentPageNumber - 1) }}>
                                                                                        <span aria-hidden="true">&laquo;</span>
                                                                                    </a>
                                                                                </li>
                                                                                {attachmentPageNumbers.map(pageNumber =>
                                                                                    <li className={`page-item ${pageNumber === attachmentPageNumber ? 'active' : ''}`}>
                                                                                        <a class="page-link" onClick={async () => { await goToAttachmentPage(pageNumber) }}>{pageNumber}</a>
                                                                                    </li>
                                                                                )}
                                                                                <li className={`page-item ${attachmentPageNumber === attachmentPages ? 'disabled' : ''}`}>
                                                                                    <a class="page-link" aria-label="Next" onClick={async () => { await goToAttachmentPage(attachmentPageNumber + 1) }}>
                                                                                        <span aria-hidden="true">&raquo;</span>
                                                                                    </a>
                                                                                </li>
                                                                            </ul>
                                                                        </nav>
                                                                    </div>
                                                                </Row>
                                                            }
                                                        </Container>
                                                        :
                                                        <Form noValidate validated={validatedAttachment} onSubmit={handleSaveAttachment}>
                                                            <div className="d-grid gap-2">
                                                                {viewingAttachment ?
                                                                    <Row>
                                                                        <Stack direction="horizontal" gap={0}>
                                                                            <Button type="button" variant="link" onClick={handleBackGallery}><b>Back to Gallery</b></Button>
                                                                            <Button type="button" variant="primary" className="ms-auto" onClick={handleEditAttachment}>Edit Attachment</Button>
                                                                        </Stack>
                                                                    </Row>
                                                                    :
                                                                    null
                                                                }

                                                                <Form.Group as={Row} controlId="formAttachmentType">
                                                                    <Form.Label column sm={2}>Type</Form.Label>
                                                                    <Col sm={3}>
                                                                        <Form.Select type="Text" disabled={viewingAttachment} defaultValue={currentAttachment.attachmentTypeID}
                                                                            onChange={({ target: { value } }) => currentAttachment.attachmentTypeID = Number(value)}>
                                                                            {
                                                                                attachmentTypes.map(attachmentType =>
                                                                                    <option value={attachmentType.id}>{attachmentType.name}</option>
                                                                                )
                                                                            }
                                                                        </Form.Select>
                                                                    </Col>
                                                                </Form.Group>
                                                                <Form.Group as={Row} controlId="formAttachmentDesc">
                                                                    <Form.Label column sm={2}>Description</Form.Label>
                                                                    <Col sm={5}>
                                                                        <Form.Control required as="textarea" maxlength="1024" rows={3} disabled={viewingAttachment} defaultValue={currentAttachment.description} placeholder="Document Description"
                                                                            onChange={({ target: { value } }) => currentAttachment.description = value}
                                                                        />
                                                                    </Col>
                                                                </Form.Group>
                                                                <Form.Group as={Row} controlId="formAttachmentFile">
                                                                    <Form.Label column sm={2}>File</Form.Label>
                                                                    <Col sm={5}>
                                                                        {viewingAttachment ?
                                                                            <Form.Control type="Text" plaintext disabled value={currentAttachment.name} />
                                                                            :
                                                                            <div>
                                                                                {currentAttachment.name.length > 0 ?
                                                                                    <Form.Control type="Text" plaintext disabled value={currentAttachment.name} />
                                                                                    :
                                                                                    null
                                                                                }

                                                                                <FormControl required={currentAttachment.name.length === 0} type="file" onChange={uploadedAttachment} />
                                                                            </div>
                                                                        }
                                                                    </Col>
                                                                </Form.Group>
                                                                {!viewingAttachment ?
                                                                    <Row>
                                                                        <Stack direction="horizontal" gap={1}>
                                                                            <Button type="submit">Save Changes</Button>
                                                                            <Button type="button" variant="link" onClick={handleCancelModifyAttachment}>Cancel</Button>
                                                                            {currentAttachment.name.length > 0 ?
                                                                                <Button type="button" variant="link" className="ms-auto" onClick={handleDeleteShow}>Delete Attachment</Button>
                                                                                :
                                                                                null
                                                                            }
                                                                            <Modal show={showDelete} onHide={handleDeleteClose}>
                                                                                <Modal.Header closeButton>
                                                                                    <Modal.Title>Delete Attachment</Modal.Title>
                                                                                </Modal.Header>
                                                                                <Modal.Body>Are you sure you want to delete this attachment?</Modal.Body>
                                                                                <Modal.Footer>
                                                                                    <Button variant="secondary" onClick={handleDeleteAttachment}>
                                                                                        Yes
                                                                                    </Button>
                                                                                    <Button variant="primary" onClick={handleDeleteClose}>
                                                                                        No
                                                                                    </Button>
                                                                                </Modal.Footer>
                                                                            </Modal>
                                                                        </Stack>
                                                                    </Row>
                                                                    :
                                                                    null
                                                                }
                                                                    {viewingAttachment && (currentAttachment.data_url.startsWith('data:image') ||
                                                                        currentAttachment.data_url.toLowerCase().includes('.jpg?') ||
                                                                        currentAttachment.data_url.toLowerCase().includes('.jpeg?') ||
                                                                        currentAttachment.data_url.toLowerCase().includes('.png?') ||
                                                                        currentAttachment.data_url.toLowerCase().includes('.bmp?') ||
                                                                        currentAttachment.data_url.toLowerCase().includes('.gif?'))
                                                                         ?
                                                                    <Row>
                                                                        <Col sm={2} />
                                                                        <Col sm={10}>
                                                                            <Image rounded src={currentAttachment.data_url} alt="" style={{ maxWidth: '100%' }} />
                                                                        </Col>
                                                                    </Row>
                                                                    :
                                                                    <div></div>
                                                                }
                                                            </div>
                                                        </Form>
                                                    }
                                                </div>
                                            }
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    </Tab.Container>
                    <Modal size="sm" show={showAlertModal} onHide={() => { setShowAlertModal(false) }} backdrop="static">
                        <Modal.Header closeButton>
                            <Modal.Title>Alert</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>{alertModalMessage}</Modal.Body>
                        <Modal.Footer>
                            <Container>
                                <Row>
                                    <Col className="col-12 text-end">
                                        <Button onClick={() => {
                                            setShowAlertModal(false);
                                        }}>OK</Button>
                                    </Col>
                                </Row>
                            </Container>
                        </Modal.Footer>
                    </Modal>
                <p></p>
            </div>
        </React.Fragment>
    );
}

export default Asset;