import React, { Component } from 'react';
import utils from '../utils';

export class RecurringCostsGroupedByYear extends Component {
    static displayName = RecurringCostsGroupedByYear.name;

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (<div class="card">
            <div class="card-body">
                <table className="table table-striped" aria-labelledby="tableLabel">
                    <thead>
                        <tr>
                            <th>Fiscal Year</th>
                            <th colSpan="2">Discrete Recurring Costs</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.items.map(q =>
                            <tr>
                                <td>
                                    <button type="button" class="btn btn-link btn-sm" onClick={() => { this.props.selectYear(q.year); }}>{q.year}</button>
                                </td>
                                <td>{utils.getDollarString(q.total)}</td>
                                <td>{q.count}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>);
    }
}