import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Stack from 'react-bootstrap/Stack';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Legend, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';

const AssetScenarioPlanningCharts = (param) => {

    const [loading, setLoading] = useState(true);
    const [asset, setAsset] = useState(param.asset);
    const [chart1Data, setChart1Data] = useState(null);
    const [chart2Data, setChart2Data] = useState(null);
    const [chart1Labels, setChart1Labels] = useState(null);
    const [chart2Labels, setChart2Labels] = useState(null);

    // Add a new data structure here to contain the modified
    // charting data - multiple values per model year entry

    // Modify useEffect below (or another one) to re-populate
    // the charting data on change in asset

    useEffect(() => {
        var data1 = [];
        var data2 = [];
        var labels1 = [];
        var labels2 = [];

        // Scenario - the current model in the spending plan
        // asset.scenarios[0] - no spending scenario
        // asset.scenarios[1] - spending model
        // asset.scenarios [2...] - additional scenarios

        param.asset.scenarios.forEach((scenario, scenarioIndex) => {
            if (scenarioIndex !== 1) {
                if (data1.length < 1) {
                    scenario.scenarioYears.forEach(scenarioYear => {
                        let entry = { year: scenarioYear.year };
                        entry[scenario.name] = scenarioYear.fci;
                        data1.push(entry);
                        data2.push(entry);
                    })
                    labels1.push(scenario.name);
                } else {
                    scenario.scenarioYears.forEach((scenarioYear, index) => {
                        var entry = data2[index];
                        entry[scenario.name] = scenarioYear.fci;
                    });
                }

                labels2.push(scenario.name);
            }
        });

        param.scenario.scenarioYears.forEach((scenarioYear, index) => {
            var entry = data1[index];
            entry["Spending Model"] = scenarioYear.fci;
        });

        labels1.push("Spending Model");

        setChart1Data(data1);
        setChart1Labels(labels1);

        setChart2Data(data2);
        setChart2Labels(labels2);

        setLoading(false);
    }, [loading, param]);

    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#B4009E', '#61D6D6', '#767676', '#7F3300', '#FF7F7F', '#7FC9FF', '#000000', '#D67FFF'];

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        if (percent === 0) {
            return null;
        }

        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

    return loading ? (
        <p><em>Loading...</em></p>
    ) : (
        <React.Fragment>
            <Container>
                <Row>
                    <Col sm={7}>
                        <Row>
                            <ResponsiveContainer width="100%" height={450} >
                                <LineChart data={chart1Data} margin={{ top: 0, left: 5, right: 5, bottom: 100 }}>
                                    {chart1Labels.map((entry, index) =>
                                        <Line type="monotone" key={index} dataKey={entry} stroke={COLORS[index % COLORS.length]} />
                                    )}
                                    <CartesianGrid stroke="#ccc" />
                                    <XAxis dataKey="year"
                                        label={{
                                            value: 'Fiscal Year',
                                            style: { textAnchor: 'middle' },
                                            angle: 0,
                                            position: 'bottom',
                                            offset: 20,
                                        }}
                                        tick={{
                                            angle: -90,
                                        }}
                                        tickMargin={20}
                                    />
                                    <YAxis label={{
                                        value: 'FCI',
                                        style: { textAnchor: 'middle' },
                                        angle: -90,
                                        position: 'left',
                                        offset: 0,
                                    }}
                                    />
                                    <Legend verticalAlign="bottom" wrapperStyle={{ position: 'relative' }} height={35} />
                                </LineChart>
                            </ResponsiveContainer>
                        </Row>
                        <Row>
                            <ResponsiveContainer width="100%" height={450}  >
                                <LineChart data={chart2Data} margin={{ top: 0, left: 5, right: 5, bottom: 100 }}>
                                    {chart2Labels.map((entry, index) =>
                                        <Line type="monotone" key={index} dataKey={entry} stroke={COLORS[index % COLORS.length]} />
                                    )}
                                    <CartesianGrid stroke="#ccc" />
                                    <XAxis dataKey="year"
                                        label={{
                                            value: 'Fiscal Year',
                                            style: { textAnchor: 'middle' },
                                            angle: 0,
                                            position: 'bottom',
                                            offset: 20,
                                        }}
                                        tick={{
                                            angle: -90,
                                        }}
                                        tickMargin={20}
                                    />
                                    <YAxis label={{
                                        value: 'FCI',
                                        style: { textAnchor: 'middle' },
                                        angle: -90,
                                        position: 'left',
                                        offset: 0,
                                    }}
                                    />
                                    <Legend verticalAlign="bottom" wrapperStyle={{ position: 'relative' }} height={35} />
                                </LineChart>
                            </ResponsiveContainer>
                        </Row>
                    </Col>
                    <Col sm={5}>
                        <h6 style={{ marginLeft: '50px' }}>Requirements</h6>
                        <ResponsiveContainer width="100%" height={500}  >
                            <PieChart>
                                <Pie
                                    data={asset.scenarioRequirements}
                                    cx="50%"
                                    cy="50%"
                                    labelLine={false}
                                    label={renderCustomizedLabel}
                                    outerRadius={80}
                                    fill="#8884d8"
                                    dataKey="cost"
                                    name="action"
                                >
                                    {asset.scenarioRequirements.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                    ))}
                                </Pie>
                                <Legend layout="vertical" payload={
                                    asset.scenarioRequirements.map(
                                        (item, index) => ({
                                            id: item.id,
                                            color: COLORS[index % COLORS.length],
                                            value: item.system,
                                        })
                                    )
                                } />
                            </PieChart>
                        </ResponsiveContainer>

                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
}

export default AssetScenarioPlanningCharts;