import React, { useEffect, useState } from 'react';
import authService from './api-authorization/AuthorizeService'
import ItemsList from './ItemsList.js';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DatePicker from 'react-datepicker';
import { NumericFormat } from 'react-number-format';

const AssetPastProjects = (param) => {

    const [loading, setLoading] = useState(true);
    const [asset, setAsset] = useState(param.asset);
    const [addEditing, setAddEditing] = useState(0);
    const [validated, setValidated] = useState(false);
    const [activePastProject, setActivePastProject] = useState(null);
    const [projectTypes, setProjectTypes] = useState([]);
    const [projectDrivers, setProjectDrivers] = useState([]);
    const [projectFundingSources, setProjectFundingSources] = useState([]);
    const [minYear, setMinYear] = useState(0);
    const [maxYear, setMaxYear] = useState(0);

    useEffect(() => {
        async function populateProjectTypes() {
            const token = await authService.getAccessToken();
            const response = await fetch('api/pastprojects/types', {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });

            const data = await response.json();
            await setProjectTypes(data);
        }

        async function populateProjectDrivers() {
            const token = await authService.getAccessToken();
            const response = await fetch('api/pastprojects/drivers', {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });

            const data = await response.json();
            await setProjectDrivers(data);
        }

        async function populateProjectFundingSources() {
            const token = await authService.getAccessToken();
            const response = await fetch('api/pastprojects/fundingsources', {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });

            const data = await response.json();
            await setProjectFundingSources(data);
        }
        async function populateData() {
            await Promise.all([populateProjectTypes(), populateProjectDrivers(),
            populateProjectFundingSources()]);

            setAsset(param.asset);
            setLoading(false);
        }

        const d = new Date();
        setMinYear(1900);
        setMaxYear(d.getFullYear());

        populateData();
    }, [loading, param]);

    const columns = [
        { id: 'year', name: 'Year Completed', sortable: true, filter: null, selectId: null, labelFormat: null, aggregate: false },
        { id: 'projectType', name: 'Type', sortable: true, filter: 'select', selectId: 'projectTypeId', labelFormat: null, aggregate: false },
        { id: 'narrative', name: 'Narrative', sortable: true, filter: null, selectId: null, labelFormat: 'clickToItem', aggregate: false },
        { id: 'designCost', name: 'Design Cost', sortable: true, filter: null, selectId: null, labelFormat: 'dollar', aggregate: true },
        { id: 'constructionCost', name: 'Construction Cost', sortable: true, filter: null, selectId: null, labelFormat: 'dollar', aggregate: true },
        { id: 'totalCost', name: 'Total Cost', sortable: true, filter: null, selectId: null, labelFormat: 'dollar', aggregate: true },
    ];

    const mapFunction = (q) => {

        let narrative = q.narrative;

        if (narrative.length > 50) {
            narrative = narrative.substring(0, 50) + "…";
        }

        return {
            id: q.id,
            year: q.completedYear,
            projectType: q.pastProjectType.name,
            projectTypeId: q.pastProjectType.id,
            narrative: narrative,
            designCost: q.designCost,
            constructionCost: q.constructionCost,
            totalCost: q.totalProjectCost,
        }
    }

    const handleAddPastProject = () => {
        let addVals = {
            completedYear: 2023,
            pastProjectTypeID: 1,
            pastProjectDriverID: 1,
            pastProjectFundingSourceID: 1,
            designCost: 0,
            constructionCost: 0,
            totalProjectCost: 0,
            narrative: "",
            assetID: asset.id
        }

        setActivePastProject(addVals);
        setAddEditing(1);
    };

    const handleSaveChanges = (event) => {
        async function addPastProjectData() {
            const token = await authService.getAccessToken();
            const response = await fetch('api/pastprojects', {
                method: 'POST',
                headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
                body: JSON.stringify(activePastProject),
            });

            const data = await response.json();

            await asset.pastProjects.push(data);

            setAddEditing(0);
        };

        async function editPastProjectData() {
            const token = await authService.getAccessToken();
            await fetch('api/pastprojects/' + activePastProject.id, {
                method: 'PUT',
                headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
                body: JSON.stringify(activePastProject),
            });

            setAddEditing(0);
        };

        const form = event.currentTarget;

        event.preventDefault();

        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
            return;
        }

        setValidated(false);

        if (addEditing === 1) {
            addPastProjectData();
        } else {
            let index = asset.pastProjects.findIndex(p => p.id === activePastProject.id);
            asset.pastProjects.splice(index, 1, activePastProject);

            editPastProjectData();
        }
    }

    const handleEditPastProject = (id) => {
        let pid = Number(id);

        var pastProjects = asset.pastProjects.filter(p => p.id === pid);

        if (pastProjects.length > 0) {
            setActivePastProject(pastProjects[0]);
            setAddEditing(2);
        }
    }

    return loading ? (
        <p><em>Loading...</em></p>
    ) : (
        <React.Fragment>
            {addEditing === 0 ?
                <div>
                    <ItemsList columns={columns}
                        itemName="Past Project"
                        useAddItemUrl={false}
                        addItemMethod={handleAddPastProject}
                        mapFunction={mapFunction}
                        getItemsUrl={`api/assets/pastprojects?id=${asset.id}`}
                        uniqueIdColumn="id"
                        showHeader={true}
                        itemUrlBase="pastprojects"
                        shouldUpdate={true}
                        editItemMethod={handleEditPastProject}
                    />
                </div>
                :
                <div>
                    <Form noValidate validated={validated} onSubmit={handleSaveChanges}>
                        <div className="d-grid gap-3">
                            <Form.Group as={Row} controlId="formYear">
                                <Form.Label column sm={3}>Year Completed</Form.Label>
                                <Col sm={2}>
                                    <DatePicker className="form-control" required
                                        selected={new Date((activePastProject.completedYear + 1).toString())} showYearPicker dateFormat="yyyy"
                                        minDate={new Date((minYear + 1).toString())} maxDate={new Date((maxYear + 1).toString())}
                                        onChange={(date) => {
                                            let newPastProject = structuredClone(activePastProject);
                                            newPastProject.completedYear = date == null ? '' : date.getFullYear();
                                            setActivePastProject(newPastProject);
                                        }}
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="formType">
                                <Form.Label column sm={3}>Project Type</Form.Label>
                                <Col sm={4}>
                                    <Form.Select required type="Text" defaultValue={activePastProject.pastProjectTypeID}
                                        onChange={({ target: { value } }) => activePastProject.pastProjectTypeID = parseInt(value)}>
                                        {projectTypes.map(projectType => <option value={projectType.id}>{projectType.name}</option>)}
                                    </Form.Select>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="formDriver">
                                <Form.Label column sm={3}>Project Driver</Form.Label>
                                <Col sm={4}>
                                    <Form.Select requiredtype="Text" defaultValue={activePastProject.pastProjectDriverID}
                                        onChange={({ target: { value } }) => activePastProject.pastProjectDriverID = parseInt(value)}>
                                        {projectDrivers.map(projectDriver => <option value={projectDriver.id}>{projectDriver.name}</option>)}
                                    </Form.Select>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="formFundingSource">
                                <Form.Label column sm={3}>Project Funding Source</Form.Label>
                                <Col sm={4}>
                                    <Form.Select requiredtype="Text" defaultValue={activePastProject.pastProjectFundingSourceID}
                                        onChange={({ target: { value } }) => activePastProject.pastProjectFundingSourceID = parseInt(value)}>
                                        {projectFundingSources.map(projectFundingSource => <option value={projectFundingSource.id}>{projectFundingSource.name}</option>)}
                                    </Form.Select>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="formDesignCost">
                                <Form.Label column sm={3}>Design Cost</Form.Label>
                                <Col sm={3}>
                                    <InputGroup>
                                        <InputGroup.Text>$</InputGroup.Text>
                                        <Form.Control type="Text" required as={NumericFormat} thousandSeparator={true}
                                            defaultValue={activePastProject.designCost} decimalScale={2} fixedDecimalScale
                                            allowNegative={false}
                                            onValueChange={(values) => {
                                                const { floatValue } = values;
                                                activePastProject.designCost = Number(floatValue);
                                            }}
                                            isAllowed={(values) => {
                                                const { floatValue } = values;
                                                return floatValue <= 10000000000;
                                            }}
                                            pattern="^\d{1,3}(?:,\d{3})*\.\d*$"
                                        />
                                    </InputGroup>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="formConstructionCost">
                                <Form.Label column sm={3}> Construction Cost</Form.Label>
                                <Col sm={3}>
                                    <InputGroup>
                                        <InputGroup.Text>$</InputGroup.Text>
                                        <Form.Control type="Text" required as={NumericFormat} thousandSeparator={true}
                                            defaultValue={activePastProject.constructionCost} decimalScale={2} fixedDecimalScale
                                            allowNegative={false}
                                            onValueChange={(values) => {
                                                const { floatValue } = values;
                                                activePastProject.constructionCost = Number(floatValue);
                                            }}
                                            isAllowed={(values) => {
                                                const { floatValue } = values;
                                                return floatValue <= 10000000000;
                                            }}
                                            pattern="^\d{1,3}(?:,\d{3})*\.\d*$"
                                        />
                                    </InputGroup>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="formTotalProjectCost">
                                <Form.Label column sm={3}>Total Project Cost</Form.Label>
                                <Col sm={3}>
                                    <InputGroup>
                                        <InputGroup.Text>$</InputGroup.Text>
                                        <Form.Control type="Text" required as={NumericFormat} thousandSeparator={true}
                                            defaultValue={activePastProject.totalProjectCost} decimalScale={2} fixedDecimalScale
                                            allowNegative={false}
                                            onValueChange={(values) => {
                                                const { floatValue } = values;
                                                activePastProject.totalProjectCost = Number(floatValue);
                                            }}
                                            isAllowed={(values) => {
                                                const { floatValue } = values;
                                                return floatValue <= 10000000000;
                                            }}
                                            pattern="^\d{1,3}(?:,\d{3})*\.\d*$"
                                        />
                                    </InputGroup>
                                </Col>
                            </Form.Group>
                            <Form.Group sm={10} controlId="formNarrative">
                                <Form.Label>Narrative</Form.Label>
                                <Col sm={7}>
                                    <Form.Control required as="textarea" rows={4} defaultValue={activePastProject.narrative}
                                        onChange={({ target: { value } }) => activePastProject.narrative = value}
                                    />
                                </Col>
                            </Form.Group>
                            <Col sm={7}>
                                <Stack direction="horizontal" gap={1}>
                                    <Button type="submit" >
                                        Save Changes
                                    </Button>
                                    <Button className="ms-auto" variant="link" onClick={() => { setAddEditing(0) }} >
                                        Cancel
                                    </Button>
                                </Stack>
                            </Col>
                        </div>
                    </Form>
                </div>
            }
        </React.Fragment>
    );
}

export default AssetPastProjects;