import React, { useEffect, useState, useRef, useReducer } from 'react';
import authService from './api-authorization/AuthorizeService'
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import InputGroup from 'react-bootstrap/InputGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import Tab from 'react-bootstrap/Tab';
import AssetScenarioPlanningModel from './AssetScenarioPlanningModel';
import AssetScenarioPlanningCharts from './AssetScenarioPlanningCharts';
import AssetScenarioPlanningScenarios from './AssetScenarioPlanningScenarios';

const AssetScenarioPlanning = (param) => {

    const [loading, setLoading] = useState(true);
    const [asset, setAsset] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [validated, setValidated] = useState(false);
    const recurringRef = useRef(null);
    const [editVals, setEditVals] = useState([]);
    const [, forceUpdate] = useReducer(x => x + 1, 0);
    const [scenario, setScenario] = useState(null);
    const [activeKey, setActiveKey] = useState('model');

    useEffect(() => {
        async function getTable() {
            return populateTable([]);
        }

        async function getInitialScenarios() {
            let data = await Promise.all([
                populateRequirements(),
                populateRecurringCosts(),
                populateScenarios()
            ]);

            param.asset.scenarioRequirements = data[0];
            param.asset.scenarioRecurringCosts = data[1];
            param.asset.scenarios = data[2];

            param.asset.scenarios.forEach(scenario => {
                scenario.scenarioYears = populateTable(scenario.scenarioYears);
                scenario.state = 1;
            });

            var baseScenario = await populateInitialScenarios();

            var scenarios = [baseScenario];

            if (param.asset.scenarios.length < 1) {
                var spendingScenario = await populateInitialScenarios();

                spendingScenario.name = 'Spending Model';
                spendingScenario.state = 0;

                await scenarios.push(spendingScenario);
            }

            param.asset.scenarios = await scenarios.concat(param.asset.scenarios);

            var scenarioCopy = await structuredClone(param.asset.scenarios[1]);

            setScenario(scenarioCopy);

            setAsset(param.asset);

            setLoading(false);
        }

        async function populateInitialScenarios() {
            let scenario = {
                id: 0,
                name: 'Baseline - No Investment',
                funding: 0,
                state: 1,    // Unmodified
                assetID: param.asset.id,
            };

            let data = await getTable();

            scenario.scenarioYears = data;

            return scenario;
        }

        async function populateRequirements() {
            const token = await authService.getAccessToken();
            const response = await fetch('api/assets/requirements?id=' + param.asset.id, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });

            const data = await response.json();

            return data;
        }

        async function populateRecurringCosts() {
            const token = await authService.getAccessToken();
            const response = await fetch('api/assets/recurringcosts?id=' + param.asset.id, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });

            const data = await response.json();

            return data;
        }

        async function populateScenarios() {
            const token = await authService.getAccessToken();
            const response = await fetch('api/assets/scenarios?id=' + param.asset.id, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });

            const data = await response.json();

            return data;
        }

        if (loading) {
            getInitialScenarios();
        };
    }, [loading, param]);

    function populateTable(scenarioYears) {
        const d = new Date();
        let year = d.getFullYear();
        let accumCost = 0;
        let accumCost3 = 0;
        let accumDet = 0;
        let defaultYear = false;
        let costEscalationPercent = param.asset.costEscalationRatePercent;
        let cost = param.asset.scenarioRecurringCosts;
        let sysReqs = param.asset.scenarioRequirements;

        if (scenarioYears.length < 1) {
            for (let curYear = year; curYear < year + 10; curYear++) {
                scenarioYears.push({ year: curYear });
            }

            defaultYear = true;
        }

        let req13 = 0;
        let req45 = 0;

        sysReqs.forEach(sysReq => {
            if (sysReq.cost !== null && sysReq.cost !== undefined) {
                if (sysReq.priorityNumber <= 3) {
                    req13 += sysReq.cost;
                } else {
                    req45 += sysReq.cost;
                }
            }
        });

        scenarioYears.forEach((scenarioYear, index) => {
            let scenYear = scenarioYear.year;
            let sysCosts = cost.filter(c => c.nextScheduled === scenYear);
            let sysCosts3 = cost.filter(c => (c.nextScheduled >= scenYear) && (c.nextScheduled < (scenYear + 3)));
            let discreteCost = 0;
            let funding = 0;

            if (scenarioYear.deferredRenewalFunding !== null && scenarioYear.deferredRenewalFunding !== undefined) {
                funding = scenarioYear.deferredRenewalFunding;
            }

            let crvd = scenarioYears[0].year;
            if (param.asset.assetCRVRevisionHistories !== null && param.asset.assetCRVRevisionHistories.length > 0) {
                let { [param.asset.assetCRVRevisionHistories.length - 1]: lastCrv } = param.asset.assetCRVRevisionHistories;
                let lastDate = new Date(lastCrv.revisionDate);
                crvd = lastDate.getFullYear();
            }

            let crv = param.asset.currentCRV * Math.pow(1 + costEscalationPercent, scenYear - crvd);

            // escalate previous years accumulated recurring costs by the Cost Escalation Percent
            accumCost += accumCost * costEscalationPercent;

            sysCosts.forEach(sysCost => {
                discreteCost += sysCost.replacementCost; // Already escalated * Math.pow(1 + costEscalationPercent, scenYear - year);
            });
           
            accumCost += discreteCost;

            let discreteCost3 = 0;

            sysCosts3.forEach(sysCost3 => {
                discreteCost3 += sysCost3.replacementCost; // Already escalated * Math.pow(1 + costEscalationPercent, sysCost3.nextScheduled - year);
            });

            accumCost3 = discreteCost3;

            accumCost -= funding * param.asset.recurringCostsPercent;

            if (accumCost < 0) {
                accumCost = 0;
            }

            if (index > 0) {
                accumDet += crv * param.asset.deteriorationRatePercent;
            }

            let accumReq13 = req13 * Math.pow(1 + costEscalationPercent, scenYear - year);
            accumReq13 -= funding * param.asset.drRequirementsPercent;

            if (accumReq13 < 0) {
                accumReq13 = 0;
            }

            let accumReq45 = req45 * Math.pow(1 + costEscalationPercent, scenYear - year);

            if (accumReq45 < 0) {
                accumReq45 = 0;
            }

            let deferred = accumReq13 + accumReq45 + accumCost + accumCost3 + accumDet;

            let fci = deferred / crv;

            scenarioYear.discrete = discreteCost;
            scenarioYear.accum = accumCost;
            scenarioYear.accumReq = accumReq13;
            scenarioYear.accumDet = accumDet;
            scenarioYear.deferred = deferred;
            scenarioYear.modDeferred = deferred;
            scenarioYear.crv = crv;
            scenarioYear.fci = fci;

            if (defaultYear) {
                scenarioYear.deferredRenewalFunding = 0;
            }
        });

        return scenarioYears;
    }

    const handleShowEdit = () => {

        setEditVals({
            startMonth: asset.fiscalYearStartMonth,
            drRequirements: asset.drRequirementsPercent,
            recurringCosts: asset.recurringCostsPercent,
            deteriorationRate: asset.deteriorationRatePercent,
            costEscalationRate: asset.costEscalationRatePercent
        });

        setShowModal(true);
        setValidated(false);
    }

    const handleSaveChanges = (event) => {
        async function saveAssetData() {
            const token = await authService.getAccessToken();
            await fetch('api/assets/' + asset.id, {
                method: 'PUT',
                headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
                body: JSON.stringify(asset),
            });
        };

        const form = event.currentTarget;

        event.preventDefault();

        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
            return;
        }

        var newAsset = asset;

        newAsset.fiscalYearStartMonth = 1;
        newAsset.drRequirementsPercent = editVals.drRequirements;
        newAsset.recurringCostsPercent = 1 - editVals.drRequirements;
        newAsset.deteriorationRatePercent = editVals.deteriorationRate;
        newAsset.costEscalationRatePercent = editVals.costEscalationRate;

        newAsset.scenarios.forEach(scenario => {
            scenario.scenarioYears = populateTable(scenario.scenarioYears);
        });

        setAsset(newAsset);

        var updatedScenario = scenario;
        updatedScenario.scenarioYears = populateTable(scenario.scenarioYears);

        setScenario(updatedScenario);

        setShowModal(false);
        setValidated(false);

        saveAssetData();

        //param.assetUpdate();
    }

    const updateScenarios = () => {
        asset.scenarios.forEach(scenario => {
            scenario.scenarioYears = populateTable(scenario.scenarioYears);
        });

        var updatedScenario = scenario;
        updatedScenario.scenarioYears = populateTable(scenario.scenarioYears);

        setScenario(updatedScenario);

        forceUpdate();
    }

    const switchToModel = () => {
        setActiveKey("model");
    }

    return loading ? (
        <p><em>Loading...</em></p>
    ) : (
        <React.Fragment>
            <div className="d-grid gap-2">
                <Card>
                    <Card.Header>
                        Model Variables
                    </Card.Header>
                    <Card.Body>
                        <Container>
                            <Row>
                                <Col xs="auto">
                                    <Table borderless>
                                        <tbody>
                                            <tr>
                                                <th>Facility Type</th>
                                                <td>{asset.facilityUse.name}</td>
                                            </tr>
                                            <tr>
                                                <th>% DR Requirements</th>
                                                <td>{(asset.drRequirementsPercent * 100).toFixed(2) + '%'}</td>
                                                <th>Cost Escalation Rate/Year</th>
                                                <td>{(asset.costEscalationRatePercent * 100).toFixed(2) + '%'}</td>
                                            </tr>
                                            <tr>
                                                <th>% Recurring Costs</th>
                                                <td>{(asset.recurringCostsPercent * 100).toFixed(2) + '%'}</td>
                                                <th>Deterioration Rate/Year</th>
                                                <td>{(asset.deteriorationRatePercent * 100).toFixed(2) + '%'}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Col>
                                <Col>
                                    <Stack direction="horizontal" gap={0}>
                                        <Button className="ms-auto" onClick={() => {
                                            handleShowEdit();
                                        }}>Edit Variables</Button>
                                    </Stack>
                                </Col>
                            </Row>
                        </Container>
                    </Card.Body>
                </Card>
                <Modal show={showModal} onHide={() => { setShowModal(false) }} backdrop="static">
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Variables</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form noValidate validated={validated} onSubmit={handleSaveChanges}>
                            <div className="d-grid gap-3">
                                <Form.Group as={Row} controlId="formDRReqs">
                                    <Form.Label column sm={6}>% DR Requirements</Form.Label>
                                    <Col sm={4}>
                                        <InputGroup>
                                            <Form.Control required type="Text" defaultValue={(editVals.drRequirements * 100).toFixed(2)}
                                                onChange={({ target: { value } }) => {
                                                    editVals.drRequirements = value / 100;
                                                    recurringRef.current.value = ((1 - value / 100.0) * 100.0).toFixed(2);
                                                }}
                                                pattern="^(100(?:\.00?)?|\d?\d(?:\.\d\d?)?)$"
                                            />
                                            <InputGroup.Text>%</InputGroup.Text>
                                        </InputGroup>
                                    </Col>
                                </Form.Group>
                                <Row>
                                    <Form.Label column sm={6}>% Recurring Costs</Form.Label>
                                    <Col sm={4}>
                                        <InputGroup>
                                            <Form.Control ref={recurringRef} disabled type="Text" defaultValue={(editVals.recurringCosts * 100).toFixed(2)} />
                                            <InputGroup.Text>%</InputGroup.Text>
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Form.Group as={Row} controlId="formDetRate">
                                    <Form.Label column sm={6}>Deterioration Rate/Year</Form.Label>
                                    <Col sm={4}>
                                        <InputGroup>
                                            <Form.Control required type="Text" defaultValue={(editVals.deteriorationRate * 100).toFixed(2)}
                                                onChange={({ target: { value } }) => editVals.deteriorationRate = value / 100}
                                                pattern="^(100(?:\.00?)?|\d?\d(?:\.\d\d?)?)$"
                                            />
                                            <InputGroup.Text>%</InputGroup.Text>
                                        </InputGroup>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formCostRate">
                                    <Form.Label column sm={6}>Cost Escalation Rate/Year</Form.Label>
                                    <Col sm={4}>
                                        <InputGroup>
                                                <Form.Control disabled type="Text" defaultValue={(editVals.costEscalationRate * 100).toFixed(2)}
                                                onChange={({ target: { value } }) => editVals.costEscalationRate = value / 100}
                                                pattern="^(100(?:\.00?)?|\d?\d(?:\.\d\d?)?)$"
                                            />
                                            <InputGroup.Text>%</InputGroup.Text>
                                        </InputGroup>
                                    </Col>
                                </Form.Group>
                                <Stack direction="horizontal" gap={1}>
                                    <Button type="submit" >
                                        Save Changes
                                    </Button>
                                    <Button className="ms-auto" variant="link" onClick={() => { setShowModal(false) }} >
                                        Cancel
                                    </Button>
                                </Stack>
                            </div>
                        </Form>
                    </Modal.Body>
                </Modal>
                <Tab.Container id="scenario-tabs" defaultActiveKey="model" activeKey={activeKey}>
                    <Stack direction="horizontal">
                        <ToggleButtonGroup name="tabs" className="mb-2">
                            <ToggleButton id="tb1" type="radio" variant={activeKey === "model" ? 'primary' : 'outline-primary'} checked={activeKey === "model"} onClick={() => setActiveKey("model")} >
                                Investment Model
                            </ToggleButton>
                            <ToggleButton id="tb2" type="radio" variant={activeKey === "scenarios" ? 'primary' : 'outline-primary'} checked={activeKey === "scenarios"} onClick={() => setActiveKey("scenarios")} >
                                Scenarios
                            </ToggleButton>
                            <ToggleButton id="tb3" type="radio" variant={activeKey === "charts" ? 'primary' : 'outline-primary'} checked={activeKey === "charts"} onClick={() => setActiveKey("charts")} >
                                Charts
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Stack>
                    <Tab.Content>
                        <Tab.Pane eventKey="model">
                            <AssetScenarioPlanningModel scenario={scenario} />
                        </Tab.Pane>
                        <Tab.Pane eventKey="scenarios">
                            <AssetScenarioPlanningScenarios asset={asset} updateScenarios={updateScenarios} scenario={scenario} switchTab={switchToModel} />
                        </Tab.Pane>
                        <Tab.Pane eventKey="charts">
                            <AssetScenarioPlanningCharts asset={asset} scenario={scenario} />
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </div>
        </React.Fragment>
    );
}

export default AssetScenarioPlanning;