import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import authService from './api-authorization/AuthorizeService';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import InputGroup from 'react-bootstrap/InputGroup';
import utils from '../utils';
import { NumericFormat } from 'react-number-format';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';

const RecurringCost = () => {
    const _assetId = 'assetId';
    const _systemTypeId = 'systemTypeId';
    const _notSpecified = 'Not specified';

    const { id } = useParams();

    const navigate = useNavigate();
    const [queryParameters] = useSearchParams();

    const [addMode] = useState(id.toUpperCase() === 'ADD');
    const [assetMode] = useState(queryParameters.get(_assetId) !== null);
    const [asset, setAsset] = useState(null)
    const [systemMode] = useState(queryParameters.get(_systemTypeId) !== null);
    const [system, setSystem] = useState(null)

    const [loading, setLoading] = useState(true);
    const [validated, setValidated] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showAlertModal, setShowAlertModal] = useState(false);
    const [alertModalMessage, setAlertModalMessage] = useState('');

    const [recurringCost, setRecurringCost] = useState(null);
    const [assets, setAssets] = useState([]);
    const [systems, setSystems] = useState([]);
    const [components, setComponents] = useState([]);
    const [inspectors, setInspectors] = useState([]);
    const [exteriorLocations, setExteriorLocations] = useState([]);
    const [interiorLocations, setInteriorLocations] = useState([]);
    const [directions, setDirections] = useState([]);
    const [units, setUnits] = useState([]);
    const [complexityFactors, setComplexityFactors] = useState([]);
    const [priorities, setPriorities] = useState([]);
    const [operabilities, setOperabilities] = useState([]);
    const [aesthetics, setAesthetics] = useState([]);

    const [component, setComponent] = useState(null);
    const [tempComponent, setTempComponent] = useState('');
    const [componentFilter, setComponentFilter] = useState('');
    const [componentsInView, setComponentsInView] = useState([]);
    const [yearInstalledDate, setYearInstalledDate] = useState(new Date());
    const [scheduledReplacementYearDate, setScheduledReplacementYearDate] = useState(new Date());

    useEffect(() => {
        if (loading) {
            async function populateItem() {
                let assetID = assetMode ? queryParameters.get(_assetId) : '';                
                if (addMode) {
                    await setRecurringCost({
                        assetID: assetMode ? queryParameters.get(_assetId) : '',
                        systemTypeID: systemMode ? queryParameters.get(_systemTypeId) : '',
                        componentID: '',
                        inspectionDate: '',
                        inspectorID: '',
                        exteriorLocationID: '',
                        interiorLocationID: '',
                        floorNumber: '',
                        compassDirectionID: '',
                        componentStatus: '',
                        quantity: '',
                        unitID: '',
                        yearInstalled: yearInstalledDate.getFullYear(),
                        scheduledReplacementYear: scheduledReplacementYearDate.getFullYear(),
                        complexityFactorID: '',
                        assessedYearReplacementCost: '',
                        statedCost: '',
                        additionalInfo: '',
                        priorityID: '',
                        operabilityID: '',
                        aestheticsID: ''
                    });
                } else {       
                    const token = await authService.getAccessToken();
                    const response = await fetch('api/recurringcosts/' + id, {
                        headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                    });
                    const item = await response.json();
                    const inspectionDate = new Date(item.inspectionDate);
                    item.inspectionDate = utils.getDateString(inspectionDate);
                    await setYearInstalledDate(new Date(`${item.yearInstalled}-01-01 00:00:00`));
                    await setScheduledReplacementYearDate(new Date(`${item.scheduledReplacementYear}-01-01 00:00:00`));
                    item.assessedYearReplacementCost = getAssessedYearReplacementCost(item);
                    await setRecurringCost(item);
                    await setComponent(item.component);
                    assetID = item.assetID;
                }

                await populateArrays(assetID);
                setLoading(false);
            }

            async function populateArrays(assetID) {
                const token = await authService.getAccessToken();
                const response = await fetch('api/recurringcosts/itemsForAdd', {
                    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                });
                const item = await response.json();
                await setAssets(item.assets);
                if (assetID === '' && item.assets.length > 0 && item.assets[0].facilitySystems.length > 0) {
                    await setSystems(item.assets[0].facilitySystems.map(q => q.systemType).sort(utils.sortByName));
                } else if (assetID !== '') {
                    const asset = item.assets.find(q => q.id === assetID);
                    if (asset !== undefined) {
                        const assetSystems = asset.facilitySystems.map(q => q.systemType).sort(utils.sortByName);
                        await setSystems(assetSystems)
                        if (assetMode) await setAsset(asset);
                        await setSystem(assetSystems.find(q => q.id === parseInt(queryParameters.get(_systemTypeId))));
                    }
                }
                await setInspectors(item.inspectors);
                await setExteriorLocations(item.exteriorLocations);
                await setInteriorLocations(item.interiorLocations);
                await setDirections(item.directions);
                await setUnits(item.units);
                await setComplexityFactors(item.complexityFactors);
                await setPriorities(item.priorities);
                await setOperabilities(item.operabilities);
                await setAesthetics(item.aesthetics);   
            }   

            populateItem();            
        }
    }, [loading, id]);

    const handleSave = async (event) => {
        const form = event.currentTarget;

        event.preventDefault();

        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
            return;
        }

        // ensure that a component is selected
        if (recurringCost.componentID === '') {
            await setAlertModalMessage('Please select a Component before saving.');
            await setShowAlertModal(true);
            return;
        }

        if (recurringCost.statedCost === '') {
            recurringCost.statedCost = null;
        }

        recurringCost.component = null;

        const token = await authService.getAccessToken();

        if (addMode) {
            await fetch('api/recurringcosts', {
                method: 'POST',
                headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
                body: JSON.stringify(recurringCost),
            });            
        } else {
            await fetch(`api/recurringcosts/${recurringCost.id}`, {
                method: 'PUT',
                headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
                body: JSON.stringify(recurringCost),
            });
        }

        let path = '/recurringcosts';
        if (assetMode) {
            path = `/assets/${asset.id}?tabKey=recurringCosts`;
        }
        navigate(path);
    };

    const handleDelete = async () => {
        const token = await authService.getAccessToken();
        await fetch(`api/recurringcosts/${recurringCost.id}`, {
            method: 'DELETE',
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        });

        let path = '/recurringcosts';
        if (systemMode) {
            path = `/assets/${asset.id}/system/${system.id}?tabKey=recurringCosts`;
        }
        else if (assetMode) {
            path = `/assets/${asset.id}?tabKey=recurringCosts`;
        }
        navigate(path);
    };

    const getAssessedYearReplacementCost = (item) => {
        const complexityFactor = item.complexityFactor == null ? complexityFactors.find(q => q.id === item.complexityFactorID) : item.complexityFactor;
        if (item.component == null || item.quantity === '' || complexityFactor == null) {
            return '';
        }
        return item.component.cost * complexityFactor.factor * item.quantity;
    };

    const getComponents = async (systemTypeID) => {
        const token = await authService.getAccessToken();
        const response = await fetch(`api/recurringcosts/getComponents?systemTypeID=${systemTypeID}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const item = await response.json();
        return item;
    };

    return loading ? (        
        <p><em>Loading...</em></p>
    ) : (
        <React.Fragment>
            <div>
                <Card className="mb-2">
                        <Card.Body>
                            <Card.Title>
                                <Breadcrumb>
                                    {(!assetMode && !systemMode) && <Breadcrumb.Item href="/recurringCosts/">Recurring Costs</Breadcrumb.Item>}
                                    {assetMode && <Breadcrumb.Item href="/assets/">Assets</Breadcrumb.Item>}
                                    {assetMode && <Breadcrumb.Item href={`/assets/${asset.id}?tabKey=recurringCosts`}>{asset.facilityName}</Breadcrumb.Item>}
                                    {systemMode && <Breadcrumb.Item href={`/assets/${asset.id}/system/${system.id}?tabKey=recurringCosts`}>{system.name}</Breadcrumb.Item>}
                                    <Breadcrumb.Item active>{`${addMode ? 'Add' : 'Edit'} Recurring Cost`}</Breadcrumb.Item>
                                </Breadcrumb>                                      
                            </Card.Title>
                            {assetMode &&
                                <Row>
                                    <Col xs={6}>
                                        <Table borderless>
                                            <tbody>
                                                <tr>
                                                    <th>Facility Code</th>
                                                    <td>{asset.facilityCode}</td>
                                                    <th>Initial CRV</th>
                                                    <td>{utils.getDollarString(asset.initialCRV)}</td>
                                                    <th>Gross SF</th>
                                                    <td>{asset.grossSquareFootage.toLocaleString()}</td>
                                                </tr>
                                                <tr>
                                                    <th>Year Built</th>
                                                    <td>{asset.yearBuilt}</td>
                                                    <th>Current CRV</th>
                                                    <td>{utils.getDollarString(asset.currentCRV)}</td>
                                                    <th>CRV / SF</th>
                                                    <td>{utils.getDollarString(asset.currentCRV / asset.grossSquareFootage)}</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                            }
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Body>
                        <Form noValidate validated={validated} onSubmit={handleSave}>
                            <Row className="p-3 align-items-center">
                                <Form.Group as={Row} controlId="formFacility">
                                    <Form.Label column sm={2}>Facility</Form.Label>
                                        <Col sm={3}>
                                            <Form.Select required defaultValue={recurringCost.assetID} disabled={!addMode || assetMode} onChange={async (e) => {                                                                                               
                                                const asset = assets.find(q => q.id === e.target.value);
                                                if (asset !== undefined && asset.facilitySystems.length > 0) {
                                                    const systemTypes = asset.facilitySystems.map(q => q.systemType).sort(utils.sortByName);
                                                    await setSystems(systemTypes);
                                                } else {
                                                    await setSystems([]);
                                                }
                                                const item = utils.getCopy(recurringCost);
                                                item.assetID = e.target.value; 
                                                item.systemTypeID = '';
                                                setRecurringCost(item);
                                                // need to reset
                                                document.getElementById('systemSelect').value = '';
                                            }} >
                                                <option value="" disabled>Select a Facility...</option>
                                                {assets.map(q => <option value={q.id}>{q.facilityName}</option>) }
                                        </Form.Select>
                                    </Col>
                                </Form.Group>
                            </Row>

                            <Row className="p-3 align-items-center">
                                <Form.Group as={Row} controlId="formSystem">
                                    <Form.Label column sm={2}>System</Form.Label>
                                    <Col sm={3}>
                                            <Form.Select id="systemSelect" required defaultValue={recurringCost.systemTypeID} disabled={!addMode || systemMode} onChange={(e) => {
                                                const item = utils.getCopy(recurringCost);
                                                item.systemTypeID = parseInt(e.target.value);
                                                setRecurringCost(item);
                                            }} >
                                                <option value="" disabled>{systems.length > 0 ? 'Select a System...' : 'No Systems...'}</option>
                                                {systems.map(q => <option value={q.id}>{q.name}</option>)}
                                        </Form.Select>
                                    </Col>
                                </Form.Group>
                                </Row>

                                <Row className="p-3 g-0">
                                    <Card className="col-8">
                                        <Card.Header>
                                            <Row>
                                                <Col className="col-6">Component</Col>
                                                <Col className="col-6 text-end">
                                                    <a class="btn btn-primary btn-sm" onClick={async () => {
                                                        if (recurringCost.systemTypeID === '' || recurringCost.assetID === '') {
                                                            await setAlertModalMessage('Please select an asset and system first.');
                                                            await setShowAlertModal(true);
                                                        } else {
                                                            const _components = await getComponents(recurringCost.systemTypeID);
                                                            await setComponents([..._components]);
                                                            await setTempComponent(component !== null ? component.id : '');
                                                            await setComponentFilter('');
                                                            await setComponentsInView([..._components]);
                                                            await setShowModal(true);
                                                        }
                                                    }}>Select Component</a>
                                                </Col>
                                            </Row>
                                        </Card.Header>
                                        <Card.Body>
                                            <Row className="align-items-center">
                                                <Col className="fw-bold col-3 p-2">Description</Col>
                                                <Col className="col-3 p-2">{component !== null && component.description}</Col>
                                                <Col className="fw-bold col-3 p-2">Life Expectancy</Col>
                                                <Col className="col-3 p-2">{component !== null && (component.lifeExpectancy == null ? _notSpecified : `${component.lifeExpectancy} year(s)`)}</Col>
                                                <Col className="fw-bold col-3 p-2">Uniformat</Col>
                                                <Col className="col-3 p-2">{component !== null && component.uniformat}</Col>
                                                <Col className="fw-bold col-3 p-2">Historic Service Life</Col>
                                                <Col className="col-3 p-2">{component !== null && (component.historicServiceLife == null ? _notSpecified : `${component.historicServiceLife} year(s)`)}</Col>
                                                <Col className="fw-bold col-3 p-2">Unit</Col>
                                                <Col className="col-3 p-2">{component !== null && component.unit.name}</Col>
                                                <Col className="fw-bold col-3 p-2">Cost</Col>
                                                <Col className="col-3 p-2">{component !== null && utils.getDollarString(component.cost)}</Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Row>

                                <Row className="p-3 g-0">
                                    <Card className="col-8">
                                        <Card.Header>Inspection</Card.Header>
                                        <Card.Body>
                                            <Row className="align-items-center">
                                                <Col className="fw-bold col-3 p-2 text-middle">Inspection Date</Col>
                                                <Col className="col-3 p-2">
                                                    <Form.Control type="date" value={recurringCost.inspectionDate} min="1000-01-01" max="9999-12-31" required onChange={(e) => {
                                                        const item = utils.getCopy(recurringCost);
                                                        item.inspectionDate = e.target.value;
                                                        setRecurringCost(item);
                                                    }}>
                                                    </Form.Control>
                                                </Col>
                                                <Col className="fw-bold col-3 p-2">Inspector</Col>
                                                <Col className="col-3 p-2">
                                                    <Form.Select defaultValue={recurringCost.inspectorID} required onChange={(e) => {
                                                        const item = utils.getCopy(recurringCost);
                                                        item.inspectorID = parseInt(e.target.value);
                                                        setRecurringCost(item);
                                                    }}>
                                                        <option disabled value="">Select an Inspector...</option>
                                                        {inspectors.map(q => <option value={q.id}>{q.name}</option>)}
                                                    </Form.Select>
                                                </Col>
                                                <Col className="fw-bold col-3 p-2">Exterior Location</Col>
                                                <Col className="col-3 p-2">
                                                    <Form.Select defaultValue={recurringCost.exteriorLocationID} required onChange={(e) => {
                                                        const item = utils.getCopy(recurringCost);
                                                        item.exteriorLocationID = parseInt(e.target.value);
                                                        setRecurringCost(item);
                                                    }}>
                                                        <option disabled value="">Select a Location...</option>
                                                        {exteriorLocations.map(q => <option value={q.id}>{q.name}</option>)}
                                                    </Form.Select>
                                                </Col>
                                                <Col className="fw-bold col-3 p-2">Interior Location</Col>
                                                <Col className="col-3 p-2">
                                                    <Form.Select defaultValue={recurringCost.interiorLocationID} required onChange={(e) => {
                                                        const item = utils.getCopy(recurringCost);
                                                        item.interiorLocationID = parseInt(e.target.value);
                                                        setRecurringCost(item)
                                                    }}>
                                                        <option disabled value="">Select a Location...</option>
                                                        {interiorLocations.map(q => <option value={q.id}>{q.name}</option>)}
                                                    </Form.Select>
                                                </Col>
                                                <Col className="fw-bold col-3 p-2">Floor Number</Col>
                                                <Col className="col-3 p-2">
                                                    <Form.Control type="text" defaultValue={recurringCost.floorNumber} required onChange={(e) => {
                                                        const item = utils.getCopy(recurringCost);
                                                        item.floorNumber = e.target.value;
                                                        setRecurringCost(item)
                                                    }} />
                                                </Col>
                                                <Col className="fw-bold col-3 p-2">Direction</Col>
                                                <Col className="col-3 p-2">
                                                    <Form.Select defaultValue={recurringCost.compassDirectionID} required onChange={(e) => {
                                                        const item = utils.getCopy(recurringCost);
                                                        item.compassDirectionID = parseInt(e.target.value);
                                                        setRecurringCost(item)
                                                    }}>
                                                        <option disabled value="">Select a Direction...</option>
                                                        {directions.map(q => <option value={q.id}>{q.name}</option>)}
                                                    </Form.Select>
                                                </Col>
                                                <Col className="fw-bold col-3 p-2">Component Status</Col>
                                                <Col className="col-3 p-2">
                                                    <Form.Control type="text" defaultValue={recurringCost.componentStatus} required onChange={(e) => {
                                                        const item = utils.getCopy(recurringCost);
                                                        item.componentStatus = e.target.value;
                                                        setRecurringCost(item)
                                                    }} />
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Row>

                            <Row className="p-3 g-0">
                                <Card className="col-8">
                                        <Card.Header>Quanitative Data</Card.Header>
                                    <Card.Body>
                                        <Row className="align-items-center">
                                            <Col className="fw-bold col-3 p-2">Quantity</Col>
                                                <Col className="col-3 p-2">
                                                    <NumericFormat className="form-control" decimalScale={8} fixedDecimalScale={false} value={recurringCost.quantity} thousandSeparator="," required onValueChange={async (values, sourceInfo) => {
                                                        const item = utils.getCopy(recurringCost);
                                                        item.quantity = values.floatValue;
                                                        item.assessedYearReplacementCost = getAssessedYearReplacementCost(item);
                                                        await setRecurringCost(item);
                                                    }} />
                                            </Col>
                                            <Col className="fw-bold col-3 p-2">Unit</Col>
                                            <Col className="col-3 p-2">
                                                    <Form.Select defaultValue={recurringCost.unitID} required onChange={(e) => {
                                                        const item = utils.getCopy(recurringCost);
                                                        item.unitID = parseInt(e.target.value);
                                                        setRecurringCost(item);
                                                    }}>
                                                    <option disabled value="">Select a Unit...</option>
                                                    {units.map(q => <option value={q.id}>{q.name}</option>)}
                                                    </Form.Select>
                                            </Col>
                                            <Col className="fw-bold col-3 p-2">Year Installed</Col>
                                                <Col className="col-3 p-2">
                                                    <DatePicker className="form-control" selected={yearInstalledDate} showYearPicker dateFormat="yyyy" required onChange={async (date) => {
                                                        await setYearInstalledDate(date);
                                                        const item = utils.getCopy(recurringCost);                                                        
                                                        item.yearInstalled = date == null ? '' : date.getFullYear();
                                                        await setRecurringCost(item);
                                                    }} />
                                            </Col>
                                            <Col className="fw-bold col-3 p-2">Scheduled Replacement Year</Col>
                                                <Col className="col-3 p-2">
                                                    <DatePicker className="form-control" selected={scheduledReplacementYearDate} showYearPicker dateFormat="yyyy" required onChange={async (date) => {
                                                        await setScheduledReplacementYearDate(date);
                                                        const item = utils.getCopy(recurringCost);
                                                        item.scheduledReplacementYear = date == null ? '' : date.getFullYear();
                                                        await setRecurringCost(item);
                                                    }} />
                                            </Col>
                                            <Col className="fw-bold col-3 p-2">Complexity Factor</Col>
                                                <Col className="col-3 p-2">
                                                    <Form.Select defaultValue={recurringCost.complexityFactorID} required onChange={(e) => {
                                                        const item = utils.getCopy(recurringCost);
                                                        item.complexityFactorID = parseInt(e.target.value);         
                                                        item.complexityFactor = null;
                                                        item.assessedYearReplacementCost = getAssessedYearReplacementCost(item);
                                                        setRecurringCost(item);
                                                    }}>
                                                    <option disabled value="">Select a Factor...</option>
                                                    {complexityFactors.map(q => <option value={q.id}>{q.factor}</option>)}
                                                    </Form.Select>
                                            </Col>
                                                <Col className="fw-bold col-3 p-2">Assessed Year Replacement Cost</Col>
                                                <Col className="col-3 p-2">
                                                    <InputGroup>
                                                        <InputGroup.Text>$</InputGroup.Text>
                                                        <NumericFormat className="form-control" decimalScale={2} fixedDecimalScale={true} value={recurringCost.assessedYearReplacementCost} thousandSeparator="," disabled />
                                                    </InputGroup>
                                            </Col>
                                            <Col className="fw-bold col-3 p-2">Stated Cost</Col>
                                                <Col className="col-3 p-2">
                                                    <InputGroup>
                                                        <InputGroup.Text>$</InputGroup.Text>
                                                        <NumericFormat className="form-control" decimalScale={2} fixedDecimalScale={true} value={recurringCost.statedCost} thousandSeparator="," onValueChange={async (values, sourceInfo) => {
                                                            const item = utils.getCopy(recurringCost);
                                                            item.statedCost = values.floatValue;
                                                            await setRecurringCost(item);
                                                        }} />                                                        
                                                    </InputGroup>
                                            </Col>
                                            <Col className="fw-bold col-12 p-2">Additional Info</Col>
                                                <Col className="col-12 p-2">
                                                    <Form.Control as="textarea" defaultValue={recurringCost.additionalInfo} onChange={(e) => {
                                                        const item = utils.getCopy(recurringCost);
                                                        item.additionalInfo = e.target.value;
                                                        setRecurringCost(item);
                                                    }} />
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                            </Row>

                            <Row className="p-3 g-0">
                                <Card className="col-8">
                                        <Card.Header>Qualitative Data</Card.Header>
                                    <Card.Body>
                                        <Row className="align-items-center">
                                            <Col className="fw-bold col-3 p-2">Priority Number</Col>
                                            <Col className="col-3 p-2">
                                                    <Form.Select defaultValue={recurringCost.priorityID} required onChange={(e) => {
                                                        const item = utils.getCopy(recurringCost);
                                                        item.priorityID = parseInt(e.target.value);
                                                        setRecurringCost(item);
                                                    }}>
                                                    <option disabled value="">Select a Priority...</option>
                                                    {priorities.map(q => <option value={q.id}>{q.name}</option>)}
                                                    </Form.Select >
                                            </Col>
                                            <Col className="fw-bold col-3 p-2">Operability</Col>
                                            <Col className="col-3 p-2">
                                                    <Form.Select defaultValue={recurringCost.operabilityID} required onChange={(e) => {
                                                        const item = utils.getCopy(recurringCost);
                                                        item.operabilityID = parseInt(e.target.value);
                                                        setRecurringCost(item);
                                                    }}>
                                                    <option disabled value="">Select an Operability...</option>
                                                    {operabilities.map(q => <option value={q.id}>{q.name}</option>)}
                                                    </Form.Select>
                                            </Col>
                                            <Col className="fw-bold col-3 p-2">Aesthetics</Col>
                                                <Col className="col-3 p-2">
                                                    <Form.Select defaultValue={recurringCost.aestheticsID} required onChange={(e) => {
                                                        const item = utils.getCopy(recurringCost);
                                                        item.aestheticsID = parseInt(e.target.value);
                                                        setRecurringCost(item);
                                                    }}>
                                                    <option disabled value="">Select an Aesthetic...</option>
                                                    {aesthetics.map(q => <option value={q.id}>{q.name}</option>)}
                                                    </Form.Select>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                </Card>
                            </Row>

                            <Row className="p-3">
                                <Col className="col-2">
                                    <Button type="submit">Save Changes</Button>
                                    </Col>
                                    <Col className="col-2">
                                        <Button variant="outline-primary" onClick={() => {
                                            let path = '/recurringcosts';
                                            if (systemMode) {
                                                path = `/assets/${recurringCost.assetID}/system/${recurringCost.systemTypeID}`;
                                            } else if (assetMode) {
                                                path = `/assets/${recurringCost.assetID}?tabKey=recurringCosts`;
                                            }
                                            navigate(path);
                                        }}>Cancel</Button>
                                    </Col>
                                    {!addMode &&
                                        <Col className="col-4 text-end">
                                            <Button variant="outline-danger" onClick={() => {
                                                setShowDeleteModal(true);
                                            }}>Delete</Button>
                                        </Col>
                                    }
                            </Row>
                        </Form>
                    </Card.Body>
                </Card>
                <Modal size="xl" fullscreen='xl-down' show={showModal} onHide={() => { setShowModal(false)}} backdrop="static">
                    <Modal.Header closeButton>
                        <Modal.Title>Select Component</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ maxHeight: '400px', overflowY: 'scroll' }}>
                        <Table striped>
                            <thead>
                                    <tr>
                                        <th></th>
                                        <th>Cost Code</th>
                                        <th>Description</th>
                                        <th>Unit</th>
                                        <th>Cost</th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th></th>
                                        <th>
                                            <input type="search" class="form-control" placeholder="Search" onChange={async (e) => {
                                                const searchString = e.target.value.trim().toUpperCase();
                                                await setComponentFilter(searchString);
                                                await setComponentsInView([...components.filter(q => q.systemTypeID.toString() === recurringCost.systemTypeID.toString())].filter(q => searchString === '' || q.description.toUpperCase().includes(searchString)))
                                            }} defaultValue={componentFilter} />
                                        </th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                            </thead>
                            <tbody>
                                    {componentsInView.map(q =>
                                        <tr>
                                            <td><input class="form-check-input" type="checkbox" value="" checked={tempComponent === q.id} onChange={(e) => { setTempComponent(e.target.checked ? q.id : '') }} /></td>
                                            <td>{q.costCode}</td>
                                            <td>{q.description}</td>
                                            <td>{q.unit.name}</td>
                                            <td>{utils.getDollarString(q.cost)}</td>
                                        </tr>)
                                    }
                            </tbody>
                        </Table>
                    </Modal.Body>
                    <Modal.Footer>
                        <Container>
                            <Row>
                                <Col className="col-6">
                                        <Button disabled={tempComponent === ''} onClick={() => {
                                            const component = components.find(q => q.id === tempComponent);
                                            setComponent(component);
                                            const item = utils.getCopy(recurringCost);
                                            item.componentID = tempComponent;
                                            item.component = component;
                                            item.assessedYearReplacementCost = getAssessedYearReplacementCost(item);
                                            setRecurringCost(item);
                                            setShowModal(false);
                                        }}>Add Selected Component</Button>
                                </Col>
                                <Col className="col-6 text-end">
                                        <Button variant="outline-primary" onClick={() => { setShowModal(false) }}>Cancel</Button>
                                    </Col>
                                </Row>
                            </Container>
                    </Modal.Footer>
                    </Modal>
                    <Modal size="sm" show={showDeleteModal} onHide={() => { setShowDeleteModal(false) }} backdrop="static">
                        <Modal.Header closeButton>
                            <Modal.Title>Delete</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Are you sure you want to delete this recurring cost?</Modal.Body>                    
                        <Modal.Footer>
                            <Container>
                                <Row>
                                    <Col className="col-6">
                                        <Button onClick={() => {
                                            setShowDeleteModal(false);
                                            handleDelete();                                        
                                        }}>Yes</Button>
                                    </Col>
                                    <Col className="col-6 text-end">
                                        <Button variant="secondary" onClick={() => { setShowDeleteModal(false) }}>No</Button>
                                    </Col>
                                </Row>
                            </Container>
                        </Modal.Footer>
                    </Modal>
                    <Modal size="sm" show={showAlertModal} onHide={() => { setShowAlertModal(false) }} backdrop="static">
                        <Modal.Header closeButton>
                            <Modal.Title>Alert</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>{alertModalMessage}</Modal.Body>
                        <Modal.Footer>
                            <Container>
                                <Row>
                                    <Col className="col-12 text-end">
                                        <Button onClick={() => {
                                            setShowAlertModal(false);
                                        }}>OK</Button>
                                    </Col>
                                </Row>
                            </Container>
                        </Modal.Footer>
                    </Modal>
            </div>
        </React.Fragment>
    );
}

export default RecurringCost;
