import React, { useEffect, useState, useRef } from 'react';
import authService from '../api-authorization/AuthorizeService'
import Table from 'react-bootstrap/Table';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { PlusCircle } from 'react-bootstrap-icons';
import utils from '../../utils';

const AssetRequirements = (props) => {
    const _asset = "ASSET";
    const _system = "SYSTEM";

    const [project] = useState(props.project);
    
    const [allAssetRequirements, setAllAssetRequirements] = useState([]);
    const [showRequirementCostsModal, setShowRequirementCostsModal] = useState(false);
    const [requirementCostFilter, setRequirementCostFilter] = useState('');
    const [requirementCostsInView, setRequirementCostsInView] = useState([]);
    const [requirementCosts, setRequirementCosts] = useState([]);
    const [requirementCostsToAdd, setRequirementCostsToAdd] = useState([]);
    const [showRecurringCostsModal, setShowRecurringCostsModal] = useState(false);
    const [recurringCostFilter, setRecurringCostFilter] = useState('');
    const [recurringCostsInView, setRecurringCostsInView] = useState([]);
    const [recurringCosts, setRecurringCosts] = useState([]);
    const [recurringCostsToAdd, setRecurringCostsToAdd] = useState([]);

    useEffect(() => {

        async function populate() {
            await populateAllAssetRequirements();            
        }

        async function populateAllAssetRequirements() {
            const token = await authService.getAccessToken();
            const fetchUrl = project.type === _asset ? `api/projects/allAssetRequirements?assetID=${project.projectAssets[0].assetID}` : `api/projects/allAssetSystemRequirements?projectID=${project.id}&systemTypeID=${project.systemTypeID}`;
            const response = await fetch(fetchUrl, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });
            const items = await response.json();
            await setAllAssetRequirements(items);
        }

        populate();
    }, [project]);

    const handleAddRequirementCosts = async () => {
        const token = await authService.getAccessToken();
        const projectRequirementCosts = requirementCostsToAdd.map(q => { return { ProjectID: project.id, RequirementID: q }; });
        await fetch('api/projects/addRequirementCosts', {
            method: 'POST',
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(projectRequirementCosts),
        });
        await props.refreshCosts();
        await props.refreshProjectAssetRequirements(project);
    };

    const handleAddRecurringCosts = async () => {
        const token = await authService.getAccessToken();
        const projectRecurringCosts = recurringCostsToAdd.map(q => { return { ProjectID: project.id, RecurringCostID: q }; });
        await fetch('api/projects/addRecurringCosts', {
            method: 'POST',
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(projectRecurringCosts),
        });
        await props.refreshCosts();
        await props.refreshProjectAssetRequirements(project);
    };

    return (
        <Container>
            <Table striped>
                <thead>
                    <tr>
                        <th>{props.project.type === _asset ? 'System' : 'Facility'}</th>
                        <th>Deferred Renewal (1-3)</th>
                        <th></th>
                        <th></th>
                        <th>{'Recurring Costs < 10 years'}</th>
                        <th></th>
                        <th></th>
                        <th>System CRV</th>
                    </tr>
                </thead>
                <tbody>
                    {props.projectAssetRequirements.map(q => <tr>
                        <td>{q.name}</td>
                        <td>{utils.getDollarString(q.deferredRenewal.sum)}</td>
                        <td>{q.deferredRenewal.count}</td>
                        <td>
                            <Button className="p-0 m-0" variant="link" onClick={() => {
                                setRequirementCostFilter('');
                                setRequirementCostsToAdd([]);
                                // filter requirement costs so only the ones not already added display
                                const assetRequirement = allAssetRequirements.find(a => a.id === q.id);                                
                                const requirementIds = props.costs.filter(c => c.type === 'Requirement').map(c => c.reqRecID);
                                setRequirementCosts([...assetRequirement.requirementCosts.filter(r => requirementIds.indexOf(r.requirementID) < 0)]);
                                setRequirementCostsInView([...assetRequirement.requirementCosts.filter(r => requirementIds.indexOf(r.requirementID) < 0)]);
                                setShowRequirementCostsModal(true); 
                            }}>
                                <PlusCircle />
                            </Button>
                        </td>
                        <td>{utils.getDollarString(q.recurringCosts10.sum)}</td>
                        <td>{q.recurringCosts10.count}</td>
                        <td>
                            <Button className="p-0 m-0" variant="link" onClick={() => {
                                setRecurringCostFilter('');
                                setRecurringCostsToAdd([]);
                                // filter recurring costs so only the ones not already added display
                                const assetRequirement = allAssetRequirements.find(a => a.id === q.id);
                                const recurringCostIds = props.costs.filter(c => c.type === 'Recurring Cost').map(c => c.reqRecID);
                                setRecurringCosts([...assetRequirement.recurringCosts.filter(r => recurringCostIds.indexOf(r.recurringCostID) < 0)]);
                                setRecurringCostsInView([...assetRequirement.recurringCosts.filter(r => recurringCostIds.indexOf(r.recurringCostID) < 0)]);
                                setShowRecurringCostsModal(true); 
                            }}>
                                <PlusCircle />
                            </Button>
                        </td>
                        <td>{utils.getDollarString(q.systemCRV)}</td>
                    </tr>)}
                </tbody>
            </Table>
            <Modal size="xl" fullscreen='xl-down' show={showRequirementCostsModal} onHide={() => { setShowRequirementCostsModal(false) }} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Add Requirements</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table striped>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Description</th>
                                <th>Action</th>
                                <th>Priority</th>
                                <th>Cost</th>
                            </tr>
                            <tr>
                                <th></th>
                                <th><input type="search" class="form-control" placeholder="Search" onChange={(e) => {
                                    const searchString = e.target.value.trim().toUpperCase();
                                    setRequirementCostFilter(searchString);
                                    setRequirementCostsInView([...requirementCosts].filter(q => searchString === '' || q.description.toUpperCase().includes(searchString)))
                                }} defaultValue={requirementCostFilter} /></th>
                                <th></th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {requirementCostsInView.map(q => <tr>
                                <td><input class="form-check-input" type="checkbox" value="" checked={requirementCostsToAdd.indexOf(q.requirementID) >= 0} onChange={(e) => {
                                    if (e.target.checked) {
                                        const costs = [...new Set(requirementCostsToAdd)];
                                        costs.push(q.requirementID);
                                        setRequirementCostsToAdd(costs);
                                    } else {
                                        const costs = [...new Set(requirementCostsToAdd.filter(r => r !== q.requirementID))];
                                        setRequirementCostsToAdd(costs);
                                    }
                                }} /></td>
                                <td>{q.description}</td>
                                <td>{q.action}</td>
                                <td>{q.priority}</td>
                                <td>{utils.getDollarString(q.cost)}</td>
                            </tr>)}
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Container>
                        <Row>
                            <Col className="col-6">
                                <Button disabled={requirementCostsToAdd.length === 0} onClick={() => {
                                    setShowRequirementCostsModal(false);
                                    handleAddRequirementCosts();
                                }}>Add Selected Requirements</Button>
                            </Col>
                            <Col className="col-6 text-end">
                                <Button variant="secondary" onClick={() => { setShowRequirementCostsModal(false) }}>Cancel</Button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal>
            <Modal size="xl" fullscreen='xl-down' show={showRecurringCostsModal} onHide={() => { setShowRecurringCostsModal(false) }} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Add Recurring Costs</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table striped>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Description</th>
                                <th>Priority</th>
                                <th>Cost</th>
                            </tr>
                            <tr>
                                <th></th>
                                <th><input type="search" class="form-control" placeholder="Search" onChange={(e) => {
                                    const searchString = e.target.value.trim().toUpperCase();
                                    setRecurringCostFilter(searchString);
                                    setRecurringCostsInView([...recurringCosts].filter(q => searchString === '' || q.description.toUpperCase().includes(searchString)))
                                }} defaultValue={recurringCostFilter} /></th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {recurringCostsInView.map(q => <tr>
                                <td><input class="form-check-input" type="checkbox" value="" checked={recurringCostsToAdd.indexOf(q.recurringCostID) >= 0} onChange={(e) => {
                                    if (e.target.checked) {
                                        const costs = [...new Set(recurringCostsToAdd)];
                                        costs.push(q.recurringCostID);
                                        setRecurringCostsToAdd(costs);
                                    } else {
                                        const costs = [...new Set(recurringCostsToAdd.filter(r => r !== q.recurringCostID))];
                                        setRecurringCostsToAdd(costs);
                                    }
                                }} /></td>
                                <td>{q.description}</td>
                                <td>{q.priority}</td>
                                <td>{utils.getDollarString(q.cost)}</td>
                            </tr>)}
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Container>
                        <Row>
                            <Col className="col-6">
                                <Button disabled={recurringCostsToAdd.length === 0} onClick={() => {
                                    setShowRecurringCostsModal(false);
                                    handleAddRecurringCosts();
                                }}>Add Selected Recurring Costs</Button>
                            </Col>
                            <Col className="col-6 text-end">
                                <Button variant="secondary" onClick={() => { setShowRecurringCostsModal(false) }}>Cancel</Button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal>
        </Container>
    );
}

export default AssetRequirements;