import React, { useEffect, useState, useRef } from 'react';
import authService from '../api-authorization/AuthorizeService'
import ItemsList from '../ItemsList.js';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import utils from '../../utils';
import InputGroup from 'react-bootstrap/InputGroup';
import { NumericFormat } from 'react-number-format';
import Button from 'react-bootstrap/Button';
import { ArrowReturnRight } from 'react-bootstrap-icons';

const AssemblyCosts = () => {
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [units, setUnits] = useState([]);
    const [uniformats, setUniformats] = useState({
        level1: [],
        level2: [],
        level3: [],
        level4: []
    });
    const [modalTitle, setModalTitle] = useState('');
    const [assemblyCost, setAssemblyCost] = useState({});
    const [level1, setLevel1] = useState('');
    const [level2, setLevel2] = useState('');
    const [level3, setLevel3] = useState('');
    const updateList = useRef(true);
    const [validated, setValidated] = useState(false);
    const [costCodeValid, setCostCodeValid] = useState(true);
    const [disableInput, setDisableInput] = useState(true);

    useEffect(() => {
        if (loading) {
            const getItems = async () => {
                const token = await authService.getAccessToken();
                const response = await fetch(`api/settings/items`, {
                    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                });

                const data = await response.json();
                const tempUniformats = utils.getCopy(uniformats);
                tempUniformats.level1 = data.uniformatLevel1s;
                tempUniformats.level2 = data.uniformatLevel2s;
                tempUniformats.level3 = data.uniformatLevel3s;
                tempUniformats.level4 = data.uniformatLevel4s;
                debugger;
                await setUniformats(tempUniformats);
                await setUnits(data.units);

                await setLoading(false);
            };
            getItems();
        }
    }, [loading]);

    const columns = [
        { id: 'costCode', name: 'Cost Code', sortable: true, filter: "search", selectId: null, labelFormat: 'clickToItem', aggregate: false },
        { id: 'uniformatPath', name: 'Uniformat Path', sortable: true, filter: null, selectId: null, labelFormat: 'small', aggregate: false },
        { id: 'description', name: 'Description', sortable: true, filter: "search", selectId: null, labelFormat: null, aggregate: false },
        { id: 'unit', name: 'Unit', sortable: true, filter: "select", selectId: "unitId", labelFormat: null, aggregate: false },
        { id: 'cost', name: 'Cost', sortable: true, filter: null, selectId: null, labelFormat: 'dollar', aggregate: false },
        { id: 'historicServiceLifeInYears', name: 'Historical Service Life (years)', sortable: true, filter: null, selectId: null, labelFormat: null, aggregate: false },
        { id: 'active', name: 'Active', sortable: true, filter: "select", selectId: "active", labelFormat: null, aggregate: false },        
        // { id: 'userModified', name: 'User Modified', sortable: true, filter: "select", selectId: 'userModified', labelFormat: null, aggregate: false }
    ];

    const mapFunction = (q) => {
        return {
            id: q.id,
            costCode: q.costCode,
            uniformatPath: q.uniformatPath,
            description: q.description,
            unitId: q.unitId,
            unit: q.unit,
            cost: q.cost,
            active: q.active ? "Yes" : "No",
            userModified: q.userModified ? "Yes" : "No",
            historicServiceLifeInYears: q.historicServiceLifeInYears
        }
    };   

    const shouldUpdateList = () => {
        if (updateList.current) {
            updateList.current = false;
            return true;
        }
        return false;
    };

    const handleEditItem = async (id) => {
        const token = await authService.getAccessToken();
        const response = await fetch(`api/settings/assemblyCost?id=${id}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });

        const data = await response.json();

        await setLevel1(data.uniformat.systemTypeID.toString());
        await setLevel2(data.uniformat.level2);
        await setLevel3(data.uniformat.level3);
        await setAssemblyCost(data);
        await setModalTitle('Edit Assembly Cost');
        await setValidated(false);
        await setCostCodeValid(true);
        await setDisableInput(true);
        await setShowModal(true);
    };

    const handleAddItem = async () => {
        await setLevel1('');
        await setLevel2('');
        await setLevel3('');
        await setAssemblyCost({
            costCode: '',
            description: '',
            uniformatID: '',
            unitID: '',
            cost: '',
            active: true,
            userModified: true,
            historicServiceLifeInYears: ''
        });
        await setModalTitle('Add Assembly Cost');
        await setValidated(false);
        await setCostCodeValid(true);
        await setDisableInput(false);
        await setShowModal(true);
    };

    const handleSave = async (event) => {
        const form = event.currentTarget;

        event.preventDefault();

        if (form.checkValidity() === false) {
            event.stopPropagation();
            await setValidated(true);
            return;
        } 

        //const valid = await validateCostCode();
        //await setCostCodeValid(valid);
        //if (valid === false) {
        //    event.stopPropagation();
        //    await setValidated(true);
        //    return;
        //} 

        const token = await authService.getAccessToken();
        await fetch('api/settings/assemblyCost', {
            method: assemblyCost.hasOwnProperty('id') ? 'PUT' : 'POST',
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(assemblyCost),
        });

        updateList.current = true;
        await setShowModal(false);
    };

    const handleCancel = async () => {
        await setShowModal(false);
    };

    const validateCostCode = async () => {
        if (assemblyCost.costCode === '') return 'Cost Code';

        const token = await authService.getAccessToken();

        const response = await fetch(`api/settings/checkCostCode?costCode=${assemblyCost.costCode}`, {
            method: 'GET',
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        });
        const valid = await response.json();
        return valid;        
    };

    const getCostCode = async (uniformatID) => {
        const token = await authService.getAccessToken();

        const response = await fetch(`api/settings/getCostCode?uniformatID=${uniformatID}`, {
            method: 'GET',
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        });
        return await response.text(); 
    };

    return (
        <React.Fragment>
            <Row className="align-items-center mb-3">
                <Col className="col-6">
                    <Card.Subtitle>
                        Assembly Costs
                    </Card.Subtitle>
                </Col>
                <Col className="col-6 text-end">
                    <Button type="button" onClick={handleAddItem}>Add Assembly Cost</Button>
                </Col>
            </Row>            
            <ItemsList columns={columns}
                itemName="Assembly Cost"
                addItemUrl=""
                mapFunction={mapFunction}
                getItemsUrl="api/settings/assemblyCosts"
                uniqueIdColumn="id"
                showHeader={false}
                itemUrlBase=""
                shouldUpdate={true}
                editItemMethod={handleEditItem}
                shouldUpdateList={shouldUpdateList}
            />
            <Modal show={showModal} onHide={() => { setShowModal(false) }} backdrop="static">
                <Form noValidate validated={validated} onSubmit={handleSave}>
                    <Modal.Header closeButton>
                        <Modal.Title>{modalTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container>
                            <Row className="align-items-center">
                                <Col className="fw-bold col-4 p-2">System/Level I</Col>
                                <Col className="col-8 p-2">
                                    <Form.Select value={level1} required disabled={disableInput} onChange={async (e) => {
                                        await setLevel1(e.target.value);
                                        await setLevel2('');
                                        await setLevel3('');
                                        const item = utils.getCopy(assemblyCost);
                                        item.uniformatID = '';
                                        item.userModified = true;
                                        await setAssemblyCost(item);
                                    }} >
                                        <option disabled key="none" value="">Select a System/Level I</option>
                                        {uniformats.level1.map(q => <option key={q.id} value={q.id}>{q.name}</option>)}
                                    </Form.Select>
                                </Col>
                                <Col className="fw-bold col-4 p-2"><ArrowReturnRight /> Level II</Col>
                                <Col className="col-8 p-2">                                    
                                    <Form.Select value={level2} required disabled={disableInput} onChange={async (e) => {
                                        await setLevel2(e.target.value);
                                        await setLevel3('');
                                        const item = utils.getCopy(assemblyCost);
                                        item.uniformatID = '';
                                        item.userModified = true;
                                        await setAssemblyCost(item);
                                    }} >
                                        <option disabled key="none" value="">Select a Level II</option>
                                        {uniformats.level2.filter(q => q.parentId === level1).map(q => <option key={q.name} value={q.name}>{q.name}</option>)}
                                    </Form.Select>
                                </Col>
                                <Col className="fw-bold col-4 p-2"><ArrowReturnRight /> Level III</Col>
                                <Col className="col-8 p-2">                                    
                                    <Form.Select required value={level3} disabled={disableInput} onChange={async (e) => {
                                        await setLevel3(e.target.value);
                                        const item = utils.getCopy(assemblyCost);
                                        item.uniformatID = '';
                                        item.userModified = true;
                                        await setAssemblyCost(item);
                                    }} >
                                        <option disabled key="none" value="">Select a Level III</option>
                                        {uniformats.level3.filter(q => q.systemTypeId === parseInt(level1) && q.parentId === level2).map(q => <option key={q.name} value={q.name}>{q.name}</option>)}
                                    </Form.Select>
                                </Col>
                                <Col className="fw-bold col-4 p-2"><ArrowReturnRight /> Level IV</Col>
                                <Col className="col-8 p-2">                                    
                                    <Form.Select required value={assemblyCost.uniformatID} disabled={disableInput} onChange={async (e) => {
                                        const item = utils.getCopy(assemblyCost);
                                        const uniformatID = parseInt(e.target.value);
                                        item.uniformatID = uniformatID;
                                        item.costCode = await getCostCode(uniformatID)
                                        item.userModified = true;
                                        await setAssemblyCost(item);
                                    }} >
                                        <option disabled key="none" value="">Select a Level IV</option>
                                        {uniformats.level4.filter(q => q.systemTypeId === parseInt(level1) && q.parentId === level3).map(q => <option key={q.id} value={q.id}>{q.name}</option>)}
                                    </Form.Select>
                                </Col>
                                <Col className="fw-bold col-4 p-2">Cost Code</Col>
                                <Col className="col-8 p-2">
                                    <Form.Control type="text" defaultValue={assemblyCost.costCode} required disabled={disableInput} onChange={(e) => {
                                        const item = utils.getCopy(assemblyCost);
                                        item.costCode = e.target.value;
                                        item.userModified = true;
                                        setAssemblyCost(item);
                                    }} />
                                </Col>
                                <Col className="fw-bold col-4 p-2">Description</Col>
                                <Col className="col-8 p-2">
                                    <Form.Control as="textarea" rows={2} defaultValue={assemblyCost.description} required disabled={disableInput} onChange={(e) => {
                                        const item = utils.getCopy(assemblyCost);
                                        item.description = e.target.value;
                                        item.userModified = true;
                                        setAssemblyCost(item);
                                    }} />
                                </Col>
                                <Col className="fw-bold col-4 p-2">Units</Col>
                                <Col className="col-8 p-2">
                                    <Form.Select required value={assemblyCost.unitID} disabled={disableInput} onChange={async (e) => {
                                        const item = utils.getCopy(assemblyCost);
                                        item.unitID = parseInt(e.target.value);
                                        item.userModified = true;
                                        await setAssemblyCost(item);
                                    }} >
                                        <option disabled key="none" value="">Select a Unit</option>
                                        {units.map(q => <option key={q.id} value={q.id}>{q.name}</option>)}
                                    </Form.Select>
                                </Col>
                                <Col className="fw-bold col-4 p-2">Cost</Col>
                                <Col className="col-8 p-2">
                                    <InputGroup>
                                        <InputGroup.Text>$</InputGroup.Text>
                                        <NumericFormat className="form-control" decimalScale={2} fixedDecimalScale={true} required value={assemblyCost.cost}
                                            thousandSeparator="," onValueChange={async (values, sourceInfo) => {
                                                const item = utils.getCopy(assemblyCost);
                                                item.cost = values.floatValue;
                                                item.userModified = true;
                                                await setAssemblyCost(item);
                                            }}
                                        />                                    
                                    </InputGroup>
                                </Col>
                                <Col className="fw-bold col-4 p-2">Historic Service Life</Col>
                                <Col className="col-8 p-2">
                                    <InputGroup>                                        
                                        <NumericFormat className="form-control" decimalScale={0} fixedDecimalScale={true} required value={assemblyCost.historicServiceLifeInYears}
                                            thousandSeparator="," onValueChange={async (values, sourceInfo) => {
                                                const item = utils.getCopy(assemblyCost);
                                                item.historicServiceLifeInYears = values.floatValue;
                                                item.userModified = true;
                                                await setAssemblyCost(item);
                                            }}
                                        />
                                        <InputGroup.Text>years</InputGroup.Text>
                                    </InputGroup>
                                </Col>
                                <Col className="fw-bold col-4 p-2">Active</Col>
                                <Col className="col-8 p-2">
                                    <Form.Check type="switch" checked={assemblyCost.active} onChange={async (e) => {
                                        const item = utils.getCopy(assemblyCost);
                                        item.active = e.target.checked;
                                        item.userModified = true;
                                        await setAssemblyCost(item);
                                    }} />
                                </Col>
                                {assemblyCost.userModified && < Col className="col-12 p-2">This assembly cost has been edited</Col>}
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Container>
                            <Row className="align-items-center">
                                <Col className="col-6">
                                    <Button type="submit">Save Changes</Button>
                                </Col>
                                <Col className="col-6 text-end">
                                    <Button type="button" variant="link" onClick={handleCancel}>Cancel</Button>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Footer>
                </Form>
            </Modal>
        </React.Fragment>
    );
}

export default AssemblyCosts;