import React, { Component } from 'react';
import authService from './api-authorization/AuthorizeService';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { RecurringCostsGroupedByYear } from './RecurringCostsGroupedByYear.js';
import ItemsList from './ItemsList.js';

export class AssetRecurringCosts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentView: 'byYear',
            yearFilter: '',
            group1: [],
            group2: [],
            group3: []
        };
    }

    componentDidMount() {
        this.getItems();
    }

    render() {
        const columns = [
            { id: 'lccId', name: 'LCC. ID', sortable: true, filter: null, selectId: null, labelFormat: 'linkToItem', aggregate: false },
            { id: 'system', name: 'System', sortable: true, filter: 'select', selectId: 'systemId', labelFormat: null, aggregate: false },
            { id: 'component', name: 'Component', sortable: true, filter: null, selectId: null, labelFormat: null, aggregate: false },
            { id: 'nextScheduled', name: 'Next Scheduled', sortable: true, filter: 'yearRange', selectId: null, labelFormat: null, aggregate: false, defaultFilter: this.state.yearFilter },
            { id: 'project', name: 'Project', sortable: true, filter: null, selectId: null, labelFormat: 'linkToProject', aggregate: false },
            { id: 'replacementCost', name: 'Replacement Cost', sortable: true, filter: null, selectId: null, labelFormat: 'dollar', aggregate: true },
        ];

        const mapFunction = (q) => {
            return {
                id: q.id,
                lccId: q.id,
                system: q.system,
                systemId: q.systemId,
                component: q.component,
                nextScheduled: q.nextScheduled,
                project: q.project,
                projectId: q.projectId,
                replacementCost: q.replacementCost
            }
        };

        const selectYear = (year) => {
            this.setState({ currentView: 'all', yearFilter: year });
        };

        return (
            <div class="card">
                <div class="card-header">
                    <div class="row align-items-center">
                        <div class="col-3">
                            {this.state.currentView !== 'byYear' && <button type="button" class="btn btn-link" onClick={() => {this.setState({currentView: 'byYear'})}}>Recurring Costs By Year</button>}
                            {this.state.currentView === 'byYear' && 'Recurring Costs By Year'}
                        </div>
                        <div class="col-3">
                            {this.state.currentView !== 'all' && <button type="button" class="btn btn-link" onClick={() => { this.setState({ currentView: 'all', yearFilter: '' }) }}>All Recurring Costs</button>}
                            {this.state.currentView === 'all' && 'All Recurring Costs'}                            
                        </div>
                        <div class="col-6 text-end">
                            <a href={`/recurringcosts/add?assetId=${this.props.assetId}` } class="btn btn-primary">Add Recurring Cost</a>
                        </div>
                    </div>
                </div>
                {this.state.currentView === 'byYear' && <div class="card-body">
                    <div class="row">
                        <div class="col-4">
                            <RecurringCostsGroupedByYear items={this.state.group1} selectYear={selectYear} />
                        </div>
                        <div class="col-4">
                            <RecurringCostsGroupedByYear items={this.state.group2} selectYear={selectYear} />
                        </div>
                        <div class="col-4">
                            <RecurringCostsGroupedByYear items={this.state.group3} selectYear={selectYear} />
                        </div>
                    </div>
                </div>}
                {this.state.currentView === 'all' && <div class="card-body">
                    <ItemsList columns={columns}
                        itemName=""
                        addItemUrl=""
                        mapFunction={mapFunction}
                        getItemsUrl={`api/assets/recurringcosts?id=${this.props.assetId}`}
                        uniqueIdColumn="lccId"
                        showHeader={false}
                        itemUrlBase='recurringCosts'
                        itemUrlParams={`assetId=${this.props.assetId}`}
                        shouldUpdate={true}
                    />
                </div>}
            </div>
        );
    }

    async getItems() {
        const token = await authService.getAccessToken();
        const response = await fetch(`api/assets/recurringCostsByYear?id=${this.props.assetId}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const items = await response.json();
        const currentYear = new Date().getFullYear();
        this.setState({
            group1: this.getGroupYears(currentYear, items),
            group2: this.getGroupYears(currentYear + 10, items),
            group3: this.getGroupYears(currentYear + 20, items)
        });
    }

    getGroupYears(startYear, items) {
        const groupYears = []
        for (let i = startYear; i < startYear + 10; i++) {
            const item = items.find(q => q.year === i);
            if (item !== undefined) {
                groupYears.push({ ...item })
            } else {
                groupYears.push({ year: i, total: 0, count: 0 });
            }
        }
        return groupYears;
    }
}
