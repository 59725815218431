import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import authService from '../api-authorization/AuthorizeService'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { NumericFormat } from 'react-number-format';
import { XLg } from 'react-bootstrap-icons';
import DatePicker from 'react-datepicker';
import Tab from 'react-bootstrap/Tab';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Uniformat from './Uniformat.js';
import AssemblyCosts from './AssemblyCosts.js';
import UnitCosts from './UnitCosts.js';
import RequirementTemplates from './RequirementTemplates.js';
import utils from '../../utils';

const Settings = () => {
    const [queryParameters] = useSearchParams();
    const [key, setKey] = useState(queryParameters.get("tabKey") != null ? queryParameters.get("tabKey") : 'costEscalation');
    const [isAdmin, setIsAdmin] = useState(false);
    const [loading, setLoading] = useState(true);


    const [investments, setInvestments] = useState([]);
    const [validated, setValidated] = useState(false);
    const [minYear, setMinYear] = useState(0);
    const [maxYear, setMaxYear] = useState(0);
    const [costEscalation, setCostEscalation] = useState(0.0);
    const [costEscalationSetting, setCostEscalationSetting] = useState(0.0);
    const [costEscalationValidated, setCostEscalationValidated] = useState(false);

    useEffect(() => {
        if (loading) {
            async function adminCheck() {
                const user = await authService.getUser();
                if (user.hasOwnProperty('role') && (user.role === 'Admin' || user.role.indexOf('Admin') >= 0)) {
                    await setIsAdmin(true);
                    await populateItems();
                }
                await setLoading(false);
            }

            async function populateItems() {
                await getItems();

                // States:
                // 0 - New - stays new until saved, if 'deleted' then simply remove from the array - on save add
                // 1 - Existing loaded, unmodified - on save do nothing
                // 2 - Existing loaded, modified - on save update
                // 3 - Existing deleted - on save delete
                const d = new Date();
                setMinYear(d.getFullYear());
                setMaxYear(d.getFullYear() + 100);

                await populateInvestments();
            }

            adminCheck()
        }        
    }, [loading]);

    async function getItems() {
        const token = await authService.getAccessToken();
        const response = await fetch('api/settings/globalSetting?id=EscalationRate', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });

        const data = await response.json();
        setCostEscalationSetting(data);
        setCostEscalation(parseFloat(data.value) * 100);
    }

    async function populateInvestments() {
        const token = await authService.getAccessToken();
        const response = await fetch('api/capitalplans', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });

        const data = await response.json();
        await data.forEach(i => i.state = 1);

        setInvestments(data);
    }

    const handleAddYear = () => {
        let newInvestment = {
            id: 0,
            fiscalYear: minYear,
            state: 0,
            investmentAmount: 0
        }

        let tempInvestments = investments;
        tempInvestments.push(newInvestment);
        setInvestments([...tempInvestments]);
    };

    const handleRemoveYear = (investment) => {
        investment.state = 3;
        let tempInvestments = investments;
        setInvestments([...tempInvestments]);
    }

    const handleSave = (event) => {
        async function deleteAttachmentData(id) {
            const token = await authService.getAccessToken();
            const response = await fetch('api/capitalplans/' + id, {
                method: 'DELETE',
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
            });

            return response;
        };

        async function saveInvestmentData(investment) {
            const token = await authService.getAccessToken();
            const response = await fetch('api/capitalplans/' + investment.id, {
                method: 'PUT',
                headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
                body: JSON.stringify(investment),
            });

            return response;
        };

        async function addInvestmentData(investment) {

            const token = await authService.getAccessToken();

            const response = await fetch('api/capitalplans', {
                method: 'POST',
                headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
                body: JSON.stringify(investment),
            });

            return response;
        };

        async function deleteInvestments(deletes) {
            await Promise.all(deletes.map(i => deleteAttachmentData(i.id)));
        }

        async function modifyInvestments(modifies) {
            await Promise.all(modifies.map(i => saveInvestmentData(i)));
        }

        async function addInvestments(adds) {
            await Promise.all(adds.map(i => addInvestmentData(i)));
        }

        async function doSave() {

            await Promise.all([deleteInvestments(investments.filter(i => i.state === 3 && i.id !== 0)),
            modifyInvestments(investments.filter(i => i.state === 2)),
            addInvestments(investments.filter(i => i.state === 0))]);

            var newInvestments = [];
            await setInvestments(newInvestments);

            await setLoading(true);
        }

        const form = event.currentTarget;

        event.preventDefault();

        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
            return;
        }

        setValidated(false);

        doSave();
    }

    const handleCostEscalationSave = async (event) => {
        const form = event.currentTarget;

        event.preventDefault();

        if (form.checkValidity() === false) {
            event.stopPropagation();
            setCostEscalationValidated(true);
            return;
        }

        setCostEscalationValidated(false);

        const globalSetting = utils.getCopy(costEscalationSetting);
        globalSetting.value = (Math.round((costEscalation / 100.0) * 10000) / 10000).toString();
        const token = await authService.getAccessToken();
        await fetch('api/settings/globalSetting/', {
            method: 'PUT',
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(globalSetting),
        });
        await setLoading(true);
    };

    return loading ? (
        <p><em>Loading...</em></p>
    ) : (
        isAdmin ? (
                <React.Fragment>
                    <div>
                        <Card className="mb-2">
                            <Card.Body>
                                <Card.Title>
                                    Settings
                                </Card.Title>
                            </Card.Body>
                        </Card>
                        <Tab.Container defaultActiveKey="costEscalation" activeKey={key} onSelect={(k) => setKey(k)}>
                            <Row>
                                <Col sm={3}>
                                    <Card>
                                        <Card.Body>
                                            <Nav variant="pills" className="flex-column">
                                                <Nav.Item>
                                                    <Nav.Link eventKey="costEscalation">Cost Escalation</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="capitalPlan">Capital Plan</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="uniformat">Uniformat</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="unitCosts">Unit Costs</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="assemblyCosts">Assembly Costs</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="requirementTemplates">Requirement Templates</Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col sm={9}>
                                    <Card>
                                        <Card.Body>
                                            <Tab.Content>
                                                <Tab.Pane eventKey="costEscalation">
                                                    <Card className="col-4">
                                                        <Form noValidate validated={validated} onSubmit={handleCostEscalationSave}>
                                                            <Card.Header>Annual Cost Escalation Rate</Card.Header>
                                                            <Card.Body>
                                                                <InputGroup>                                                                    
                                                                    <Form.Control type="Text" required as={NumericFormat} thousandSeparator={true}
                                                                        value={costEscalation} decimalScale={2} fixedDecimalScale
                                                                        allowNegative={false}
                                                                        onValueChange={(values) => {
                                                                            setCostEscalation(values.floatValue);
                                                                        }}
                                                                        isAllowed={(values) => {
                                                                            const { floatValue } = values;
                                                                            return floatValue <= 100;
                                                                        }}
                                                                        pattern="(?!0\.00)^\d{1,3}(?:,\d{3})*\.\d*$"
                                                                    />
                                                                    <InputGroup.Text>%</InputGroup.Text>
                                                                </InputGroup>
                                                            </Card.Body>
                                                            <Card.Footer>
                                                                <Button type="submit">Save Changes</Button>
                                                            </Card.Footer>
                                                        </Form>
                                                    </Card>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="capitalPlan">
                                                    <Card style={{ width: '75%' }}>
                                                        <Form noValidate validated={validated} onSubmit={handleSave}>
                                                            <Card.Header>Capital Plan Settings</Card.Header>
                                                            <Card.Body>
                                                                {investments.some((i) => i.state < 3) ?
                                                                    <Table style={{ width: '75%' }} >
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Fiscal Year</th>
                                                                                <th>Investment Amount</th>
                                                                                <th />
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {investments.map((investment, rowIndex) =>
                                                                                investment.state !== 3 &&
                                                                                <tr key={rowIndex}>
                                                                                    <td class="text-end">
                                                                                            <DatePicker className="form-control" required disabled={investment.state !== 0}
                                                                                                selected={new Date((investment.fiscalYear + 1).toString())} showYearPicker dateFormat="yyyy"
                                                                                                minDate={new Date((minYear + 1).toString())} maxDate={new Date((maxYear + 1).toString())}
                                                                                                onChange={(date) => {
                                                                                                    investment.fiscalYear = date == null ? minYear : date.getFullYear();
                                                                                                    let newInvestments = investments;
                                                                                                    setInvestments([...newInvestments]);
                                                                                                }}
                                                                                            />
                                                                                    </td>
                                                                                    <td class="text-end">
                                                                                        <InputGroup>
                                                                                            <InputGroup.Text>$</InputGroup.Text>
                                                                                            <Form.Control type="Text" required as={NumericFormat} thousandSeparator={true}
                                                                                                defaultValue={investment.investmentAmount} decimalScale={2} fixedDecimalScale
                                                                                                allowNegative={false}
                                                                                                onValueChange={(values) => {
                                                                                                    const { floatValue } = values;
                                                                                                    investment.investmentAmount = Number(floatValue);

                                                                                                    if (investment.state !== 0) {
                                                                                                        investment.state = 2;
                                                                                                    }
                                                                                                }}
                                                                                                isAllowed={(values) => {
                                                                                                    const { floatValue } = values;
                                                                                                    return floatValue <= 10000000000;
                                                                                                }}
                                                                                                pattern="(?!0\.00)^\d{1,3}(?:,\d{3})*\.\d*$"
                                                                                            />
                                                                                        </InputGroup>
                                                                                    </td>
                                                                                    <td>
                                                                                        <Button variant="link" onClick={() => { handleRemoveYear(investment) }}><XLg color="red" /></Button>
                                                                                    </td>
                                                                                </tr>
                                                                            )}
                                                                        </tbody>
                                                                    </Table>
                                                                    :
                                                                    <div>
                                                                        No investment data saved, click "Add Year" to add investment data.
                                                                    </div>
                                                                }
                                                                <Button variant="link" onClick={() => { handleAddYear() }}>Add Year</Button>
                                                            </Card.Body>
                                                            <Card.Footer>
                                                                <Button type="submit">Save Changes</Button>
                                                            </Card.Footer>
                                                        </Form>
                                                    </Card>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="uniformat">
                                                    <Uniformat />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="unitCosts">
                                                    <UnitCosts />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="assemblyCosts">
                                                    <AssemblyCosts />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="requirementTemplates">
                                                    <RequirementTemplates />
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Tab.Container>

            {/*<p></p>*/}
            {/*<Card>*/}
            {/*    <Card.Body>*/}
            

            {/*    </Card.Body>*/}
            {/*            </Card>*/}
                    </div>
                </React.Fragment>
            ) : (
                    <React.Fragment>
                        <Card>
                            <Card.Body>
                                <Card.Title>
                                    Settings
                                </Card.Title>
                                Your are not authorized to view this content.  Please return <a href="/">home</a>.
                            </Card.Body>
                        </Card>
                    </React.Fragment>
        )
    );
}

export default Settings;