import React, { useEffect, useState } from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import ItemsList from './ItemsList.js';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import authService from './api-authorization/AuthorizeService'

const RecurringCosts = () => {

    const [loading, setLoading] = useState(true);
    const [recurringCostReportUrl, setRecurringCostReportUrl] = useState('');

    useEffect(() => {
        if (loading) {
            async function populateItem() {
                const token = await authService.getAccessToken();
                const response = await fetch('api/recurringcosts/reportUrl', {
                    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                });

                const url = await response.text();
                await setRecurringCostReportUrl(url);
                await setLoading(false);
            }
            populateItem();
        }
    }, [loading]);

    const columns = [
        { id: 'lccId', name: 'LCC. ID', sortable: true, filter: null, selectId: null, labelFormat: 'linkToItem', aggregate: false },
        { id: 'facilityName', name: 'Facility Name', sortable: true, filter: 'search', selectId: null, labelFormat: null, aggregate: false },
        { id: 'system', name: 'System', sortable: true, filter: 'select', selectId: 'systemId', labelFormat: null, aggregate: false },
        { id: 'component', name: 'Component', sortable: true, filter: null, selectId: null, labelFormat: null, aggregate: false },
        { id: 'nextScheduled', name: 'Next Scheduled', sortable: true, filter: 'yearRange', selectId: null, labelFormat: null, aggregate: false, defaultFilter: '' },
        { id: 'project', name: 'Project', sortable: true, filter: null, selectId: null, labelFormat: 'linkToProject', aggregate: false },
        { id: 'replacementCost', name: 'Replacement Cost', sortable: true, filter: null, selectId: null, labelFormat: 'dollar', aggregate: true },
    ];

    const mapFunction = (q) => {
        return {
            id: q.id,
            lccId: q.id,
            facilityName: q.facilityName,
            system: q.system,
            systemId: q.systemID,
            component: q.component,
            nextScheduled: q.nextScheduled,
            project: q.project,
            projectId: q.projectID,
            replacementCost: q.replacementCost
        }
    }

    return (
        <div>
            <div class="card mb-2">
                <div class="card-body">
                    <Row>
                        <Col>
                            <span class="h5">Recurring Costs</span>
                        </Col>
                        <Col className="text-end">
                            <a href={recurringCostReportUrl} rel="noreferrer" target="_blank">View Recurring Cost Report</a>
                        </Col>
                    </Row>
                </div>
            </div>
            <ItemsList
                columns={columns}
                itemName="Recurring Cost"
                addItemUrl="recurringcosts/add"
                mapFunction={mapFunction}
                getItemsUrl="api/recurringcosts"
                uniqueIdColumn="lccId"
                showHeader={true}
                itemUrlBase="recurringCosts"
                shouldUpdate={true}
            />
        </div>
    );
}

export default RecurringCosts;