import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import authService from './api-authorization/AuthorizeService'
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Card from 'react-bootstrap/Card';
import Tab from 'react-bootstrap/Tab';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import { Clipboard2, Coin, Paperclip, ArrowReturnRight, XCircle, Pencil } from 'react-bootstrap-icons';
import Pagination from 'react-bootstrap/Pagination';
import RequirementAttachments from './RequirementAttachments';
import utils from '../utils';
import InputGroup from 'react-bootstrap/InputGroup';
import { NumericFormat } from 'react-number-format';

const Requirement = () => {
    const _assetId = 'assetId';
    const _systemTypeId = 'systemTypeId';
    const { id } = useParams();

    const navigate = useNavigate();
    const [queryParameters] = useSearchParams();

    const [addMode, setAddMode] = useState(id.toUpperCase() === 'ADD');
    const [assetMode] = useState(queryParameters.get(_assetId) !== null);
    const [asset, setAsset] = useState(null)
    const [systemMode] = useState(queryParameters.get(_systemTypeId) !== null);
    const [system, setSystem] = useState(null)

    const [loading, setLoading] = useState(true);
    const [validated, setValidated] = useState(false);
    const [uniformat2, setUniformat2] = useState('');
    const [uniformat3, setUniformat3] = useState('');
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showAssemblyCostsModal, setShowAssemblyCostsModal] = useState(false);
    const [showUnitCostsModal, setShowUnitCostsModal] = useState(false);

    const [requirement, setRequirement] = useState(null);
    const [assets, setAssets] = useState([]);
    const [systems, setSystems] = useState([]);
    const [inspectors, setInspectors] = useState([]);
    const [priorities, setPriorities] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const [requirementCategories, setRequirementCategories] = useState([]);
    const [uniformats, setUniformats] = useState([]);
    const [requirementTypes, setRequirementTypes] = useState([]);
    const [assemblyCosts, setAssemblyCosts] = useState([]);
    const [assemblyCostsInView, setAssemblyCostsInView] = useState([]);
    const [unitCosts, setUnitCosts] = useState([]);
    const [assemblyCostsToAdd, setAssemblyCostsToAdd] = useState([]);
    const [unitCostsToAdd, setUnitCostsToAdd] = useState([]);
    const [requirementCosts, setRequirementCosts] = useState([]);    
    const [units, setUnits] = useState([]);
    const [showDeleteCostModal, setShowDeleteCostModal] = useState(false);
    const [deleteCostId, setDeleteCostId] = useState('');
    const [requirementCostSources, setRequirementCostSources] = useState([]);
    const [showOverrideCostModal, setShowOverrideCostModal] = useState(false);
    const [overrideCostValidated, setOverrideCostValidated] = useState(false);
    const [overrideCostSourceID, setOverrideCostSourceID] = useState('');
    const [overrideCost, setOverrideCost] = useState('');
    const [showUpdateCostModal, setShowUpdateCostModal] = useState(false);
    const [updateCostValidated, setUpdateCostValidated] = useState(false);
    const [updateCost, setUpdateCost] = useState({
        requirementCostID: -1,
        unitID: '',
        quantity: ''
    });
    const [unitCostsInView, setUnitCostsInView] = useState([]);
    const [pageSize, setPageSize] = useState('10');
    const [pageNumber, setPageNumber] = useState('1');
    const [pages, setPages] = useState(['1']);
    const [totalPages, setTotalPages] = useState(1);
    const [requirementCostsInView, setRequirementCostsInView] = useState([]);
    const [quickEntryTemplates, setQuickEntryTemplates] = useState([]);
    const [assemblyCostsFilter, setAssemblyCostsFilter] = useState({
        costType: 'showAll',
        description: ''
    });
    const [unitCostsFilter, setUnitCostsFilter] = useState({
        costType: 'showAll',
        description: ''
    });
    const [showSaveTemplateModal, setShowSaveTemplateModal] = useState(false);
    const [template, setTemplate] = useState({
        saveMode: 'new',
        name: '',
        id: 0,
        overwrite: false,
        errorMessage: ''
    });
    const [editTemplate, setEditTemplate] = useState({});
    const [saveTemplateValidated, setSaveTemplateValidated] = useState(false);   

    useEffect(() => {
        if (loading) {
            async function populateItem() {                
                if (addMode) {
                    await setRequirement({
                        assetID: assetMode ? queryParameters.get(_assetId) : '',
                        systemTypeID: systemMode ? parseInt(queryParameters.get(_systemTypeId)) : '',
                        inspectorID: '',
                        priorityID: '',
                        statusID: '',
                        inspectionDate: '',
                        requirementCategoryID: '',
                        uniformatID: '',
                        requirementTypeId: '',
                        assetIDTag: '',
                        buildingLocation: '',
                    });
                } else {       
                    const token = await authService.getAccessToken();
                    const response = await fetch('api/requirements/' + id, {
                        headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                    });
                    const item = await response.json();
                    const inspectionDate = new Date(item.inspectionDate);
                    item.inspectionDate = getDateString(inspectionDate);
                    await setUniformat2(item.uniformat.level2);
                    await setUniformat3(item.uniformat.level3);
                    await setRequirement(item);
                    if (item.requirementTemplate) {
                        const _template = utils.getCopy(template);
                        _template.saveMode = item.requirementTemplate.overwrite ? 'overwrite' : 'new';
                        _template.id = item.requirementTemplate.id;
                        _template.name = item.requirementTemplate.name;
                        _template.overwrite = item.requirementTemplate.overwrite
                        await setTemplate(_template);
                    }
                    await refreshRequirementCosts();
                }               

                await populateArrays();
                await setLoading(false);
            }

            async function populateArrays() {
                const token = await authService.getAccessToken();
                const response = await fetch(`api/requirements/itemsForAdd?id=${id.toUpperCase() === 'ADD' ? -1 : id}`, {
                    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                });
                const item = await response.json();
                await setAssets(item.assets);
                if (assetMode) {
                    await setAsset(item.assets.find(q => q.id === queryParameters.get(_assetId)))
                }
                await setSystems(item.systemTypes);
                if (systemMode) {
                    await setSystem(item.systemTypes.find(q => q.id === parseInt(queryParameters.get(_systemTypeId))))
                }
                await setInspectors(item.inspectors);
                await setPriorities(item.priorities);
                await setStatuses(item.statuses);
                await setRequirementCategories(item.requirementCategories);
                await setUniformats(item.uniformats);
                await setRequirementTypes(item.requirementTypes);
                await setAssemblyCosts(item.assemblyCosts);
                await setAssemblyCostsInView([...item.assemblyCosts]);
                await setUnitCosts(item.unitCosts);
                await setUnitCostsInView([...item.unitCosts]);
                await setUnits(item.units);
                await setRequirementCostSources(item.requirementCostSources);
                await setQuickEntryTemplates(item.requirementTemplates);
            }

            function getDateString(date) {
                const year = date.getFullYear();
                const month = date.getMonth() + 1;
                const day = date.getDate();
                return `${year}-${month >= 10 ? month : '0' + month}-${day >= 10 ? day : '0' + day}`;
            }

            populateItem();
        }
    }, [loading, id]);

    const handleSave = async (event) => {
        const form = event.currentTarget;

        event.preventDefault();

        if (form.checkValidity() === false) {
            event.stopPropagation();
            await setValidated(true);
            return;
        }

        const token = await authService.getAccessToken();

        var requirementInput = utils.getCopy(requirement);
        requirementInput.requirementCostSource = null;
        requirementInput.requirementTemplate = null;
        requirementInput.uniformat = null;

        let newRequirement = null;
        if (addMode) {
            const response = await fetch('api/requirements', {
                method: 'POST',
                headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
                body: JSON.stringify(requirementInput),
            });
            newRequirement = await response.json();
        } else {
            await fetch(`api/requirements/${requirement.id}`, {
                method: 'PUT',
                headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
                body: JSON.stringify(requirementInput),
            });
        }

        let path = `/requirements/`;
        if (assetMode) {
            path = `/assets/${asset.id}?tabKey=requirements`;
        } else if (addMode) {
            path = `/requirements/${newRequirement.id}`;
            await setAddMode(false);
            await setLoading(true);
        }
        await navigate(path);        
    };

    const handleDelete = async () => {
        const token = await authService.getAccessToken();
        await fetch(`api/requirements/${requirement.id}`, {
            method: 'DELETE',
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        });

        let path = '/requirements';
        if (assetMode) {
            path = `/assets/${asset.id}?tabKey=requirements`;
        }
        navigate(path);
    };

    const handleAddAssemblyCosts = async () => {
        const token = await authService.getAccessToken();
        await fetch('api/requirements/addassemblycosts', {
            method: 'POST',
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({ costIDs: assemblyCostsToAdd, requirementID: parseInt(id) }),
        });
        await refreshRequirementCosts();
    };

    const handleAddUnitCosts = async () => {
        const token = await authService.getAccessToken();
        await fetch('api/requirements/addunitcosts', {
            method: 'POST',
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({ costIDs: unitCostsToAdd, requirementID: parseInt(id) }),
        });
        await refreshRequirementCosts();
    };

    const handleDeleteCost = async () => {
        const token = await authService.getAccessToken();
        await fetch(`api/requirements/deletecost?id=${deleteCostId}`, {
            method: 'DELETE',
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        });
        await refreshRequirementCosts();
    };

    const handleOverrideCost = async (event) => {
        const form = event.currentTarget;

        event.preventDefault();

        if (form.checkValidity() === false) {
            event.stopPropagation();
            setOverrideCostValidated(true);
            return;
        }

        const token = await authService.getAccessToken();
        const item = utils.getCopy(requirement)
        item.requirementCostSource = null;
        item.uniformat = null;
        item.requirementCostSourceID = overrideCostSourceID;
        item.overrideCost = overrideCostSourceID === 'CALC' ? null : overrideCost
        item.overrideCostDate = overrideCostSourceID === 'CALC' ? null : new Date;

        await fetch(`api/requirements/${requirement.id}`, {
            method: 'PUT',
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(item),
        });
        const response = await fetch('api/requirements/' + id, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const updatedRequirement = await response.json();
        setRequirement(updatedRequirement);
        setShowOverrideCostModal(false);
    };

    const handleUpdateCost = async (event) => {
        const form = event.currentTarget;

        event.preventDefault();

        if (form.checkValidity() === false) {
            event.stopPropagation();
            setUpdateCostValidated(true);
            return;
        }

        const token = await authService.getAccessToken();
        await fetch(`api/requirements/updatecost`, {
            method: 'PUT',
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(updateCost),
        });
        await refreshRequirementCosts();
        setShowUpdateCostModal(false);
    };

    const refreshRequirementCosts = async () => {
        const token = await authService.getAccessToken();
        const response = await fetch(`api/requirements/costs?id=${id}`, {
            method: 'GET',
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        });
        const costs = await response.json();
        await setRequirementCosts([...costs]);   
        await updateRequirementCostsInView(pageNumber, pageSize, [...costs]);
    };

    const updatePageSize = async (e) => {
        await setPageSize(e.target.value);
        await setPageNumber('1');
        await updateRequirementCostsInView('1', e.target.value, [...requirementCosts]);
    };

    const updateRequirementCostsInView = async (tempPageNumber, tempPageSize, tempCosts) => {
        let tempTotalPages = 1;
        if (tempCosts.length > 0) {
            tempTotalPages = Math.ceil(tempCosts.length / parseInt(tempPageSize));
        }        
        
        let minPage = tempPageNumber === '1' ? 1 : parseInt(tempPageNumber) - 1;
        const maxPage = tempTotalPages < minPage + 2 ? tempTotalPages : minPage + 2;
        if (maxPage === parseInt(tempPageNumber) && maxPage > 2) {
            minPage = maxPage - 2;
        }

        const tempPages = [];
        for (let i = minPage - 1; i < maxPage; i++) {
            tempPages.push((i + 1).toString());
        }

        await setPageNumber(tempPageNumber);
        await setPageSize(tempPageSize);
        await setPages(tempPages);
        await setTotalPages(tempTotalPages);
        await setRequirementCostsInView(tempCosts.slice(parseInt(tempPageSize) * (parseInt(tempPageNumber) - 1), parseInt(tempPageSize) * parseInt(tempPageNumber)));
    };

    const updateAssemblyCostsFilter = async (costType, description) => {
        const filter = utils.getCopy(assemblyCostsFilter);
        filter.costType = costType;
        filter.description = description;
        await setAssemblyCostsFilter(filter);
        await setAssemblyCostsInView([...assemblyCosts]
            .filter(q => costType === 'showAll' || (requirement.requirementTemplate !== null && requirement.requirementTemplate.requirementTemplateAssemblyCosts.map(ac => ac.assemblyCostID).includes(q.id)))
            .filter(q => description === '' || q.description.toUpperCase().includes(description.trim().toUpperCase())));
    };

    const updateUnitCostsFilter = async (costType, description) => {
        const filter = utils.getCopy(unitCostsFilter);
        filter.costType = costType;
        filter.description = description;
        await setUnitCostsFilter(filter);
        await setUnitCostsInView([...unitCosts]
            .filter(q => costType === 'showAll' || (requirement.requirementTemplate !== null && requirement.requirementTemplate.requirementTemplateUnitCosts.map(uc => uc.unitCostID).includes(q.id)))
            .filter(q => description === '' || q.description.toUpperCase().includes(description.trim().toUpperCase())));
    };

    const handleSaveTemplate = async (event) => {
        const form = event.currentTarget;

        event.preventDefault();

        if (form.checkValidity() === false) {
            event.stopPropagation();
            await setSaveTemplateValidated(true);
            return;
        }

        const templateInput = {
            name: editTemplate.name,
            requirementID: requirement.id,
            requirementTemplateId: 0
        };

        const token = await authService.getAccessToken();

        let result = {};
        if (editTemplate.saveMode === 'new') {
            const response = await fetch('api/requirements/addTemplate', {
                method: 'POST',
                headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
                body: JSON.stringify(templateInput),
            });
            result = await response.json();
        } else {
            templateInput.requirementTemplateId = template.id;
            const response = await fetch('api/requirements/updateTemplate', {
                method: 'PUT',
                headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
                body: JSON.stringify(templateInput),
            });
            result = await response.json();
        }
        if (result.success) {
            await setShowSaveTemplateModal(false);
            await setLoading(true);
        } else {
            const item = utils.getCopy(editTemplate);
            item.errorMessage = !result.success && result.hasOwnProperty("message") ? result.message : "There was an error saving the template.";
            setEditTemplate(item);
        }
    };

    return loading ? (
        <p><em>Loading...</em></p>
    ) : (
            <React.Fragment>
                <div>
                    <Card className="mb-2">
                        <Card.Body>
                            <Card.Title>
                                <Breadcrumb>
                                    {(!assetMode && !systemMode) && <Breadcrumb.Item href="/requirements/">Requirements</Breadcrumb.Item>}
                                    {assetMode && <Breadcrumb.Item href="/assets/">Assets</Breadcrumb.Item>}
                                    {assetMode && <Breadcrumb.Item href={`/assets/${asset.id}?tabKey=requirements`}>{asset.facilityName}</Breadcrumb.Item>}
                                    {systemMode && <Breadcrumb.Item href={`/assets/${asset.id}/system/${system.id}`}>{system.name}</Breadcrumb.Item>}
                                    <Breadcrumb.Item active>{`${addMode ? 'Add' : 'Edit'} Requirement`}</Breadcrumb.Item>
                                </Breadcrumb>
                            </Card.Title>                           
                            {assetMode &&
                                <Row>
                                    <Col xs={6}>
                                        <Table borderless>
                                            <tbody>
                                                <tr>
                                                    <th>Facility Code</th>
                                                    <td>{asset.facilityCode}</td>
                                                    <th>Initial CRV</th>
                                                    <td>{utils.getDollarString(asset.initialCRV)}</td>
                                                    <th>Gross SF</th>
                                                    <td>{asset.grossSquareFootage}</td>
                                                </tr>
                                                <tr>
                                                    <th>Year Built</th>
                                                    <td>{asset.yearBuilt}</td>
                                                    <th>Current CRV</th>
                                                    <td>{utils.getDollarString(asset.currentCRV)}</td>
                                                    <th>CRV / SF</th>
                                                    <td>{utils.getDollarString(asset.currentCRV / asset.grossSquareFootage)}</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                            }
                        </Card.Body>
                    </Card>
                    <Tab.Container defaultActiveKey="requirements">
                        <Row>
                            <Col sm={3}>
                                <Card>
                                    <Card.Body>
                                        <Nav variant="pills" className="flex-column">
                                            <Nav.Item>
                                                <Nav.Link eventKey="requirements"><Clipboard2 /> Requirements</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="costs" disabled={addMode}><Coin /> Costs</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="photosDocs" disabled={addMode}><Paperclip /> Photos and Documents</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col sm={9}>
                                <Card>
                                    <Card.Body>
                                        <Tab.Content>
                                            <Tab.Pane eventKey="requirements">                                            
                                                <Form noValidate validated={validated} onSubmit={handleSave}>
                                                    <Row>
                                                        <Col className="col-6">
                                                            <Row className="align-items-center">
                                                                <Col className="fw-bold col-4 p-2">Facility</Col>
                                                                <Col className="col-8 p-2">
                                                                    <Form.Select required defaultValue={requirement.assetID} disabled={!addMode || assetMode} onChange={async (e) => {
                                                                        const item = utils.getCopy(requirement);
                                                                        item.assetID = e.target.value;
                                                                        setRequirement(item);
                                                                    }} >
                                                                        <option key="default_facility" value="" disabled>Select a Facility...</option>
                                                                        {assets.map(q => <option key={`facility_${q.id}` } value={q.id}>{q.facilityName}</option>)}
                                                                    </Form.Select>
                                                                </Col>                                                        
                                                            </Row>
                                                            <Row className="align-items-center">
                                                                <Col className="fw-bold col-4 p-2">System</Col>
                                                                <Col className="col-8 p-2">
                                                                    <Form.Select required defaultValue={requirement.systemTypeID} disabled={!addMode || systemMode} onChange={(e) => {
                                                                        const item = utils.getCopy(requirement);
                                                                        item.systemTypeID = parseInt(e.target.value);
                                                                        item.uniformatID = '';
                                                                        setRequirement(item);
                                                                        setUniformat2('');
                                                                        document.getElementById("selectUniformat2").value = '';
                                                                        setUniformat3('');
                                                                        document.getElementById("selectUniformat3").value = '';
                                                                        document.getElementById("selectUniformat4").value = '';
                                                                    }} >
                                                                        <option key="default_system" value="" disabled>Select a System...</option>
                                                                        {systems.map(q => <option key={`system_${q.id}`} value={q.id}>{q.name}</option>)}
                                                                    </Form.Select>
                                                                </Col>
                                                            </Row>
                                                            <Row className="align-items-center">
                                                                <Col className="fw-bold col-4 p-2"><ArrowReturnRight /> Level II</Col>
                                                                <Col className="col-8 p-2">
                                                                    <Form.Select id="selectUniformat2" required defaultValue={uniformat2} onChange={(e) => {
                                                                        const item = utils.getCopy(requirement);
                                                                        item.uniformatID = '';
                                                                        setRequirement(item);
                                                                        setUniformat2(e.target.value);
                                                                        setUniformat3('');
                                                                        document.getElementById("selectUniformat3").value = '';
                                                                        document.getElementById("selectUniformat4").value = '';
                                                                    }} >
                                                                        <option key="default_level2" value="" disabled>Select an Uniformat...</option>
                                                                        {[...new Set(uniformats.filter(q => q.systemTypeID === parseInt(requirement.systemTypeID)).map(q => q.level2))].map(q => <option key={`level2_${q}`} value={q}>{q}</option>)}
                                                                    </Form.Select>
                                                                </Col>
                                                            </Row>
                                                            <Row className="align-items-center">
                                                                <Col className="fw-bold col-4 p-2"><ArrowReturnRight /> Level III</Col>
                                                                <Col className="col-8 p-2">
                                                                    <Form.Select id="selectUniformat3" required defaultValue={uniformat3} onChange={(e) => {
                                                                        const item = utils.getCopy(requirement);
                                                                        item.uniformatID = '';
                                                                        setRequirement(item);
                                                                        setUniformat3(e.target.value);
                                                                        document.getElementById("selectUniformat4").value = '';
                                                                    }} >
                                                                        <option key="default_level3" value="" disabled>Select an Uniformat...</option>
                                                                        {[...new Set(uniformats.filter(q => q.systemTypeID === parseInt(requirement.systemTypeID) && q.level2 === uniformat2).map(q => q.level3))].map(q => <option key={`level3_${q}`} value={q}>{q}</option>)}
                                                                    </Form.Select>
                                                                </Col>
                                                            </Row>
                                                            <Row className="align-items-center">
                                                                <Col className="fw-bold col-4 p-2"><ArrowReturnRight /> Level IV</Col>
                                                                <Col className="col-8 p-2">
                                                                    <Form.Select id="selectUniformat4" required defaultValue={requirement.uniformatID} onChange={(e) => {
                                                                        const item = utils.getCopy(requirement);
                                                                        item.uniformatID = parseInt(e.target.value);
                                                                        setRequirement(item);
                                                                    }} >
                                                                        <option key="default_level3" value="" disabled>Select an Uniformat...</option>
                                                                        {uniformats.filter(q => q.systemTypeID === parseInt(requirement.systemTypeID) && q.level3 === uniformat3).map(q => <option key={`level4_${q.id}`} value={q.id}>{q.level4}</option>)}
                                                                    </Form.Select>
                                                                </Col>
                                                            </Row>
                                                            <Row className="align-items-center">
                                                                <Col className="fw-bold col-4 p-2">Requirement Type/Issues</Col>
                                                                <Col className="col-8 p-2">
                                                                    <Form.Select required defaultValue={requirement.requirementTypeId} onChange={(e) => {
                                                                        const item = utils.getCopy(requirement);
                                                                        item.requirementTypeId = parseInt(e.target.value);
                                                                        setRequirement(item);
                                                                    }} >
                                                                        <option key="default_requirementType" value="" disabled>Select a Requirement Type/Issue...</option>
                                                                        {requirementTypes.map(q => <option key={`requirementType_${q.id}`} value={q.id}>{q.name}</option>)}
                                                                    </Form.Select>
                                                                </Col>
                                                            </Row>
                                                            <Row className="align-items-center">
                                                                <Col className="fw-bold col-4 p-2">Inspector</Col>
                                                                <Col className="col-8 p-2">
                                                                    <Form.Select required defaultValue={requirement.inspectorID} onChange={(e) => {
                                                                        const item = utils.getCopy(requirement);
                                                                        item.inspectorID = parseInt(e.target.value);
                                                                        setRequirement(item);
                                                                    }} >
                                                                        <option key="default_inspector" value="" disabled>Select an Inspector...</option>
                                                                        {inspectors.map(q => <option key={`inspector_${q.id}`} value={q.id}>{q.name}</option>)}
                                                                    </Form.Select>
                                                                </Col>
                                                            </Row>
                                                            <Row className="align-items-center">
                                                                <Col className="fw-bold col-4 p-2">Priority</Col>
                                                                <Col className="col-8 p-2">
                                                                    <Form.Select required defaultValue={requirement.priorityID} onChange={(e) => {
                                                                        const item = utils.getCopy(requirement);
                                                                        item.priorityID = parseInt(e.target.value);
                                                                        setRequirement(item);
                                                                    }} >
                                                                        <option key="default_priority" value="" disabled>Select a Priority...</option>
                                                                        {priorities.map(q => <option key={`priority_${q.id}`} value={q.id}>{q.name}</option>)}
                                                                    </Form.Select>
                                                                </Col>
                                                            </Row>
                                                            <Row className="align-items-center">
                                                                <Col className="fw-bold col-4 p-2">Status</Col>
                                                                <Col className="col-8 p-2">
                                                                    <Form.Select required defaultValue={requirement.statusID} onChange={(e) => {
                                                                        const item = utils.getCopy(requirement);
                                                                        item.statusID = parseInt(e.target.value);
                                                                        setRequirement(item);
                                                                    }} >
                                                                        <option key="default_status" value="" disabled>Select a Status...</option>
                                                                        {statuses.map(q => <option key={`status_${q.id}`} value={q.id}>{q.name}</option>)}
                                                                    </Form.Select>
                                                                </Col>
                                                            </Row>
                                                            <Row className="align-items-center">
                                                                <Col className="fw-bold col-4 p-2 text-middle">Inspection Date</Col>
                                                                <Col className="col-8 p-2">
                                                                    <Form.Control type="date" value={requirement.inspectionDate} min="1000-01-01" max="9999-12-31" required onChange={(e) => {
                                                                        const item = utils.getCopy(requirement);
                                                                        item.inspectionDate = e.target.value;
                                                                        setRequirement(item);
                                                                    }}>
                                                                    </Form.Control>
                                                                </Col>
                                                            </Row>
                                                            <Row className="align-items-center">
                                                                <Col className="fw-bold col-4 p-2">Requirement Category</Col>
                                                                <Col className="col-8 p-2">
                                                                    <Form.Select required defaultValue={requirement.requirementCategoryID} onChange={(e) => {
                                                                        const item = utils.getCopy(requirement);
                                                                        item.requirementCategoryID = parseInt(e.target.value);
                                                                        setRequirement(item);
                                                                    }} >
                                                                        <option key="default_category" value="" disabled>Select a Requirement Category...</option>
                                                                        {requirementCategories.map(q => <option key={`category_${q.id}`} value={q.id}>{q.name}</option>)}
                                                                    </Form.Select>
                                                                </Col>
                                                            </Row>                                                    
                                                            <Row className="align-items-center">
                                                                <Col className="fw-bold col-4 p-2">Asset ID Tag</Col>
                                                                <Col className="col-8 p-2">
                                                                    <Form.Control type="text" defaultValue={requirement.assetIDTag} required onChange={(e) => {
                                                                        const item = utils.getCopy(requirement);
                                                                        item.assetIDTag = e.target.value;
                                                                        setRequirement(item)
                                                                    }} />
                                                                </Col>
                                                            </Row>
                                                            <Row className="align-items-center">
                                                                <Col className="fw-bold col-4 p-2">Building Location</Col>
                                                                <Col className="col-8 p-2">
                                                                    <Form.Control type="text" defaultValue={requirement.buildingLocation} required onChange={(e) => {
                                                                        const item = utils.getCopy(requirement);
                                                                        item.buildingLocation = e.target.value;
                                                                        setRequirement(item)
                                                                    }} />
                                                                </Col>
                                                            </Row>                                                            
                                                        </Col>
                                                        <Col className="col-6">
                                                            <Card>
                                                                <Card.Header style={{ backgroundColor: '#faf4bd'} }>
                                                                    Quick-entry Templates
                                                                </Card.Header>
                                                                <Card.Body style={{ maxHeight:'620px', overflowY: 'scroll' }}>
                                                                    { 
                                                                        addMode && (requirement.uniformatID === '' || requirement.requirementTypeId === '') &&
                                                                        'Select a uniformat path and requirement type to see available quick-entry templates.'
                                                                    }
                                                                    {
                                                                        addMode && quickEntryTemplates.filter(q => q.uniformatID === requirement.uniformatID && q.requirementTypeID === requirement.requirementTypeId).length === 0 &&
                                                                        'No templates exist for this uniformat/requirement type.'
                                                                    }
                                                                    {
                                                                        addMode && quickEntryTemplates.filter(q => q.uniformatID === requirement.uniformatID && q.requirementTypeID === requirement.requirementTypeId).map(q =>
                                                                            <Card className="mb-2">
                                                                                <Card.Body>
                                                                                    <Row>
                                                                                        <Col className="col-4">Name:</Col>
                                                                                        <Col className="col-5 text-truncate">{q.name}</Col>                                                                                        
                                                                                        <Col className="col-3 text-end">
                                                                                            <Button size="sm" onClick={() => {
                                                                                                const item = utils.getCopy(requirement);
                                                                                                item.description = q.description;
                                                                                                item.action = q.action;
                                                                                                item.requirementTemplateID = q.id
                                                                                                setRequirement(item);
                                                                                            }}>Apply</Button>
                                                                                        </Col>
                                                                                    </Row>
                                                                                    <Row>
                                                                                        <Col className="col-4">Description:</Col>
                                                                                        <Col className="col-8 text-truncate">{q.description}</Col>
                                                                                    </Row>
                                                                                    <Row>
                                                                                        <Col className="col-4">Action:</Col>
                                                                                        <Col className="col-8 text-truncate">{q.action}</Col>
                                                                                    </Row>
                                                                                </Card.Body>
                                                                            </Card>
                                                                        )
                                                                    }
                                                                    {
                                                                        !addMode && <Button onClick={async () => {
                                                                            await setEditTemplate(utils.getCopy(template));
                                                                            await setShowSaveTemplateModal(true)
                                                                        }}>Save as Template</Button>
                                                                    }
                                                                </Card.Body>
                                                            </Card>
                                                        </Col>
                                                    </Row>
                                                    <Row className="align-items-center mt-2">
                                                        <Col className="fw-bold col-5 p-2">Description</Col>
                                                        <Col className="fw-bold col-5 p-2">Proposed Action</Col>
                                                    </Row>
                                                    <Row>
                                                        <Col className="col-5 p-2">
                                                            <Form.Control as="textarea" required value={requirement.description} onChange={(e) => {
                                                                const item = utils.getCopy(requirement);
                                                                item.description = e.target.value;
                                                                setRequirement(item);
                                                            }} />
                                                        </Col>
                                                        <Col className="col-5 p-2">
                                                            <Form.Control as="textarea" required value={requirement.action} onChange={(e) => {
                                                                const item = utils.getCopy(requirement);
                                                                item.action = e.target.value;
                                                                setRequirement(item);
                                                            }} />
                                                        </Col>
                                                    </Row>
                                                    <Row className="p-3">
                                                        <Col className="col-2">
                                                            <Button type="submit">Save Changes</Button>
                                                        </Col>
                                                        <Col className="col-2">
                                                            <Button variant="secondary" onClick={() => {
                                                                let path = '/requirements';
                                                                if (systemMode) {
                                                                    path = `/assets/${requirement.assetID}/system/${requirement.systemTypeID}`;
                                                                } else if (assetMode) {
                                                                    path = `/assets/${requirement.assetID}?tabKey=requirements`;
                                                                }
                                                                navigate(path);
                                                            }}>Cancel</Button>
                                                        </Col>
                                                        <Col className="col-4 text-end">
                                                            <Button variant="danger" disabled={addMode} onClick={() => {
                                                                setShowDeleteModal(true);
                                                            }}>Delete</Button>
                                                        </Col>
                                                    </Row>
                                                </Form>                                            
                                            </Tab.Pane>
                                            { !addMode && 
                                                <Tab.Pane eventKey="costs">
                                                    <Row className="mb-5">
                                                        <Col className="col-6 p-2">
                                                            <Card>
                                                                <Card.Header>Overall Requirement Cost</Card.Header>
                                                                <Card.Body>
                                                                    <Row>
                                                                        <Col className="fw-bold col-6 p-2">Total Requirement Cost</Col>
                                                                        <Col className="col-6 p-2">{utils.getDollarString(requirement.requirementCostSourceID === 'CALC' ? utils.getSum(requirementCosts.map(q => q.subtotal)) : requirement.overrideCost)}</Col>                                                            
                                                                        <Col className="fw-bold col-6 p-2">Source</Col>
                                                                        <Col className="col-6 p-2">{requirement.requirementCostSource.name}</Col>
                                                                    </Row>     
                                                                </Card.Body>
                                                                <Card.Footer>
                                                                    <Button onClick={() => {
                                                                        setOverrideCostSourceID(requirement.requirementCostSourceID);
                                                                        setOverrideCost(requirement.overrideCost);
                                                                        setOverrideCostValidated(false);
                                                                        setShowOverrideCostModal(true);
                                                                    }}>Override Requirement Cost</Button>
                                                                </Card.Footer>
                                                            </Card>
                                                        </Col>
                                                        <Col className="col-6 p-2">
                                                            <Card>
                                                                <Card.Header>Requirement Information</Card.Header>
                                                                <Card.Body>
                                                                    <Row>
                                                                        <Col className="fw-bold col-6">Description</Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col className="col-12">{requirement.description}</Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col className="fw-bold col-6">Proposed Action</Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col className="col-12">{requirement.action}</Col>
                                                                    </Row>
                                                                </Card.Body>
                                                            </Card>
                                                        </Col>
                                                    </Row>
                                                <Row>
                                                    <Col className="col-3 p-2">
                                                            <Button className="w-100" onClick={async () => {
                                                                await updateAssemblyCostsFilter(requirement.requirementTemplateID !== null ? 'showTemplate' : 'showAll', '');
                                                                await setAssemblyCostsToAdd([]);
                                                                await setShowAssemblyCostsModal(true);
                                                            }}>Add Assembly Cost</Button>
                                                    </Col>
                                                    <Col className="col-3 p-2">
                                                            <Button className="w-100" onClick={async () => {
                                                                await updateUnitCostsFilter(requirement.requirementTemplateID !== null ? 'showTemplate' : 'showAll', '');
                                                                await setUnitCostsToAdd([]);
                                                                await setShowUnitCostsModal(true);
                                                            }}>Add Unit Cost
                                                            </Button>
                                                    </Col>
                                                </Row>
                                                <Table striped>
                                                    <thead>
                                                        <tr>
                                                            <th>Cost Code</th>
                                                            <th>Description</th>
                                                            <th>Unit</th>
                                                            <th>Unit Cost</th>
                                                            <th>Quantity</th>
                                                            <th>Subtotal</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                            {requirementCostsInView.map(q => <tr key={`costs_row_${q.costCode}` }>
                                                            <td>{q.costCode}</td>
                                                            <td>{q.description}</td>
                                                            <td>{q.unit.name}</td>
                                                            <td>{utils.getDollarString(q.unitCost)}</td>
                                                            <td>{q.quantity}</td>
                                                            <td>{utils.getDollarString(q.subtotal)}</td>
                                                            <td>
                                                                <Button variant="link" onClick={() => {
                                                                    setUpdateCost({ requirementCostID: q.id, unitID: q.unit.id, quantity: q.quantity });
                                                                    setUpdateCostValidated(false);
                                                                    setShowUpdateCostModal(true);
                                                                }}><Pencil color="black" /></Button>
                                                                <Button variant="link" onClick={() => {
                                                                setDeleteCostId(q.id);
                                                                setShowDeleteCostModal(true);
                                                            }}><XCircle color="red" /></Button></td>
                                                        </tr>)}
                                                    </tbody>
                                                </Table>
                                                <Row className="align-items-center">
                                                    <Col className="col-2">
                                                        <Form.Select defaultValue={pageSize} onChange={updatePageSize}>
                                                            <option key="pageSize_10" value="10">10</option>
                                                                <option key="pageSize_20" value="20">20</option>
                                                                <option key="pageSize_30" value="30">30</option>
                                                                <option key="pageSize_50" value="50">50</option>
                                                        </Form.Select>                                                        
                                                    </Col>
                                                    <Col className="col-2">Items per page</Col>
                                                    <Col className="col-8">
                                                        <Pagination className="float-end m-0">
                                                            <Pagination.Prev key="previousPage" disabled={pageNumber === '1'} onClick={async () => {
                                                                const tempPageNumber = (parseInt(pageNumber) - 1).toString();
                                                                await updateRequirementCostsInView(tempPageNumber, pageSize, [...requirementCosts]);
                                                            }}/>
                                                                {pages.map(q => <Pagination.Item key="currentPage" active={pageNumber === q} onClick={async () => {
                                                                await updateRequirementCostsInView(q, pageSize, [...requirementCosts]);
                                                            }}>{q}</Pagination.Item>)}                                                            
                                                                <Pagination.Next key="nextPage" disabled={pageNumber === totalPages.toString()} onClick={async () => {
                                                                const tempPageNumber = (parseInt(pageNumber) + 1).toString();
                                                                await updateRequirementCostsInView(tempPageNumber, pageSize, [...requirementCosts]);
                                                            }} />
                                                        </Pagination>
                                                    </Col>
                                                </Row>
                                                </Tab.Pane>                                                
                                            }
                                            {!addMode &&
                                                <Tab.Pane eventKey="photosDocs">
                                                    <RequirementAttachments requirementID={id} />
                                                </Tab.Pane>
                                            }
                                        </Tab.Content>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>                        
                    </Tab.Container>
                    <Modal size="sm" show={showDeleteModal} onHide={() => { setShowDeleteModal(false) }} backdrop="static">
                        <Modal.Header closeButton>
                            <Modal.Title>Delete</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Are you sure you want to delete this requirement?</Modal.Body>
                        <Modal.Footer>
                            <Container>
                                <Row>
                                    <Col className="col-6">
                                        <Button onClick={() => {
                                            setShowDeleteModal(false);
                                            handleDelete();
                                        }}>Yes</Button>
                                    </Col>
                                    <Col className="col-6 text-end">
                                        <Button variant="secondary" onClick={() => { setShowDeleteModal(false) }}>No</Button>
                                    </Col>
                                </Row>
                            </Container>
                        </Modal.Footer>
                    </Modal>
                    <Modal size="xl" fullscreen='xl-down' show={showAssemblyCostsModal} onHide={() => { setShowAssemblyCostsModal(false) }} backdrop="static">
                        <Modal.Header closeButton>
                            <Modal.Title>Add Assembly Costs</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {requirement.requirementTemplateID !== null &&
                                <Row className="mb-3">
                                    <Col className="col-4">
                                        <Form.Select value={assemblyCostsFilter.costType} onChange={async (e) => {
                                            await updateAssemblyCostsFilter(e.target.value, assemblyCostsFilter.description)
                                        }}>
                                            <option key="showAll" value="showAll">Show all costs</option>
                                            <option key="showTemplate" value="showTemplate">Show costs from template</option>
                                        </Form.Select>
                                    </Col>
                                </Row>
                            }
                            <Table striped>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Cost Code</th>
                                        <th>Description</th>
                                        <th>Unit</th>
                                        <th>Cost</th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th></th>
                                        <th>
                                            <input type="search" class="form-control" placeholder="Search" onChange={async (e) => {
                                                const searchString = e.target.value;
                                                await updateAssemblyCostsFilter(assemblyCostsFilter.costType, searchString);
                                            }} value={assemblyCostsFilter.description} />
                                        </th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {assemblyCostsInView.map(q => <tr key={`assemblyCosts_row_${q.costCode}` }>
                                        <td><input class="form-check-input" type="checkbox" value="" checked={assemblyCostsToAdd.indexOf(q.id) >= 0} onChange={(e) => {
                                            if (e.target.checked) {
                                                const costs = [...new Set(assemblyCostsToAdd)];
                                                costs.push(q.id);
                                                setAssemblyCostsToAdd(costs);
                                            } else {
                                                const costs = [...new Set(assemblyCostsToAdd.filter(r => r !== q.id))];
                                                setAssemblyCostsToAdd(costs);
                                            }
                                        }} /></td>
                                        <td>{q.costCode}</td>
                                        <td>{q.description}</td>
                                        <td>{q.unit.name}</td>
                                        <td>{utils.getDollarString(q.cost)}</td>
                                    </tr>)}
                                </tbody>
                            </Table>
                        </Modal.Body>
                        <Modal.Footer>
                            <Container>
                                <Row>
                                    <Col className="col-6">
                                        <Button disabled={assemblyCostsToAdd.length === 0} onClick={() => {
                                            setShowAssemblyCostsModal(false);
                                            handleAddAssemblyCosts();
                                        }}>Add Selected Assembly Costs</Button>
                                    </Col>
                                    <Col className="col-6 text-end">
                                        <Button variant="secondary" onClick={() => { setShowAssemblyCostsModal(false) }}>Cancel</Button>
                                    </Col>
                                </Row>
                            </Container>
                        </Modal.Footer>
                    </Modal>
                    <Modal size="xl" fullscreen='xl-down' show={showUnitCostsModal} onHide={() => { setShowUnitCostsModal(false) }} backdrop="static">
                        <Modal.Header closeButton>
                            <Modal.Title>Add Unit Costs</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {requirement.requirementTemplateID !== null &&
                                <Row className="mb-3">
                                    <Col className="col-4">
                                        <Form.Select value={unitCostsFilter.costType} onChange={async (e) => {
                                            await updateUnitCostsFilter(e.target.value, unitCostsFilter.description)
                                        }}>
                                            <option key="showAll2" value="showAll">Show all costs</option>
                                            <option key="showTemplate2" value="showTemplate">Show costs from template</option>
                                        </Form.Select>
                                    </Col>
                                </Row>
                            }

                            <Table striped>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Cost Code</th>
                                        <th>Description</th>
                                        <th>Unit</th>
                                        <th>Unit Cost</th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th></th>
                                        <th>
                                            <input type="search" class="form-control" placeholder="Search" onChange={async (e) => {
                                                const searchString = e.target.value;
                                                await updateUnitCostsFilter(unitCostsFilter.costType, searchString);
                                            }} value={unitCostsFilter.description} />
                                        </th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {unitCostsInView.map(q => <tr key={`unitCosts_row_${q.costCode}` }>
                                        <td><input class="form-check-input" type="checkbox" value="" checked={unitCostsToAdd.indexOf(q.id) >= 0} onChange={(e) => {
                                            if (e.target.checked) {
                                                const costs = [...new Set(unitCostsToAdd)];
                                                costs.push(q.id);
                                                setUnitCostsToAdd(costs);
                                            } else {
                                                const costs = [...new Set(unitCostsToAdd.filter(r => r !== q.id))];
                                                setUnitCostsToAdd(costs);
                                            }
                                        }} /></td>
                                        <td>{q.costCode}</td>
                                        <td>{q.description}</td>
                                        <td>{q.unit.name}</td>
                                        <td>{utils.getDollarString(q.cost)}</td>
                                    </tr>)}
                                </tbody>
                            </Table>
                        </Modal.Body>
                        <Modal.Footer>
                            <Container>
                                <Row>
                                    <Col className="col-6">
                                        <Button disabled={unitCostsToAdd.length === 0} onClick={() => {
                                            setShowUnitCostsModal(false);
                                            handleAddUnitCosts();
                                        }}>Add Selected Unit Costs</Button>
                                    </Col>
                                    <Col className="col-6 text-end">
                                        <Button variant="secondary" onClick={() => { setShowUnitCostsModal(false) }}>Cancel</Button>
                                    </Col>
                                </Row>
                            </Container>
                        </Modal.Footer>
                    </Modal>
                    <Modal size="sm" show={showDeleteCostModal} onHide={() => { setShowDeleteCostModal(false) }} backdrop="static">
                        <Modal.Header closeButton>
                            <Modal.Title>Delete Cost</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Are you sure you want to remove this cost?</Modal.Body>
                        <Modal.Footer>
                            <Container>
                                <Row>
                                    <Col className="col-6">
                                        <Button onClick={() => {
                                            setShowDeleteCostModal(false);
                                            handleDeleteCost();
                                        }}>Yes</Button>
                                    </Col>
                                    <Col className="col-6 text-end">
                                        <Button variant="secondary" onClick={() => { setShowDeleteCostModal(false) }}>No</Button>
                                    </Col>
                                </Row>
                            </Container>
                        </Modal.Footer>
                    </Modal>
                    <Modal size="md" fullscreen='md-down' show={showOverrideCostModal} onHide={() => { setShowOverrideCostModal(false) }} backdrop="static">
                        <Form noValidate validated={overrideCostValidated} onSubmit={handleOverrideCost}>
                            <Modal.Header closeButton>
                                <Modal.Title>Override Cost</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Row className="align-items-center">
                                    <Col className="fw-bold col-4 p-2">Cost</Col>
                                    <Col className="col-8 p-2">
                                        <Form.Select required defaultValue={overrideCostSourceID} onChange={(e) => {
                                            setOverrideCostSourceID(e.target.value);
                                        }} >
                                            {requirementCostSources.map(q => <option key={`costSource_${q.id}` } value={q.id}>{q.name}</option>)}
                                        </Form.Select>
                                    </Col>

                                    <Col className="fw-bold col-4 p-2">Total Cost</Col>
                                    <Col className="col-8 p-2">
                                        <InputGroup>
                                            <InputGroup.Text>$</InputGroup.Text>
                                            <NumericFormat className="form-control" disabled={overrideCostSourceID === 'CALC'} decimalScale={2} fixedDecimalScale={true} value={overrideCost} thousandSeparator="," required onValueChange={async (values, sourceInfo) => {
                                                await setOverrideCost(values.floatValue);
                                            }} />
                                        </InputGroup>
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <Container>
                                    <Row>
                                        <Col className="col-6">
                                            <Button type="submit">Save Changes</Button>
                                        </Col>
                                        <Col className="col-6 text-end">
                                            <Button variant="secondary" onClick={() => { setShowOverrideCostModal(false) }}>Cancel</Button>
                                        </Col>
                                    </Row>
                                </Container>
                            </Modal.Footer>
                        </Form>
                    </Modal>
                    <Modal size="md" fullscreen='md-down' show={showSaveTemplateModal} onHide={() => { setShowSaveTemplateModal(false) }} backdrop="static">
                        <Form noValidate validated={saveTemplateValidated} onSubmit={handleSaveTemplate}>
                            <Modal.Header closeButton>
                                <Modal.Title>Save as Template</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {editTemplate.overwrite &&
                                    <Row className="align-items-center">
                                        <Col className="fw-bold col-4 p-2">Save Mode</Col>
                                        <Col className="col-8 p-2">
                                            <Form.Select required value={editTemplate.saveMode} onChange={(e) => {
                                                const item = utils.getCopy(editTemplate)
                                                item.saveMode = e.target.value;
                                                setEditTemplate(item);
                                            }} >
                                                <option key="saveMode_Overwrite" value='overwrite'>Overwrite template</option>
                                                <option key="saveMode_New" value='new'>Save as new template</option>
                                            </Form.Select>
                                        </Col>
                                    </Row>
                                }
                                <Row className="align-items-center">
                                    <Col className="fw-bold col-4 p-2">Template Name</Col>
                                    <Col className="col-8 p-2">
                                        {
                                            editTemplate.saveMode === 'new' &&
                                            <Form.Control type="text" defaultValue={editTemplate.name} required maxLength={30} onChange={(e) => {
                                                const item = utils.getCopy(editTemplate);
                                                item.name = e.target.value;
                                                setEditTemplate(item);
                                            }} />
                                        }
                                        {
                                            editTemplate.saveMode === 'overwrite' &&
                                            <div>{editTemplate.name}</div>
                                        }
                                    </Col>
                                </Row>
                                <Row>
                                    <Form.Label style={{ color: 'red' }}>{editTemplate.errorMessage}</Form.Label>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <Container>
                                    <Row>
                                        <Col className="col-6">
                                            <Button type="submit">Save Changes</Button>
                                        </Col>
                                        <Col className="col-6 text-end">
                                            <Button variant="secondary" onClick={() => { setShowSaveTemplateModal(false) }}>Cancel</Button>
                                        </Col>
                                    </Row>
                                </Container>
                            </Modal.Footer>
                        </Form>
                    </Modal>
                    <Modal size="md" fullscreen='md-down' show={showUpdateCostModal} onHide={() => { setShowUpdateCostModal(false) }} backdrop="static">
                        <Form noValidate validated={updateCostValidated} onSubmit={handleUpdateCost}>
                            <Modal.Header closeButton>
                                <Modal.Title>Update Cost</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Row className="align-items-center">
                                    <Col className="fw-bold col-4 p-2">Cost</Col>
                                    <Col className="col-8 p-2">
                                        <Form.Select required defaultValue={updateCost.unitID} onChange={(e) => {
                                            const item = utils.getCopy(updateCost)
                                            item.unitID = e.target.value;
                                            setUpdateCost(item);
                                        }} >
                                            {units.map(q => <option key={`updateCostUnit_${q.id}` } value={q.id}>{q.name}</option>)}
                                        </Form.Select>
                                    </Col>

                                    <Col className="fw-bold col-4 p-2">Quantity</Col>
                                    <Col className="col-8 p-2">
                                        <NumericFormat className="form-control" decimalScale={0} fixedDecimalScale={true} value={updateCost.quantity} thousandSeparator="," required onValueChange={async (values, sourceInfo) => {
                                            const item = utils.getCopy(updateCost)
                                            item.quantity = values.floatValue;
                                            await setUpdateCost(item);
                                        }} />
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <Container>
                                    <Row>
                                        <Col className="col-6">
                                            <Button type="submit">Save Changes</Button>
                                        </Col>
                                        <Col className="col-6 text-end">
                                            <Button variant="secondary" onClick={() => { setShowUpdateCostModal(false) }}>Cancel</Button>
                                        </Col>
                                    </Row>
                                </Container>
                            </Modal.Footer>
                        </Form>
                    </Modal>
                </div>
            </React.Fragment>
    );
}

export default Requirement;
