import React, { Component } from 'react';
import authService from './api-authorization/AuthorizeService';
import Button from 'react-bootstrap/Button';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './ItemsList.css';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import utils from '../utils';

export class GanttList extends Component {
    constructor(props) {
        super(props);

        console.log(props);

        this.state = {
            items: [],
            itemsForDisplay: [],
            secondaryItems: props.secondaryItems,
            loading: true,
            sortColumn: props.uniqueIdColumn,
            sortDirection: 'asc',
            pages: 1,
            pageNumber: 1,
            pageSize: 10,
            selectValues: props.columns.filter(q => q.filter === 'select').reduce((a, q) => (a[q.id] = '-1', a), {}),
            selectIds: props.columns.filter(q => q.filter === 'select').reduce((a, q) => (a[q.id] = q.selectId, a), {}),
            selectOptions: props.columns.filter(q => q.filter === 'select').reduce((a, q) => (a[q.id] = [], a), {}),
            searchValues: props.columns.filter(q => q.filter === 'search').reduce((a, q) => (a[q.id] = '', a), {}),
            dateRangeValues: props.columns.filter(q => q.filter === 'dateRange').reduce((a, q) => (a[q.id] = { from: '', to: '' }, a), {}),
            yearRangeValues: props.columns.filter(q => q.filter === 'yearRange').reduce((a, q) => (a[q.id] = { from: q.defaultFilter, to: q.defaultFilter }, a), {}),
            shouldUpdate: props.shouldUpdate,
        };
    }

    componentDidMount() {
        this.getItems();
    }

    componentDidUpdate() {
    }

    shouldComponentUpdate() {
        if (this.state.loading || this.state.shouldUpdate) {
            return true;
        }

        return false;
    }

    render() {
        const sort = (column) => {
            let sortColumn = column;
            let sortDirection = 'asc';
            const icon = document.getElementById(`column_${column}`);
            if (icon.classList.contains('bi-chevron-up')) {
                sortDirection = 'desc';
            }
            const tempState = Object.assign({}, this.state);
            tempState.sortColumn = sortColumn;
            tempState.sortDirection = sortDirection;
            this.populateItemsForDisplay(tempState);
        };

        const goToPage = (pageNumber) => {
            const tempState = Object.assign({}, this.state);
            tempState.pageNumber = pageNumber;
            this.populateItemsForDisplay(tempState);
        };

        const updatePageSize = (event) => {
            const tempState = Object.assign({}, this.state);
            tempState.pageSize = event.target.value;
            tempState.pageNumber = 1;
            this.populateItemsForDisplay(tempState);
        };

        const search = (event) => {
            if (event.key === 'Enter') {
                const tempState = Object.assign({}, this.state);
                const key = event.target.id.substring(7);
                tempState.searchValues[key] = event.target.value;
                this.populateItemsForDisplay(tempState);
            }
        }

        const clearSearch = (event) => {
            if (event.target.value === '' && this.state.facilityName !== '') {
                const tempState = Object.assign({}, this.state);
                const key = event.target.id.substring(7);
                tempState.searchValues[key] = '';
                this.populateItemsForDisplay(tempState);
            }
        }

        const updateSelect = (event) => {
            const tempState = Object.assign({}, this.state);
            const key = event.target.id.substring(7);
            tempState.selectValues[key] = event.target.value;
            tempState.pageNumber = 1;
            this.populateItemsForDisplay(tempState);
        };

        const updateDateRange = (event) => {
            const tempState = Object.assign({}, this.state);
            if (event.target.id.startsWith('dateRange_from')) {
                // from range was updated
                const key = event.target.id.substring(15);
                tempState.dateRangeValues[key]['from'] = event.target.value;
                const toDateElement = document.getElementById(`dateRange_to_${key}`);
                toDateElement.min = event.target.value;
            } else {
                // to range was updated
                const key = event.target.id.substring(13);
                tempState.dateRangeValues[key]['to'] = event.target.value;
                const fromDateElement = document.getElementById(`dateRange_from_${key}`);
                fromDateElement.max = event.target.value;
            }
            tempState.pageNumber = 1;
            this.populateItemsForDisplay(tempState);
        }

        const updateYearRange = (event) => {
            const tempState = Object.assign({}, this.state);
            const value = event.target.value === '-1' ? '' : event.target.value;
            if (event.target.id.startsWith('yearRange_from')) {
                // from range was updated
                const key = event.target.id.substring(15);
                tempState.yearRangeValues[key]['from'] = value;
            } else {
                // to range was updated
                const key = event.target.id.substring(13);
                tempState.yearRangeValues[key]['to'] = value;
            }
            tempState.pageNumber = 1;
            this.populateItemsForDisplay(tempState);
        }

        const getSum = (values, labelFormat) => {
            let sum = 0;
            if (values.length > 0) {
                sum = values.reduce(function (a, b) {
                    return a + b;
                });
            }
            return getLabel(sum, labelFormat);
        };

        const getLabel = (value, labelFormat) => {
            if (labelFormat === 'dollar') {
                return utils.getDollarString(value);
            } else if (labelFormat === 'date') {
                return value.toLocaleDateString();
            } else if (labelFormat === 'html') {
                // We are directly controlling what is rendered
                return (<div style={{ width: 'max-content' }} dangerouslySetInnerHTML={{ __html: value }}></div>)
            }
            return value;
        };

        const calcSecondaryValue = (item, index, column) => {

            let secondaryValue = 0;

            if (index === 0) {
                secondaryValue = item[column.id];
                column.funding = secondaryValue;
            } else if (index === 1) {
                let onHoldTotal = 0;
                let draftTotal = 0;
                let submittedTotal = 0;

                this.state.itemsForDisplay.forEach(mainRow => {
                    let lowerYear = Number(column.name);

                    if (mainRow.startYear <= lowerYear) {
                        let value = mainRow.value;
                        let startQuarter = Number(mainRow.quarter.replace("Q", ""));
                        let overallQuarters = Math.ceil(mainRow.duration / 3);
                        let remainingQuarters = overallQuarters;
                        let quarterIndex = startQuarter - 1;
                        let mainRowYear = mainRow.startYear;

                        while (lowerYear >= mainRowYear) {
                            let quarters = 0;

                            while (quarterIndex < 4) {
                                if (remainingQuarters > 0 && lowerYear === mainRowYear) {
                                    quarters += 1;
                                }

                                remainingQuarters--;
                                quarterIndex++;
                            }

                            if (lowerYear === mainRowYear) {
                                let addValue = value / overallQuarters * quarters;
                                secondaryValue += addValue;

                                if (mainRow.status === 'Draft') {
                                    draftTotal += addValue;
                                } else if (mainRow.status === 'On-Hold') {
                                    onHoldTotal += addValue;
                                } else if (mainRow.status === 'Submitted') {
                                    submittedTotal += addValue;
                                }
                            }

                            quarterIndex = 0;
                            mainRowYear++;
                        }
                    }
                });

                item.totalProjects = secondaryValue;
                column.totalProjects = secondaryValue;
                column.onHoldTotal = onHoldTotal;
                column.draftTotal = draftTotal;
                column.submittedTotal = submittedTotal
                secondaryValue = column.submittedTotal;
            } else if (index === 2) {
                secondaryValue = column.funding - column.submittedTotal;
                item.budget = secondaryValue;
            } else if (index === 3) {
                secondaryValue = column.onHoldTotal;
            } else if (index === 4) {
                secondaryValue = column.draftTotal;
            }

            return utils.getDollarString(secondaryValue);
        }

        let minPage = this.state.pageNumber === 1 ? 1 : this.state.pageNumber - 1;
        const maxPage = this.state.pages < minPage + 2 ? this.state.pages : minPage + 2;
        if (maxPage === this.state.pageNumber && maxPage > 2) {
            minPage = maxPage - 2;
        }
        let pageNumbers = [];
        for (let i = minPage - 1; i < maxPage; i++) {
            pageNumbers.push(i + 1);
        }

        const currentYear = new Date().getFullYear();
        const years = [];
        for (let y = currentYear; y <= currentYear + 30; y++) {
            years.push(y);
        }

        return (
            <div class="card">
                {this.props.showHeader &&
                    <div class="card-header d-md-flex justify-content-md-end">
                        {(this.props.addItemMethod === undefined || this.props.addItemMethod === null) ?
                            <a href={this.props.addItemUrl} class="btn btn-primary">Add {this.props.itemName}</a>
                            :
                            <Button type="button" variant="primary" onClick={this.props.addItemMethod}>Add {this.props.itemName}</Button>
                        }
                    </div>
                }
                <div class="card-body">
                    <Table striped responsive aria-labelledby="tableLabel" style={{ verticalAlign: 'baseline' }}>
                        <thead>
                            <tr>
                                {this.props.columns.map(c =>
                                    <th className="sortColumnHeader" style={{ minWidth: c.minWidth }} onClick={() => { if (c.sortable) { sort(c.id) } }}>
                                        {c.name} <i id={`column_${c.id}`} className={`${this.state.sortColumn === c.id ? (this.state.sortDirection === 'asc' ? 'bi bi-chevron-up' : 'bi bi-chevron-down') : ''}`}></i>
                                    </th>
                                )}
                            </tr>
                            <tr>
                                {this.props.columns.map(c =>
                                    <th>
                                        {c.filter === 'search' && <input id={`search_${c.id}`} type="search" class="form-control" placeholder="Search" onKeyDown={search} onChange={clearSearch} defaultValue={this.state.searchValues[c.id]} />}
                                        {c.filter === 'select' && <select id={`select_${c.id}`} class="form-select" aria-label="" value={this.state.selectValues[c.id]} onChange={updateSelect}>
                                            <option value="-1">All</option>
                                            {this.state.selectOptions[c.id].map(o => <option value={o.id}>{o.label}</option>)}
                                        </select>
                                        }
                                        {c.filter === 'dateRange' &&
                                            <div class="container-sm">
                                                <div class="row align-items-start">
                                                    <div class="col-5">
                                                        <input id={`dateRange_from_${c.id}`} type="date" class="form-control" onChange={updateDateRange}></input>
                                                    </div>
                                                    <div class="col-5">
                                                        <input id={`dateRange_to_${c.id}`} type="date" class="form-control" onChange={updateDateRange}></input>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {c.filter === 'yearRange' &&
                                            <div class="container-sm">
                                                <div class="row align-items-start">
                                                    <div class="col-5">
                                                        <select id={`yearRange_from_${c.id}`} class="form-select" onChange={updateYearRange} value={this.state.yearRangeValues[c.id]['from']}>
                                                            <option value="">From</option>
                                                            <option value="-1">All</option>
                                                            {years.map(year => <option value={year} disabled={this.state.yearRangeValues[c.id]['to'] !== '' && year > this.state.yearRangeValues[c.id]['to']}>{year}</option>)}
                                                        </select>
                                                    </div>
                                                    <div class="col-5">
                                                        <select id={`yearRange_to_${c.id}`} class="form-select" onChange={updateYearRange} value={this.state.yearRangeValues[c.id]['to']}>
                                                            <option value="">To</option>
                                                            <option value="-1">All</option>
                                                            {years.map(year => <option value={year} disabled={this.state.yearRangeValues[c.id]['from'] !== '' && year < this.state.yearRangeValues[c.id]['from']}>{year}</option>)}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </th>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.itemsForDisplay.map(item =>
                                <tr key={item[this.props.uniqueIdColumn]}>
                                    {this.props.columns.map(column =>
                                        <td>
                                            {column.labelFormat === 'linkToItem' && <a href={`${this.props.itemUrlBase}/${item.id}`}>{item[column.id]}</a>}
                                            {column.labelFormat === 'clickToItem' && <a class="link" style={{ cursor: "pointer" }} onClick={(e) => this.props.editItemMethod(`${item.id}`, e)}>{item[column.id]}</a>}
                                            {/*to do - linkToProject*/}
                                            {column.labelFormat === 'textBox' && <Form.Control type="Number" defaultValue={item[column.id]} min={column.min} max={column.max}
                                                onBlur={({ target: { value } }) => {
                                                    if (value === undefined || value === null || value === '') {
                                                        value = column.min;
                                                    } else if (value < column.min) {
                                                        value = column.min;
                                                    } else if (value > column.max) {
                                                        value = column.max;
                                                    }

                                                    item[column.id] = value;

                                                    if (this.props.onChangeMethod !== undefined && this.props.onChangeMethod !== null) {
                                                        this.props.onChangeMethod(item, column.id, value);

                                                        // Repopulate and update
                                                        const tempState = Object.assign({}, this.state);
                                                        this.populateItemsForDisplay(tempState);
                                                        this.forceUpdate();
                                                    }
                                                }} />
                                            }
                                            {column.labelFormat === 'select' &&
                                                <Form.Select defaultValue={item[column.id]}
                                                    onChange={({ target: { value } }) => {
                                                        if (this.props.onChangeMethod !== undefined && this.props.onChangeMethod !== null) {
                                                            this.props.onChangeMethod(item, column.id, value);

                                                            // Repopulate and update
                                                            const tempState = Object.assign({}, this.state);
                                                            this.populateItemsForDisplay(tempState);
                                                            this.forceUpdate();
                                                        }
                                                    }}>
                                                    {column.selections.map(selection => <option value={selection}>{selection}</option>)}
                                                </Form.Select>
                                            }
                                            {column.labelFormat !== 'linkToItem' && column.labelFormat !== 'clickToItem' && column.labelFormat !== 'textBox' && column.labelFormat !== 'select'&&
                                                getLabel(item[column.id], column.labelFormat)}
                                        </td>
                                    )}
                                </tr>
                            )}
                        </tbody>
                        <tfoot>
                            {this.props.columns.find(q => q.aggregate) !== undefined &&
                                <tr> {
                                    this.props.columns.map(c =>
                                        <th>
                                            {c.id === this.props.uniqueIdColumn && 'Total:'}
                                            {c.aggregate && getSum(this.state.itemsForDisplay.map(q => q[c.id]), c.labelFormat)}
                                        </th>
                                    )
                                }
                                </tr>
                            }
                            {this.state.secondaryItems.map((item, index) =>
                                <tr key={index}>
                                    {this.props.columns.map(c =>
                                        c.id.startsWith("year") ?
                                        <td>{calcSecondaryValue(item, index, c)}</td>
                                        :
                                        <th>{item[c.id]}</th>
                                    )}
                                </tr>
                            )}
                        </tfoot>
                    </Table>
                </div>
                {this.props.showFooter &&
                    <div class="card-footer">
                        <div class="row justify-content-center justify-content-sm-between align-items-sm-center">
                            <div class="col-sm mb-2 mb-sm-0">
                                <div class="d-flex justify-content-center justify-content-sm-start align-items-center">
                                    <select class="js-select form-select form-select-borderless w-auto" aria-label="" onChange={updatePageSize} value={this.state.pageSize}>
                                        <option value="10">10</option>
                                        <option value="20">20</option>
                                        <option value="30">30</option>
                                        <option value="50">50</option>
                                    </select>
                                    <span>&nbsp;Items per page</span>
                                </div>
                            </div>
                            <div class="col-sm-auto">
                                <nav aria-label="Page navigation example">
                                    <ul class="pagination">
                                        <li className={`page-item ${this.state.pageNumber === 1 ? 'disabled' : ''}`} >
                                            <a class="page-link" aria-label="Previous" onClick={() => { goToPage(this.state.pageNumber - 1) }}>
                                                <span aria-hidden="true">&laquo;</span>
                                            </a>
                                        </li>
                                        {pageNumbers.map(pageNumber =>
                                            <li className={`page-item ${pageNumber === this.state.pageNumber ? 'active' : ''}`}>
                                                <a class="page-link" onClick={() => { goToPage(pageNumber) }}>{pageNumber}</a>
                                            </li>
                                        )}
                                        <li className={`page-item ${this.state.pageNumber === this.state.pages ? 'disabled' : ''}`}>
                                            <a class="page-link" aria-label="Next" onClick={() => { goToPage(this.state.pageNumber + 1) }}>
                                                <span aria-hidden="true">&raquo;</span>
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }

    async getItems() {
        this.setState({ items: [], loading: true });
        const token = await authService.getAccessToken();
        const response = await fetch(this.props.getItemsUrl, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const items = await response.json();
        const tempState = Object.assign({}, this.state);
        tempState.items = items.map(this.props.mapFunction);
        tempState.loading = false;
        tempState.pages = Math.ceil(items.length / tempState.pageSize);
        this.populateItemsForDisplay(tempState);
    }

    populateItemsForDisplay(tempState) {
        let filteredItems = [...tempState.items];
        for (const key in tempState.searchValues) {
            filteredItems = filteredItems.filter(q => tempState.searchValues[key] === '' || q[key].toUpperCase().includes(tempState.searchValues[key].toUpperCase()));
        }
        for (const key in tempState.selectValues) {
            filteredItems = filteredItems.filter(q => tempState.selectValues[key] === '-1' || q[tempState.selectIds[key]].toString() === tempState.selectValues[key]);
        }
        for (const key in tempState.dateRangeValues) {
            filteredItems = filteredItems.filter(q => tempState.dateRangeValues[key]['from'] === '' || q[key].getTime() >= new Date(`${tempState.dateRangeValues[key]['from']}T00:00:00`).getTime());
            filteredItems = filteredItems.filter(q => tempState.dateRangeValues[key]['to'] === '' || q[key].getTime() <= new Date(`${tempState.dateRangeValues[key]['to']}T00:00:00`).getTime());
        }
        for (const key in tempState.yearRangeValues) {
            filteredItems = filteredItems.filter(q => tempState.yearRangeValues[key]['from'] === '' || q[key] >= tempState.yearRangeValues[key]['from']);
            filteredItems = filteredItems.filter(q => tempState.yearRangeValues[key]['to'] === '' || q[key] <= tempState.yearRangeValues[key]['to']);
        }
        const itemsForDisplay = filteredItems.sort((a, b) => {
            let aComp = a[tempState.sortColumn], bComp = b[tempState.sortColumn];
            if (typeof (aComp) === 'string') {
                aComp = aComp.toUpperCase();
                bComp = bComp.toUpperCase();
            }
            if (aComp instanceof Date) {
                aComp = aComp.getTime();
                bComp = bComp.getTime();
            }
            if (aComp < bComp) {
                return tempState.sortDirection === 'asc' ? -1 : 1;
            }
            if (aComp > bComp) {
                return tempState.sortDirection === 'asc' ? 1 : -1;
            }
            return 0;
        }).slice(tempState.pageSize * (tempState.pageNumber - 1), tempState.pageSize * tempState.pageNumber);
        tempState.itemsForDisplay = itemsForDisplay;
        for (const key in tempState.selectOptions) {
            tempState.selectOptions[key] = this.getDistinctItems(filteredItems.map((q) => { return { id: q[tempState.selectIds[key]], label: q[key] } }));
        }
        tempState.pages = Math.ceil(filteredItems.length / tempState.pageSize);

        this.setState(tempState);
    }

    getDistinctItems(items) {
        const ids = [];
        return items.filter((q) => {
            if (ids.indexOf(q.id) >= 0) return false;
            ids.push(q.id);
            return true;
        }).sort((a, b) => {
            if (a.label.toUpperCase() < b.label.toUpperCase()) return -1;
            if (a.label.toUpperCase() > b.label.toUpperCase()) return 1;
            return 0;
        });
    }

}