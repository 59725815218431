import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import authService from '../api-authorization/AuthorizeService'
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Card from 'react-bootstrap/Card';
import Tab from 'react-bootstrap/Tab';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import { InfoCircle, Coin, GraphUp, Clipboard2Check } from 'react-bootstrap-icons';
import AssetRequirements from './AssetRequirements';
import Costs from './Costs';
import ProjectMetrics from './ProjectMetrics';
import utils from '../../utils';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import { NumericFormat } from 'react-number-format';
import InputGroup from 'react-bootstrap/InputGroup';

const Project = () => {
    const _asset = "ASSET";
    const _system = "SYSTEM";
    const _single = "SINGLE";
    const _multi = "MULTIPLE";

    const { id } = useParams();

    const navigate = useNavigate();
    const [queryParameters] = useSearchParams();

    const [addMode, setAddMode] = useState(id.toUpperCase() === 'ADD');
    const [assetMode] = useState(addMode && queryParameters.get("assetid") !== null);
    const [asset, setAsset] = useState(null)
    const [systemMode] = useState(addMode && queryParameters.get("systemtypeid") !== null);

    const [loading, setLoading] = useState(true);
    const [validated, setValidated] = useState(false);

    const [project, setProject] = useState(null);
    const [assets, setAssets] = useState([]);
    const [systems, setSystems] = useState([]);
    const [tempAsset, setTempAsset] = useState(''); // single asset for ASSET project type
    const [showAssetModal, setShowAssetModal] = useState(''); 
    const [assetModalMode, setAssetModalMode] = useState(_single);
    const [facilityNameFilter, setFacilityNameFilter] = useState('');
    const [assetsInView, setAssetsInView] = useState([]);
    const [assetsToAdd, setAssetsToAdd] = useState([]);
    const [editMode, setEditMode] = useState(false);
    const [projectStatuses, setProjectStatuses] = useState([]);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [projectAssetRequirements, setProjectAssetRequirements] = useState([]);

    const [costs, setCosts] = useState([]);

    useEffect(() => {
        if (loading) {
            async function populateItem() {
                if (addMode) {
                    await setProject({
                        type: _asset,
                        systemTypeID: '',
                        projectAssets: [],
                        title: '',
                        startYear: new Date().getFullYear(),
                        quarter: '',
                        duration: '',
                        description: '',
                        narrative: ''
                    });
                    setEditMode(true);
                } else {
                    const token = await authService.getAccessToken();
                    const response = await fetch('api/projects/' + id, {
                        headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                    });
                    const item = await response.json();
                    if (item.status === 404) {
                        const path = '/projects';
                        navigate(path);
                    }
                    await setProject(item);
                    await setEditMode(false);
                    await refreshCosts();
                    await refreshProjectAssetRequirements(item);
                    await setAssetModalMode(item.type === _asset ? _single : _multi);
                }
                await populateArrays();
                await setLoading(false);
            }

            async function populateArrays() {
                const token = await authService.getAccessToken();
                const response = await fetch('api/projects/items', {
                    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                });
                const item = await response.json();
                await setAssets(item.assets);
                if (assetMode) {
                    setAsset(item.assets.find(q => q.id === queryParameters.get("assetid")))
                }

                await setSystems(item.systemTypes);
                await setProjectStatuses(item.projectStatuses);
            }

            populateItem();
        }
    }, [loading, id]);

    const handleSave = async (event) => {
        const form = event.currentTarget;

        event.preventDefault();

        if (form.checkValidity() === false) {
            event.stopPropagation();
            await setValidated(true);
            return;
        }        

        const token = await authService.getAccessToken();

        const projectCopy = utils.getCopy(project);
        const projectAssets = [...projectCopy.projectAssets];
        if (projectCopy.systemTypeID === '') delete projectCopy.systemTypeID;

        if (addMode) {            
            const response = await fetch('api/projects', {
                method: 'POST',
                headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
                body: JSON.stringify({ project: projectCopy, projectAssets: projectAssets }),
            });            
            const item = await response.json();

            const response2 = await fetch('api/projects/' + item.id, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });
            const item2 = await response2.json();
            await setProject(item2);

            const path = `/projects/${item2.id}`;
            navigate(path);
            await setAddMode(false);
            await setEditMode(false);
        } else {
            await fetch(`api/projects/${project.id}`, {
                method: 'PUT',
                headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
                body: JSON.stringify(projectCopy),
            });
            setEditMode(false);
        }       
    };

    const handleDelete = async () => {
        const token = await authService.getAccessToken();
        await fetch(`api/projects/${project.id}`, {
            method: 'DELETE',
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        });

        const path = '/projects';
        navigate(path);
    };

    const refreshCosts = async () => {
        const token = await authService.getAccessToken();
        let response = await fetch(`api/projects/getCosts?projectID=${id}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const _costs = await response.json();
        await setCosts(_costs);
    };

    const refreshProjectAssetRequirements = async (_project) => {
        const token = await authService.getAccessToken();
        const fetchUrl = _project.type === _asset ? `api/projects/projectAssetRequirements?projectID=${_project.id}&assetID=${_project.projectAssets[0].assetID}` : `api/projects/projectAssetSystemRequirements?projectID=${_project.id}&systemTypeID=${_project.systemTypeID}`;
        const response = await fetch(fetchUrl, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const items = await response.json();
        await setProjectAssetRequirements(items);
    };

    return loading ? (
        <p><em>Loading...</em></p>
    ) : (
            <React.Fragment>
                <div>
                    <Card className="mb-2">
                        <Card.Body>
                            <Card.Title>
                                <Breadcrumb>
                                    {(!assetMode && !systemMode) && <Breadcrumb.Item href="/projects/">Projects</Breadcrumb.Item>}
                                    {assetMode && <Breadcrumb.Item href="/assets/">Assets</Breadcrumb.Item>}
                                    {assetMode && <Breadcrumb.Item href={`/assets/${asset.id}`}>{asset.facilityName}</Breadcrumb.Item>}
                                    <Breadcrumb.Item active>{`${addMode ? 'Add Project' : project.title}`}</Breadcrumb.Item>
                                </Breadcrumb>
                            </Card.Title>
                            {!addMode &&
                                <Row>
                                    <Col xs={6}>
                                        <Table borderless>
                                            <tbody>
                                                <tr>
                                                    <th>Project Code</th>
                                                    <td>{project.code}</td>
                                                    <th>Project Start</th>
                                                    <td>{`${project.quarter} ${project.startYear}`}</td>
                                                </tr>
                                                <tr>
                                                    <th>Status</th>
                                                    <td>{project.projectStatus.name}</td>
                                                    <th>Duration</th>
                                                    <td>{`${project.duration} months`}</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                            }
                        </Card.Body>
                    </Card>
                    <Tab.Container defaultActiveKey="details">
                        <Row>
                            <Col sm={3}>
                                <Card>
                                    <Card.Body>
                                        <Nav variant="pills" className="flex-column">
                                            <Nav.Item>
                                                <Nav.Link eventKey="details"><InfoCircle /> Details</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="costs" disabled={addMode}><Coin /> Costs</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="projectMetrics" disabled={addMode}><GraphUp /> Project Metrics</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="assetRequirements" disabled={addMode}><Clipboard2Check /> Asset Requirements</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col sm={9}>
                                <Card>
                                    <Card.Body>
                                        <Tab.Content>
                                            <Tab.Pane eventKey="details">                                            
                                                <Form noValidate validated={validated} onSubmit={handleSave}>               
                                                    <Row className="align-items-center">
                                                        <Col className="fw-bold col-2 p-2">Project Type</Col>
                                                        <Col className="col-4 p-2">
                                                            <Form.Select required defaultValue={project.type} disabled={!addMode} onChange={async (e) => {
                                                                const item = utils.getCopy(project);
                                                                item.type = e.target.value;
                                                                item.projectAssets = [];
                                                                item.systemTypeID = '';
                                                                setProject(item);
                                                                setAssetModalMode(e.target.value === _asset ? _single : _multi);
                                                            }} >
                                                                <option value={_asset}>Asset</option>
                                                                <option value={_system}>System</option>
                                                            </Form.Select>
                                                        </Col>
                                                    </Row>
                                                    {project.type === _system &&
                                                        <Row className="align-items-center">
                                                            <Col className="fw-bold col-2 p-2">System</Col>
                                                            <Col className="col-4 p-2">
                                                                <Form.Select required defaultValue={project.systemTypeID} disabled={!addMode} onChange={async (e) => {
                                                                    const item = utils.getCopy(project);
                                                                    item.systemTypeID = parseInt(e.target.value);
                                                                    item.projectAssets = [];
                                                                    setProject(item);
                                                                }} >
                                                                    <option value='' disabled>Select a System...</option>
                                                                    {systems.map(q => <option value={q.id}>{q.name}</option>) }
                                                                </Form.Select>
                                                            </Col>
                                                        </Row>
                                                    }
                                                    <Card className="mt-2 mb-2">
                                                        <Card.Header>
                                                            <Row className="align-items-center">
                                                                <Col className="col-10">
                                                                    {assetModalMode === _single ? 'Asset' : 'Assets'}
                                                                </Col>
                                                                {addMode &&
                                                                    <Col className="col-2 text-end">
                                                                        <a class="btn btn-primary btn-sm" onClick={() => {
                                                                            setFacilityNameFilter('');
                                                                            setAssetsInView([...assets]);
                                                                            setTempAsset(assetModalMode === _single && project.projectAssets.length > 0 ? project.projectAssets[0].assetID : '');
                                                                            setAssetsToAdd(assetModalMode === _single ? [] : project.projectAssets.map(q => q.assetID));
                                                                            setShowAssetModal(true);
                                                                        }}>{assetModalMode === _single ? 'Select Asset' : 'Select Assets'}</a>
                                                                    </Col>
                                                                }
                                                            </Row>
                                                        </Card.Header>
                                                        <Card.Body>
                                                            {assetModalMode === _single ?
                                                                <Row className="align-items-center">
                                                                    <Col className="fw-bold col-2 p-2">Name</Col>
                                                                    <Col className="col-10">
                                                                        {assets.filter(q => project.projectAssets.map(q => q.assetID).indexOf(q.id) >= 0).map(q => q.name) }
                                                                    </Col>
                                                                </Row>
                                                                :
                                                                <Row className="align-items-center">
                                                                    {assets.filter(q => project.projectAssets.map(q => q.assetID).indexOf(q.id) >= 0).map(q => <Col className="col-4 p-2">{q.name}</Col>)}
                                                                </Row>
                                                            }
                                                        </Card.Body>
                                                    </Card>
                                                    <Row className="align-items-center">
                                                        <Col className="fw-bold col-2 p-2">Project Title</Col>
                                                        <Col className="col-4 p-2">
                                                            <Form.Control type="text" defaultValue={project.title} disabled={!editMode} required onChange={(e) => {
                                                                const item = utils.getCopy(project);
                                                                item.title = e.target.value;
                                                                setProject(item)
                                                            }} />
                                                        </Col>
                                                    </Row>
                                                    <Row className="align-items-center">
                                                        <Col className="fw-bold col-2 p-2">Fiscal Start Year</Col>
                                                        <Col className="col-4 p-2">
                                                            <DatePicker className="form-control" required disabled={!editMode}
                                                                selected={new Date((project.startYear + 1).toString())} showYearPicker dateFormat="yyyy" onChange={async (date) => {                                                                    
                                                                    const item = utils.getCopy(project);
                                                                    item.startYear = date == null ? '' : date.getFullYear();
                                                                    await setProject(item);
                                                                }}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row className="align-items-center">
                                                        <Col className="fw-bold col-2 p-2">Start Quarter</Col>
                                                        <Col className="col-4 p-2">
                                                            <Form.Select required defaultValue={project.quarter} disabled={!editMode} onChange={async (e) => {
                                                                const item = utils.getCopy(project);
                                                                item.quarter = e.target.value;
                                                                setProject(item);
                                                            }} >
                                                                <option value='' disabled>Select a Quarter...</option>
                                                                <option value='Q1'>Quarter 1</option>
                                                                <option value='Q2'>Quarter 2</option>
                                                                <option value='Q3'>Quarter 3</option>
                                                                <option value='Q4'>Quarter 4</option>
                                                            </Form.Select>
                                                        </Col>
                                                    </Row>
                                                    <Row className="align-items-center">
                                                        <Col className="fw-bold col-2 p-2">Project Duration</Col>
                                                        <Col className="col-4 p-2">
                                                            <InputGroup>                                                                
                                                                <NumericFormat className="form-control" decimalScale={0} fixedDecimalScale={true} value={project.duration} thousandSeparator="," disabled={!editMode} required onValueChange={async (values, sourceInfo) => {
                                                                    const item = utils.getCopy(project);
                                                                    item.duration = values.floatValue;
                                                                    await setProject(item);
                                                                }} />
                                                                <InputGroup.Text>months</InputGroup.Text>
                                                            </InputGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row className="align-items-center">
                                                        <Col className="fw-bold col-2 p-2">Description</Col>
                                                        <Col className="col-4 p-2">
                                                            <Form.Control type="text" defaultValue={project.description} disabled={!editMode} required onChange={(e) => {
                                                                const item = utils.getCopy(project);
                                                                item.description = e.target.value;
                                                                setProject(item)
                                                            }} />
                                                        </Col>
                                                    </Row>
                                                    <Row className="align-items-top">
                                                        <Col className="fw-bold col-2 p-2">Narrative</Col>
                                                        <Col className="col-4 p-2">
                                                            <Form.Control as="textarea" defaultValue={project.narrative} disabled={!editMode} required onChange={(e) => {
                                                                const item = utils.getCopy(project);
                                                                item.narrative = e.target.value;
                                                                setProject(item)
                                                            }} />
                                                        </Col>
                                                    </Row>
                                                    {!addMode &&
                                                        <Row className="align-items-center">
                                                            <Col className="fw-bold col-2 p-2">Status</Col>
                                                            <Col className="col-4 p-2">
                                                                <Form.Select required defaultValue={project.projectStatusID} disabled={!editMode} onChange={async (e) => {
                                                                    const item = utils.getCopy(project);
                                                                    item.projectStatusID = e.target.value;
                                                                    setProject(item);
                                                                }} >
                                                                    {projectStatuses.map(q => <option value={q.id}>{q.name}</option>) }
                                                                </Form.Select>
                                                            </Col>
                                                        </Row>
                                                    }
                                                    {editMode ?
                                                        <Row className="p-3">
                                                            <Col className="col-2">
                                                                <Button type="submit">Save Changes</Button>
                                                            </Col>
                                                            <Col className="col-2">
                                                                <Button variant="secondary" onClick={() => {
                                                                    if (addMode) {
                                                                        let path = '/projects';
                                                                        if (assetMode) {
                                                                            path = `/assets/${project.projectAssets[0].assetID}`;
                                                                        }
                                                                        navigate(path);
                                                                    } else {
                                                                        setEditMode(false);
                                                                    }
                                                                }}>Cancel</Button>
                                                            </Col>
                                                            <Col className="col-4 text-end">
                                                                <Button variant="danger" disabled={addMode} onClick={() => {
                                                                    setShowDeleteModal(true);
                                                                }}>Delete</Button>
                                                            </Col>
                                                        </Row>
                                                        :
                                                        <Row className="p-3">
                                                            <Col className="col-2">
                                                                <a class="btn btn-primary" onClick={() => { setEditMode(true) }}>
                                                                    Edit Project
                                                                </a>
                                                            </Col>
                                                        </Row>
                                                    }
                                                </Form>                                            
                                            </Tab.Pane>
                                            { !addMode && 
                                                <Tab.Pane eventKey="costs">
                                                    <Costs project={project} refreshCosts={refreshCosts} costs={costs} refreshProjectAssetRequirements={refreshProjectAssetRequirements} />
                                                </Tab.Pane>                                                
                                            }
                                            {!addMode &&
                                                <Tab.Pane eventKey="projectMetrics">        
                                                    <ProjectMetrics project={project} />
                                                </Tab.Pane>
                                            }
                                            {!addMode &&
                                                <Tab.Pane eventKey="assetRequirements">
                                                    <AssetRequirements project={project} refreshCosts={refreshCosts} costs={costs} refreshProjectAssetRequirements={refreshProjectAssetRequirements} projectAssetRequirements={projectAssetRequirements} />
                                                </Tab.Pane>
                                            }
                                        </Tab.Content>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>                        
                    </Tab.Container>
                    <Modal size="xl" fullscreen='xl-down' show={showAssetModal} onHide={() => { setShowAssetModal(false) }} backdrop="static">
                        <Modal.Header closeButton>
                            <Modal.Title>{assetModalMode === _single ? 'Select Asset' : 'Select Assets'}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{ maxHeight: '400px', overflowY: 'scroll' }}>
                            <Table striped>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Facility Name</th>
                                        <th>Deferred Renewal (1-3)</th>
                                        <th></th>
                                        <th>{'Recurring Costs < 10 years'}</th>
                                        <th></th>
                                        <th>System CRV</th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th><input type="search" class="form-control" placeholder="Search" onChange={(e) => {
                                            const searchString = e.target.value.trim().toUpperCase();
                                            setFacilityNameFilter(searchString);
                                            setAssetsInView([...assets].filter(q => searchString === '' || q.name.toUpperCase().includes(searchString)))
                                        }} defaultValue={facilityNameFilter} /></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {assetsInView.map(q => <tr>
                                        <td>
                                            {assetModalMode === _single ?
                                                <input class="form-check-input" type="checkbox" value="" checked={tempAsset === q.id} onChange={(e) => { setTempAsset(e.target.checked ? q.id : '') }} />
                                                :
                                                <input class="form-check-input" type="checkbox" value="" checked={assetsToAdd.indexOf(q.id) >= 0} onChange={(e) => {
                                                    if (e.target.checked) {
                                                        const _assets = [...new Set(assetsToAdd)];
                                                        _assets.push(q.id);
                                                        setAssetsToAdd(_assets);
                                                    } else {
                                                        const _assets = [...new Set(assetsToAdd.filter(r => r !== q.id))];
                                                        setAssetsToAdd(_assets);
                                                    }
                                                }} />
                                            }
                                        </td>
                                        <td>{q.name}</td>
                                        <td>{utils.getDollarString(q.deferredRenewal.sum)}</td>
                                        <td>{q.deferredRenewal.count}</td>
                                        <td>{utils.getDollarString(q.recurringCosts10.sum)}</td>
                                        <td>{q.recurringCosts10.count}</td>
                                        <td>{utils.getDollarString(q.systemCRV)}</td>
                                    </tr>)}
                                </tbody>
                            </Table>
                        </Modal.Body>
                        <Modal.Footer>
                            <Container>
                                <Row>
                                    <Col className="col-6">
                                        <Button onClick={() => {                                            
                                            const item = utils.getCopy(project);
                                            if (assetModalMode === _single) {
                                                item.projectAssets = [{ assetID: tempAsset }];
                                            } else {
                                                item.projectAssets = assetsToAdd.map((q) => {return {assetID: q}});
                                            }
                                            setProject(item);
                                            setShowAssetModal(false);
                                        }}>{assetModalMode === _single ? 'Add Selected Asset' : 'Add Selected Assets'}</Button>
                                    </Col>
                                    <Col className="col-6 text-end">
                                        <Button variant="secondary" onClick={() => { setShowAssetModal(false) }}>Cancel</Button>
                                    </Col>
                                </Row>
                            </Container>
                        </Modal.Footer>
                    </Modal>
                    <Modal size="sm" show={showDeleteModal} onHide={() => { setShowDeleteModal(false) }} backdrop="static">
                        <Modal.Header closeButton>
                            <Modal.Title>Delete</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Are you sure you want to delete this project?</Modal.Body>
                        <Modal.Footer>
                            <Container>
                                <Row>
                                    <Col className="col-6">
                                        <Button onClick={() => {
                                            setShowDeleteModal(false);
                                            handleDelete();
                                        }}>Yes</Button>
                                    </Col>
                                    <Col className="col-6 text-end">
                                        <Button variant="secondary" onClick={() => { setShowDeleteModal(false) }}>No</Button>
                                    </Col>
                                </Row>
                            </Container>
                        </Modal.Footer>
                    </Modal>
                </div>
            </React.Fragment>
    );
}

export default Project;
