import React, { useEffect, useState, useRef } from 'react';
import authService from '../api-authorization/AuthorizeService'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { XCircle } from 'react-bootstrap-icons';
import utils from '../../utils';
import Card from 'react-bootstrap/Card';
import InputGroup from 'react-bootstrap/InputGroup';
import { NumericFormat } from 'react-number-format';

const Costs = (props) => {
    const _recurringCost = 'Recurring Cost';
    const _requirement = 'Requirement'
    const _asset = "ASSET";
    const _system = "SYSTEM";
    const [project] = useState(props.project);
    const [loading, setLoading] = useState(true);

    const [deleteCostId, setDeleteCostId] = useState('');
    const [showDeleteCostModal, setShowDeleteCostModal] = useState(false);
    const [deleteCostType, setDeleteCostType] = useState('');
    const [showAddCostModal, setShowAddCostModal] = useState(false);
    const [addCostModalStep, setAddCostModalStep] = useState(1);
    const [type, setType] = useState(_recurringCost);
    const [asset, setAsset] = useState('');
    const [assetRequirements, setAssetRequirements] = useState([]);
    const [system, setSystem] = useState('');
    const [recurringCostFilter, setRecurringCostFilter] = useState('');
    const [recurringCostsInView, setRecurringCostsInView] = useState([]);
    const [recurringCosts, setRecurringCosts] = useState([]);
    const [recurringCostsToAdd, setRecurringCostsToAdd] = useState([]);
    const [requirementCostFilter, setRequirementCostFilter] = useState('');
    const [requirementCostsInView, setRequirementCostsInView] = useState([]);
    const [requirementCosts, setRequirementCosts] = useState([]);
    const [requirementCostsToAdd, setRequirementCostsToAdd] = useState([]);
    const [complexityFactors, setComplexityFactors] = useState([]);

    const [escalationEditMode, setEscalationEditMode] = useState(false);
    const [contigencyEditMode, setContigencyEditMode] = useState(false);
    const [opportunisticEditMode, setOpportunisticEditMode] = useState(false);
    const [projectCostsEditMode, setProjectCostsEditMode] = useState(false);
    const [additionalCosts, setAdditionalCosts] = useState(null);
    const [originalAdditionalCosts, setOriginalAdditionalCosts] = useState(null);

    useEffect(() => {

        async function populate() {
            await populateItems();
            await setLoading(false);
        }

        populate();
    }, [project]);

    const populateItems = async () => {
        const token = await authService.getAccessToken();
        const fetchUrl = project.type === _asset ? `api/projects/allAssetRequirements?assetID=${project.projectAssets[0].assetID}` : `api/projects/allAssetSystemRequirements?projectID=${project.id}&systemTypeID=${project.systemTypeID}`;
        let response = await fetch(fetchUrl, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const _assetRequirements = await response.json();
        await setAssetRequirements(_assetRequirements);

        response = await fetch('api/projects/getComplexityFactors', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const _complexityFactors = await response.json();
        await setComplexityFactors(_complexityFactors);

        response = await fetch(`api/projects/getAdditionalCosts?projectId=${project.id}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const _additionalCosts = await response.json();
        await setAdditionalCosts(_additionalCosts);
    }

    const handleDelete = async () => {
        const token = await authService.getAccessToken();
        await fetch(`api/projects/deleteProjectCost?id=${deleteCostId}&type=${deleteCostType}`, {
            method: 'DELETE',
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        });
        await props.refreshCosts();
        await props.refreshProjectAssetRequirements(project);
        await populateItems();        
    };

    const handleAddRequirementCosts = async () => {
        const token = await authService.getAccessToken();
        const projectRequirementCosts = requirementCostsToAdd.map(q => { return { ProjectID: project.id, RequirementID: q }; });
        await fetch('api/projects/addRequirementCosts', {
            method: 'POST',
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(projectRequirementCosts),
        });
        await props.refreshCosts();
        await props.refreshProjectAssetRequirements(project);
        await populateItems();
    };

    const handleAddRecurringCosts = async () => {
        const token = await authService.getAccessToken();
        const projectRecurringCosts = recurringCostsToAdd.map(q => { return { ProjectID: project.id, RecurringCostID: q }; });
        await fetch('api/projects/addRecurringCosts', {
            method: 'POST',
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(projectRecurringCosts),
        });
        await props.refreshCosts();
        await props.refreshProjectAssetRequirements(project);
        await populateItems();
    };

    const handleEscalationSave = async (event) =>
    {
        event.preventDefault();

        await updateAdditionalCosts();
        await setEscalationEditMode(false);
    };

    const handleContigencySave = async (event) => {
        event.preventDefault();

        await updateAdditionalCosts();
        await setContigencyEditMode(false);
    };

    const handleOpportunisticSave = async (event) => {
        event.preventDefault();

        await updateAdditionalCosts();
        await setOpportunisticEditMode(false);
    };

    const handleProjectCostsSave = async (event) => {
        event.preventDefault();

        await updateAdditionalCosts();
        await setProjectCostsEditMode(false);
    };

    const updateAdditionalCosts = async () => {
        const token = await authService.getAccessToken();
        await fetch('api/projects/updateAdditionalCosts', {
            method: 'PUT',
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(additionalCosts),
        });
        await props.refreshCosts();
        await props.refreshProjectAssetRequirements(project);
        await populateItems();
    };

    return loading ? (
        <p><em>Loading...</em></p>
    ) : (
        <Container>
            <Row>
                <Col className="col-12 text-end">
                    <Button onClick={() => {
                        setType(_recurringCost);
                        setAsset('');
                        setSystem('');
                        setAddCostModalStep(1);
                        setShowAddCostModal(true);
                    }}>Add Cost</Button>
                </Col>
            </Row>
            <Table striped>
                <thead>
                    <tr>
                        <th>Req/Rec ID</th>
                        <th>Facility Name</th>
                        <th>Type</th>
                        <th>Description</th>
                        <th>Amount</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {props.costs.map(q =>
                        <tr>
                            <td>{q.reqRecID}</td>
                            <td>{q.facilityName}</td>
                            <td>{q.type}</td>
                            <td>{q.description}</td>
                            <td>{utils.getDollarString(q.amount)}</td>
                            <td><Button variant="link" onClick={() => {
                                setDeleteCostId(q.id);
                                setDeleteCostType(q.type);
                                setShowDeleteCostModal(true);
                            }}><XCircle color="red" /></Button></td>
                        </tr>
                    )}
                </tbody>
                <tfoot>
                    <tr>
                        <th>Total:</th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th>{utils.getDollarString(utils.getSum(props.costs.map(q => q.amount)))}</th>
                        <th></th>
                    </tr>
                </tfoot>
            </Table>

            <Card className="mb-2">
                <Card.Header>Escalation</Card.Header>
                <Card.Body>
                    <Form noValidate onSubmit={handleEscalationSave}>
                        <Row className="align-items-center">
                            <Col className="fw-bold col-3 p-2 text-middle">Escalation Rate</Col>
                                <Col className="col-4 p-2">
                                    {(additionalCosts.escalationRate * 100).toFixed(2)}%
                            </Col>
                        </Row>
                        <Row className="align-items-center">
                            <Col className="fw-bold col-3 p-2 text-middle">Complexity Factor</Col>
                            <Col className="col-2 p-2">
                                <Form.Select required value={additionalCosts.complexityFactorID} disabled={!escalationEditMode} onChange={async (e) => {
                                    const item = utils.getCopy(additionalCosts);
                                        item.complexityFactorID = parseInt(e.target.value);
                                    await setAdditionalCosts(item);
                                }} >
                                    {complexityFactors.map(q => <option value={q.id}>{q.factor}</option>)}
                                </Form.Select>
                            </Col>
                        </Row>
                        <Row className="align-items-center">
                            <Col className="fw-bold col-3 p-2 text-middle">Escalated Subtotal</Col>
                            <Col className="col-9 p-2">{utils.getDollarString(additionalCosts.escalatedSubtotal)}</Col>
                        </Row>
                        {escalationEditMode ?
                            <Row className="p-2 mt-2">
                                <Col className="col-3">
                                    <Button type="submit">Save Changes</Button>
                                </Col>
                                <Col className="col-3">
                                    <a class="btn btn-secondary" onClick={async () => {
                                        await setAdditionalCosts(utils.getCopy(originalAdditionalCosts));
                                        await setEscalationEditMode(false);
                                    }}>Cancel</a>
                                </Col>
                            </Row>
                            :
                            <Row className="p-2 mt-2">
                                <Col className="col-3">
                                    <a class="btn btn-primary" onClick={async () => {
                                        await setOriginalAdditionalCosts(utils.getCopy(additionalCosts));
                                        await setEscalationEditMode(true);
                                    }}>
                                        Edit
                                    </a>
                                </Col>
                            </Row>
                        }
                    </Form>
                </Card.Body>
            </Card>

            <Card className="mb-2">
                <Card.Header>Contingencies</Card.Header>
                    <Card.Body>
                        <Form noValidate onSubmit={handleContigencySave}>
                            <Row className="align-items-center">
                                <Col className="fw-bold col-3 p-2 text-middle">Design Contingency</Col>
                                <Col className="col-2 p-2">
                                    <InputGroup>
                                        <NumericFormat className="form-control" decimalScale={2} fixedDecimalScale={true} value={additionalCosts.designContingencyPercent}
                                            thousandSeparator="," disabled={!contigencyEditMode} onValueChange={async (values, sourceInfo) => {
                                                const item = utils.getCopy(additionalCosts);                                                
                                                item.designContingencyPercent = values.floatValue;
                                                await setAdditionalCosts(item);
                                            }
                                            } /> 
                                        <InputGroup.Text>%</InputGroup.Text>
                                    </InputGroup>
                                </Col>
                                <Col className="col-2 p-2">{utils.getDollarString(additionalCosts.designContingencyAmount)}</Col>
                            </Row>
                            <Row className="align-items-center">
                                <Col className="fw-bold col-3 p-2 text-middle">Construction Contingency</Col>
                                <Col className="col-2 p-2">
                                    <InputGroup>
                                        <NumericFormat className="form-control" decimalScale={2} fixedDecimalScale={true} value={additionalCosts.constructionContingencyPercent}
                                            thousandSeparator="," disabled={!contigencyEditMode} onValueChange={async (values, sourceInfo) => {
                                                const item = utils.getCopy(additionalCosts);
                                                item.constructionContingencyPercent = values.floatValue;
                                                await setAdditionalCosts(item);
                                            }
                                            } /> 
                                        <InputGroup.Text>%</InputGroup.Text>
                                    </InputGroup>
                                </Col>
                                <Col className="col-2 p-2">{utils.getDollarString(additionalCosts.constructionContingencyAmount)}</Col>
                            </Row>
                            {contigencyEditMode ?
                                <Row className="p-2 mt-2">
                                    <Col className="col-3">
                                        <Button type="submit">Save Changes</Button>
                                    </Col>
                                    <Col className="col-3">
                                        <a class="btn btn-secondary" onClick={async () => {
                                            await setAdditionalCosts(utils.getCopy(originalAdditionalCosts));
                                            await setContigencyEditMode(false);
                                        }}>Cancel</a>
                                    </Col>
                                </Row>
                                :
                                <Row className="p-2 mt-2">
                                    <Col className="col-3">
                                        <a class="btn btn-primary" onClick={async () => {
                                            await setOriginalAdditionalCosts(utils.getCopy(additionalCosts));
                                            await setContigencyEditMode(true);
                                        }}>
                                            Edit
                                        </a>
                                    </Col>
                                </Row>
                            }
                        </Form>
                </Card.Body>
            </Card>

            <Card className="mb-2">
                <Card.Header>Opportunistic</Card.Header>
                    <Card.Body>
                        <Form noValidate onSubmit={handleOpportunisticSave}>
                            <Row className="align-items-center">
                                <Col className="fw-bold col-3 p-2 text-middle">Opportunistic System Upgrade</Col>
                                <Col className="col-2 p-2">
                                    <InputGroup>
                                        <InputGroup.Text>$</InputGroup.Text>
                                        <NumericFormat className="form-control" decimalScale={2} fixedDecimalScale={true} value={additionalCosts.opportunisticSystemUpgrade}
                                            thousandSeparator="," disabled={!opportunisticEditMode} onValueChange={async (values, sourceInfo) => {
                                                const item = utils.getCopy(additionalCosts);
                                                item.opportunisticSystemUpgrade = values.floatValue;
                                                await setAdditionalCosts(item);
                                            }
                                            } />                                        
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row className="align-items-center">
                                <Col className="fw-bold col-3 p-2 text-middle">Enabling Costs</Col>
                                <Col className="col-2 p-2">
                                    <InputGroup>
                                        <InputGroup.Text>$</InputGroup.Text>
                                        <NumericFormat className="form-control" decimalScale={2} fixedDecimalScale={true} value={additionalCosts.enablingCosts}
                                            thousandSeparator="," disabled={!opportunisticEditMode} onValueChange={async (values, sourceInfo) => {
                                                const item = utils.getCopy(additionalCosts);
                                                item.enablingCosts = values.floatValue;
                                                await setAdditionalCosts(item);
                                            }
                                            } />   
                                    </InputGroup>
                                </Col>
                            </Row>
                            {opportunisticEditMode ?
                                <Row className="p-2 mt-2">
                                    <Col className="col-3">
                                        <Button type="submit">Save Changes</Button>
                                    </Col>
                                    <Col className="col-3">
                                        <a class="btn btn-secondary" onClick={async () => {
                                            await setAdditionalCosts(utils.getCopy(originalAdditionalCosts));
                                            await setOpportunisticEditMode(false);
                                        }}>Cancel</a>
                                    </Col>
                                </Row>
                                :
                                <Row className="p-2 mt-2">
                                    <Col className="col-3">
                                        <a class="btn btn-primary" onClick={async () => {
                                            await setOriginalAdditionalCosts(utils.getCopy(additionalCosts));
                                            await setOpportunisticEditMode(true);
                                        }}>
                                            Edit
                                        </a>
                                    </Col>
                                </Row>
                            }
                        </Form>
                    </Card.Body>
            </Card>

            <Card className="mb-2">
                <Card.Header>Project Costs</Card.Header>
                    <Card.Body>
                        <Form noValidate onSubmit={handleProjectCostsSave}>
                            <Row className="align-items-center">
                                <Col className="fw-bold col-3 p-2 text-middle">Designer Fee</Col>
                                <Col className="col-2 p-2">
                                    <InputGroup>
                                        <NumericFormat className="form-control" decimalScale={2} fixedDecimalScale={true} value={additionalCosts.designerFeePercent}
                                            thousandSeparator="," disabled={!projectCostsEditMode} onValueChange={async (values, sourceInfo) => {
                                                const item = utils.getCopy(additionalCosts);
                                                item.designerFeePercent = values.floatValue;
                                                await setAdditionalCosts(item);
                                            }
                                            } /> 
                                        <InputGroup.Text>%</InputGroup.Text>
                                    </InputGroup>
                                </Col>
                                <Col className="col-2 p-2">{utils.getDollarString(additionalCosts.designerFeeAmount)}</Col>
                            </Row>
                            <Row className="align-items-center">
                                <Col className="fw-bold col-3 p-2 text-middle">Other Soft Costs</Col>
                                <Col className="col-2 p-2">
                                    <InputGroup>
                                        <NumericFormat className="form-control" decimalScale={2} fixedDecimalScale={true} value={additionalCosts.otherSoftCostsPercent}
                                            thousandSeparator="," disabled={!projectCostsEditMode} onValueChange={async (values, sourceInfo) => {
                                                const item = utils.getCopy(additionalCosts);
                                                item.otherSoftCostsPercent = values.floatValue;
                                                await setAdditionalCosts(item);
                                            }
                                            } /> 
                                        <InputGroup.Text>%</InputGroup.Text>
                                    </InputGroup>
                                </Col>
                                <Col className="col-2 p-2">{utils.getDollarString(additionalCosts.otherSoftCostsAmount)}</Col>
                            </Row>
                            <Row className="align-items-center">
                                <Col className="fw-bold col-3 p-2 text-middle">Contractor Fee</Col>
                                <Col className="col-2 p-2">
                                    <InputGroup>
                                        <NumericFormat className="form-control" decimalScale={2} fixedDecimalScale={true} value={additionalCosts.contractorFeePercent}
                                            thousandSeparator="," disabled={!projectCostsEditMode} onValueChange={async (values, sourceInfo) => {
                                                const item = utils.getCopy(additionalCosts);
                                                item.contractorFeePercent = values.floatValue;
                                                await setAdditionalCosts(item);
                                            }
                                            } /> 
                                        <InputGroup.Text>%</InputGroup.Text>
                                    </InputGroup>
                                </Col>
                                <Col className="col-2 p-2">{utils.getDollarString(additionalCosts.contractorFeeAmount)}</Col>
                            </Row>
                            <Row className="align-items-center">
                                <Col className="fw-bold col-3 p-2 text-middle">Total Project Cost</Col>
                                <Col className="col-2 p-2"></Col>
                                <Col className="fw-bold col-2 p-2">{utils.getDollarString(additionalCosts.totalProjectCost)}</Col>
                            </Row>
                            {projectCostsEditMode ?
                                <Row className="p-2 mt-2">
                                    <Col className="col-3">
                                        <Button type="submit">Save Changes</Button>
                                    </Col>
                                    <Col className="col-3">
                                        <a class="btn btn-secondary" onClick={async () => {
                                            await setAdditionalCosts(utils.getCopy(originalAdditionalCosts));
                                            await setProjectCostsEditMode(false);
                                        }}>Cancel</a>
                                    </Col>
                                </Row>
                                :
                                <Row className="p-2 mt-2">
                                    <Col className="col-3">
                                        <a class="btn btn-primary" onClick={async () => {
                                            await setOriginalAdditionalCosts(utils.getCopy(additionalCosts));
                                            await setProjectCostsEditMode(true);
                                        }}>
                                            Edit
                                        </a>
                                    </Col>
                                </Row>
                            }
                        </Form>
                    </Card.Body>
            </Card>


            <Modal size="sm" show={showDeleteCostModal} onHide={() => { setShowDeleteCostModal(false) }} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this project cost?</Modal.Body>
                <Modal.Footer>
                    <Container>
                        <Row>
                            <Col className="col-6">
                                <Button onClick={() => {
                                    setShowDeleteCostModal(false);
                                    handleDelete();
                                }}>Yes</Button>
                            </Col>
                            <Col className="col-6 text-end">
                                <Button variant="secondary" onClick={() => { setShowDeleteCostModal(false) }}>No</Button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal>
            <Modal size="lg" show={showAddCostModal} onHide={() => { setShowAddCostModal(false) }} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Add Project Cost</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ maxHeight: '400px', overflowY: 'scroll' }}>      
                    {addCostModalStep === 1 && 
                        <Container>
                            <Row>
                                <Col className="fw-bold col-12 p-2">
                                    Step 1: Type and Source
                                </Col>
                            </Row>
                            <Row className="align-items-center">
                                <Col className="fw-bold col-2 p-2">Type</Col>
                                <Col className="col-4 p-2">
                                    <Form.Select required defaultValue={type} onChange={async (e) => {
                                        await setType(e.target.value);
                                    }} >
                                        <option value={_recurringCost}>{_recurringCost}</option>
                                        <option value={_requirement}>{_requirement}</option>
                                    </Form.Select>
                                </Col>
                            </Row>
                            {project.type === _asset && 
                                <Row className="align-items-center">
                                    <Col className="fw-bold col-2 p-2">System</Col>
                                    <Col className="col-4 p-2">
                                        <Form.Select required defaultValue={system} onChange={async (e) => {
                                            await setSystem(e.target.value);
                                        }} >
                                            <option value='' disabled>Select a System...</option>
                                            {assetRequirements.map(q => <option value={q.id}>{q.name}</option>)}
                                        </Form.Select>
                                    </Col>
                                </Row>
                            }
                            {project.type === _system && 
                                <Row className="align-items-center">
                                    <Col className="fw-bold col-2 p-2">Facility</Col>
                                    <Col className="col-4 p-2">
                                        <Form.Select required defaultValue={asset} onChange={async (e) => {
                                            await setAsset(e.target.value);
                                        }} >
                                            <option value='' disabled>Select a Facility...</option>
                                            {project.projectAssets.map(q => <option value={q.asset.id}>{q.asset.facilityName}</option>)}
                                        </Form.Select>
                                    </Col>
                                </Row>
                            }
                        </Container>
                    }
                    {(addCostModalStep === 2 && type === _recurringCost)  &&
                        <Table striped>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Description</th>
                                    <th>Priority</th>
                                    <th>Cost</th>
                                </tr>
                                <tr>
                                    <th></th>
                                    <th><input type="search" class="form-control" placeholder="Search" onChange={(e) => {
                                        const searchString = e.target.value.trim().toUpperCase();
                                        setRecurringCostFilter(searchString);
                                        setRecurringCostsInView([...recurringCosts].filter(q => searchString === '' || q.description.toUpperCase().includes(searchString)))
                                    }} defaultValue={recurringCostFilter} /></th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {recurringCostsInView.map(q => <tr>
                                    <td><input class="form-check-input" type="checkbox" value="" checked={recurringCostsToAdd.indexOf(q.recurringCostID) >= 0} onChange={(e) => {
                                        if (e.target.checked) {
                                            const costs = [...new Set(recurringCostsToAdd)];
                                            costs.push(q.recurringCostID);
                                            setRecurringCostsToAdd(costs);
                                        } else {
                                            const costs = [...new Set(recurringCostsToAdd.filter(r => r !== q.recurringCostID))];
                                            setRecurringCostsToAdd(costs);
                                        }
                                    }} /></td>
                                    <td>{q.description}</td>
                                    <td>{q.priority}</td>
                                    <td>{utils.getDollarString(q.cost)}</td>
                                </tr>)}
                            </tbody>
                        </Table>
                    }
                    {(addCostModalStep === 2 && type === _requirement) &&
                        <Table striped>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Description</th>
                                    <th>Action</th>
                                    <th>Priority</th>
                                    <th>Cost</th>
                                </tr>
                                <tr>
                                    <th></th>
                                    <th><input type="search" class="form-control" placeholder="Search" onChange={(e) => {
                                        const searchString = e.target.value.trim().toUpperCase();
                                        setRequirementCostFilter(searchString);
                                        setRequirementCostsInView([...requirementCosts].filter(q => searchString === '' || q.description.toUpperCase().includes(searchString)))
                                    }} defaultValue={requirementCostFilter} /></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {requirementCostsInView.map(q => <tr>
                                    <td><input class="form-check-input" type="checkbox" value="" checked={requirementCostsToAdd.indexOf(q.requirementID) >= 0} onChange={(e) => {
                                        if (e.target.checked) {
                                            const costs = [...new Set(requirementCostsToAdd)];
                                            costs.push(q.requirementID);
                                            setRequirementCostsToAdd(costs);
                                        } else {
                                            const costs = [...new Set(requirementCostsToAdd.filter(r => r !== q.requirementID))];
                                            setRequirementCostsToAdd(costs);
                                        }
                                    }} /></td>
                                    <td>{q.description}</td>
                                    <td>{q.action}</td>
                                    <td>{q.priority}</td>
                                    <td>{utils.getDollarString(q.cost)}</td>
                                </tr>)}
                            </tbody>
                        </Table>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Container>
                        {addCostModalStep === 1 &&
                            <Row>
                                <Col className="col-6">
                                    <Button disabled={(project.type === _asset && system === '') || (project.type === _system && asset === '')} onClick={() => {
                                        const id = project.type === _asset ? system : asset;
                                        const item = assetRequirements.find(q => q.id === id);
                                        if (type === _recurringCost) {
                                            setRecurringCostFilter('');
                                            setRecurringCostsToAdd([]);
                                            if (item !== undefined) {
                                                // filter recurring costs so only the ones not already added display
                                                const recurringCostIds = props.costs.filter(q => q.type === 'Recurring Cost').map(q => q.reqRecID);                                                
                                                setRecurringCosts([...item.recurringCosts.filter(q => recurringCostIds.indexOf(q.recurringCostID) < 0)]);
                                                setRecurringCostsInView([...item.recurringCosts.filter(q => recurringCostIds.indexOf(q.recurringCostID) < 0)]);
                                            } else {
                                                setRecurringCosts([]);
                                                setRecurringCostsInView([]);
                                            }
                                        } else if (type === _requirement) {
                                            setRequirementCostFilter('');
                                            setRequirementCostsToAdd([]);                                            
                                            if (item !== undefined) {
                                                // filter requirement costs so only the ones not already added display
                                                const requirementIds = props.costs.filter(q => q.type === 'Requirement').map(q => q.reqRecID);
                                                setRequirementCosts([...item.requirementCosts.filter(q => requirementIds.indexOf(q.requirementID) < 0)]);
                                                setRequirementCostsInView([...item.requirementCosts.filter(q => requirementIds.indexOf(q.requirementID) < 0)]);
                                            } else {
                                                setRequirementCosts([]);
                                                setRequirementCostsInView([]);
                                            }
                                        }
                                        setAddCostModalStep(2);
                                    }}>Next</Button>
                                </Col>
                                <Col className="col-6 text-end">
                                    <Button variant="secondary" onClick={() => { setShowAddCostModal(false) }}>Cancel</Button>
                                </Col>
                            </Row>
                        }
                        {addCostModalStep === 2 &&
                            <Row>
                                <Col className="col-2">
                                    <Button onClick={() => {
                                        setAddCostModalStep(1);
                                    }}>Back</Button>
                                </Col>
                                <Col className="col-4">
                                    <Button disabled={(type === _recurringCost && recurringCostsToAdd.length === 0) || (type === _requirement && requirementCostsToAdd.length === 0)} onClick={async () => {
                                        if (type === _recurringCost) {
                                            await handleAddRecurringCosts();
                                        } else if (type === _requirement) {
                                            await handleAddRequirementCosts();
                                        }
                                        await setShowAddCostModal(false)
                                    }}>Add Selected Costs</Button>
                                </Col>
                                <Col className="col-6 text-end">
                                    <Button variant="secondary" onClick={() => { setShowAddCostModal(false) }}>Cancel</Button>
                                </Col>
                            </Row>
                        }
                    </Container>
                </Modal.Footer>
            </Modal>
        </Container>
    )
}

export default Costs;