import React from 'react';
import ItemsList from '../ItemsList.js';
import Card from 'react-bootstrap/Card';

const Uniformat = () => {
    const columns = [
        { id: 'system', name: 'System/Level 1', sortable: true, filter: null, selectId: null, labelFormat: null, aggregate: false },
        { id: 'level2', name: 'Level 2', sortable: true, filter: null, selectId: null, labelFormat: null, aggregate: false },
        { id: 'level3', name: 'Level 3', sortable: true, filter: null, selectId: null, labelFormat: null, aggregate: false },
        { id: 'level4', name: 'Level 4', sortable: true, filter: null, selectId: null, labelFormat: null, aggregate: false }
    ];
    const mapFunction = (q) => {
        return {
            id: q.id,
            system: q.systemType.name,
            level2: q.level2,
            level3: q.level3,
            level4: q.level4
        }
    };

    return (
        <div>
            <Card.Subtitle style={ {margin: '0 0 20px 0' }}>
                Uniformat
            </Card.Subtitle>
            <ItemsList columns={columns}
                itemName="Uniformat"
                addItemUrl=""
                mapFunction={mapFunction}
                getItemsUrl="api/settings/uniformats"
                uniqueIdColumn="id"
                showHeader={false}
                itemUrlBase=""
                shouldUpdate={true}
                />
        </div>
    );
}

export default Uniformat;