import React, { useEffect, useState, useRef } from 'react';
import authService from './api-authorization/AuthorizeService'
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import ImageUploading from "react-images-uploading";
import { InfoCircle, Clipboard2Check, CalendarEvent, Paperclip } from 'react-bootstrap-icons';
import Stack from 'react-bootstrap/Stack';
import FormControl from 'react-bootstrap/FormControl';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import Modal from 'react-bootstrap/Modal';
import utils from '../utils';
import { AssetSystemRequirements } from './AssetSystemRequirements';
import { AssetSystemRecurringCosts } from './AssetSystemRecurringCosts';
import AssetSystemAttachments from './AssetSystemAttachments';

const AssetSystem = () => {

    const { assetId, systemTypeId } = useParams();

    const [loading, setLoading] = useState(true);
    const [editing, setEditing] = useState(false);

    const [asset, setAsset] = useState(null);
    const [assetSystemInfo, setAssetSystemInfo] = useState({});
    const [assetSystemInfoTemp, setAssetSystemInfoTemp] = useState({});

    const [queryParameters] = useSearchParams();
    const [key, setKey] = useState(queryParameters.get("tabKey") != null ? queryParameters.get("tabKey") : 'overview');

    useEffect(() => {
        async function populate() {
            await populateItem();
            await setLoading(false);
        }

        populate();
    }, [loading, assetId, systemTypeId]);

    const populateItem = async () => {
        const token = await authService.getAccessToken();
        let response = await fetch(`api/assets/getAssetSystemInfo?assetId=${assetId}&systemTypeId=${systemTypeId}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const item = await response.json();
        await setAssetSystemInfo(item);
        await setAssetSystemInfoTemp(utils.getCopy(item));

        response = await fetch('api/assets/' + assetId, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        await setAsset(data);
    };

    const handleEdit = async () => {
        await setAssetSystemInfoTemp(utils.getCopy(assetSystemInfo));
        await setEditing(true);
    };
    const handleSave = async (e) => {
        e.preventDefault();

        const token = await authService.getAccessToken();
        await fetch(`api/assets/updateFacilitySystem`, {
            method: 'PUT',
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                id: assetSystemInfo.id,
                assetID: assetId,
                systemTypeID: parseInt(systemTypeId),
                cost: 0.0, // dummy data
                systemNarrative: assetSystemInfo.systemNarrative
            })
        });
        await populateItem();
        await setEditing(false);
    };
    const handleCancel = async () => {
        await setAssetSystemInfo(utils.getCopy(assetSystemInfoTemp));
        await setEditing(false);
    };

    return loading ? (
        <p><em>Loading...</em></p>
    ) : (
        <React.Fragment>
            <div>
                <Card>
                    <Card.Body>
                        <Card.Title>
                            <Breadcrumb>
                                    <Breadcrumb.Item href="/assets">Assets</Breadcrumb.Item>
                                    <Breadcrumb.Item href={`/assets/${assetId}?tabKey=systems`}>{assetSystemInfo.facilityName}</Breadcrumb.Item>
                                    <Breadcrumb.Item active>{assetSystemInfo.systemName}</Breadcrumb.Item>
                            </Breadcrumb>
                            </Card.Title>
                            <Container>
                                <Row>
                                    <Col xs="auto">
                                        <Table borderless>
                                            <tbody>
                                                <tr>
                                                    <th>Facility Code</th>
                                                    <td>{asset.facilityCode}</td>
                                                    <th>Initial CRV</th>
                                                    <td>{utils.getDollarString(asset.initialCRV)}</td>
                                                    <th>Gross SF</th>
                                                    <td>{(asset.grossSquareFootage).toLocaleString()}</td>
                                                    <th>Year Built</th>
                                                    <td>{asset.yearBuilt}</td>

                                                </tr>
                                                <tr>
                                                    <th>Facility Use</th>
                                                    <td>{asset.facilityUse.name}</td>
                                                    <th>Current CRV</th>
                                                    <td>{utils.getDollarString(asset.currentCRV)}</td>
                                                    <th>CRV / SF</th>
                                                    <td>{utils.getDollarString((asset.currentCRV / asset.grossSquareFootage))}</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                            </Container>
                    </Card.Body>
                </Card>
                <p></p>
                    <Tab.Container id="left-tabs" defaultActiveKey="overview" activeKey={key} onSelect={(k) => setKey(k)}>
                    <Row>
                        <Col sm={3}>
                            <Card>
                                <Card.Body>
                                    <Nav variant="pills" className="flex-column">
                                            <Nav.Item>
                                                <Nav.Link eventKey="overview"><InfoCircle /> Overview</Nav.Link>
                                        </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="requirements"><Clipboard2Check /> Requirements</Nav.Link>
                                        </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="recurringCosts"><CalendarEvent /> Recurring Costs</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="photosDocs"><Paperclip /> Photos and Documents</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col sm={9}>
                            <Card>
                                <Card.Body>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="overview">
                                                <Form onSubmit={handleSave}>
                                                    <Row>
                                                        <Col className="fw-bold col-2 p-2">System Narrative</Col>
                                                        <Col className="col-10 p-2">
                                                            <Form.Control as="textarea" rows={5} disabled={!editing} value={assetSystemInfo.systemNarrative}
                                                                onChange={async (e) => {
                                                                    const item = utils.getCopy(assetSystemInfo);
                                                                    item.systemNarrative = e.target.value;
                                                                    await setAssetSystemInfo(item);
                                                                }}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row className="mt-5">
                                                        <Col>
                                                            {editing ?
                                                                <div>
                                                                    <Button type="submit">Save System</Button>{' '}
                                                                    <Button type="button" variant="link" onClick={handleCancel}>Cancel</Button>
                                                                </div>
                                                                :
                                                                <Button type="button" variant="primary" onClick={handleEdit}>Edit System</Button>
                                                            }
                                                        </Col>
                                                    </Row>
                                            </Form>
                                        </Tab.Pane>
                                            <Tab.Pane eventKey="requirements">
                                                <AssetSystemRequirements assetId={assetId} systemTypeId={systemTypeId}/>
                                        </Tab.Pane>
                                            <Tab.Pane eventKey="recurringCosts">
                                                <AssetSystemRecurringCosts assetId={assetId} systemTypeId={systemTypeId} />
                                        </Tab.Pane>
                                            <Tab.Pane eventKey="photosDocs">
                                                <AssetSystemAttachments assetId={assetId} systemTypeId={systemTypeId} />
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Tab.Container>
                <p></p>
            </div>
        </React.Fragment>
    );
}

export default AssetSystem;