import React, { useEffect, useState, useRef } from 'react';
import authService from '../api-authorization/AuthorizeService'
import ItemsList from '../ItemsList.js';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const RequirementTemplates = () => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (loading) {
            const getItems = async () => {
                await setLoading(false);
            };
            getItems();
        }
    }, [loading]);

    const columns = [
        { id: 'name', name: 'Name', sortable: true, filter: "search", selectId: null, labelFormat: 'linkToItem', aggregate: false },
        { id: 'uniformatPath', name: 'Uniformat Path', sortable: true, filter: null, selectId: null, labelFormat: 'small', aggregate: false },
        { id: 'requirementType', name: 'Requirement Type', sortable: true, filter: "select", selectId: "requirementTypeId", labelFormat: null, aggregate: false },
        { id: 'description', name: 'Description', sortable: true, filter: null, selectId: null, labelFormat: null, aggregate: false },        
        { id: 'action', name: 'Action', sortable: true, filter: null, selectId: null, labelFormat: null, aggregate: false }
    ];

    const mapFunction = (q) => {
        return {
            id: q.id,
            name: q.name,
            uniformatPath: q.uniformatPath,            
            requirementTypeId: q.requirementTypeId,
            requirementType: q.requirementType,
            description: q.description,
            action: q.action
        }
    };   

    return (
        <React.Fragment>
            <Row className="align-items-center mb-3">
                <Col className="col-6">
                    <Card.Subtitle>
                        Requirement Templates
                    </Card.Subtitle>
                </Col>
            </Row>            
            <ItemsList columns={columns}
                itemName="Requirement Template"
                addItemUrl=""
                mapFunction={mapFunction}
                getItemsUrl="api/settings/requirementTemplates"
                uniqueIdColumn="id"
                showHeader={false}
                itemUrlBase="settings/requirementTemplate"
                shouldUpdate={true}
            />
        </React.Fragment>
    );
}

export default RequirementTemplates;