import React, { Component } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './NavMenu.css';
import logo from '../images/CHALogoBlue.png';
import { ApplicationPaths } from './api-authorization/ApiAuthorizationConstants';
import authService from './api-authorization/AuthorizeService';

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor (props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
        collapsed: true,
        userName: null,
        isAuthenticated: false
    };
  }

  toggleNavbar () {
    this.setState({
      collapsed: !this.state.collapsed
    });
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    async populateState() {
        const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
        this.setState({
            isAuthenticated,
            userName: user && user.name
        });
    }

    render() {
        const { isAuthenticated, userName } = this.state;
        if (!isAuthenticated) {
            const loginPath = `${ApplicationPaths.Login}`;
            return this.anonymousView(loginPath);
        } else {
            const logoutPath = `${ApplicationPaths.LogOut}`;
            return this.authenticatedView(userName, logoutPath);
        }        
    }

    authenticatedView(userName, logoutPath) {
        return (
            <nav class="navbar navbar-expand-sm border-bottom box-shadow mb-3 sticky-top" style={{ background: 'white' }} >
                <div class="container">
                    <a class="navbar-brand" href="/assets">
                        <img src={logo} alt="renu360 INSIGHT" height="36" />
                        <p class="logo-text">renu360 INSIGHT</p>
                    </a>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse">
                        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                            <li class="nav-item">
                                <a class="nav-link" href="/assets"><i class="bi bi-building"></i> Assets</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="/requirements"><i class="bi bi-clipboard2-check"></i> Requirements</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="/recurringCosts"><i class="bi bi-calendar-event"></i> Recurring Costs</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="/projects"><i class="bi bi-card-checklist"></i> Projects</a>
                            </li>
                        </ul>
                    </div>
                    <div class="collapse navbar-collapse">
                        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                            <li class="nav-item">
                                <a class="nav-link" href={logoutPath}>{userName} <i class="bi bi-box-arrow-right"></i></a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="/settings"><i class="bi bi-gear"></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        );
    }

    anonymousView(loginPath) {
        return (
            <nav class="navbar navbar-expand-sm border-bottom box-shadow mb-3 sticky-top" style={{ background: 'white' }}>
                <div class="container">
                    <a class="navbar-brand" href={loginPath}>
                        <img src={logo} alt="Bootstrap" height="36" />
                        <p class="logo-text">renu360 INSIGHT</p>
                    </a>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse">
                    </div>
                    <div class="collapse navbar-collapse">
                        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                            <li class="nav-item">
                                <a class="nav-link" href={loginPath}>Login</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        );
    }
}
