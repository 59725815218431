import React, { useEffect, useState } from 'react';
import authService from './api-authorization/AuthorizeService'
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { JournalCheck } from 'react-bootstrap-icons';
import utils from '../utils';

const AssetSystemCosts = (param) => {

    const [loading, setLoading] = useState(true);
    const [asset, setAsset] = useState(null);
    const [systemTable, setSystemTable] = useState([]);

    useEffect(() => {
        if (loading) {
            async function getTable() {
                const token = await authService.getAccessToken();
                const response = await fetch('api/assets/systems?id=' + param.asset.id, {
                    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                });

                const data = await response.json();
                await setSystemTable(data);

                await setLoading(false);
            }

            setAsset(param.asset);

            getTable();
        };

    }, [loading, param]);

    return loading ? (
        <p><em>Loading...</em></p>
    ) : (
        <React.Fragment>
            <div className="d-grid gap-2">
                <Form.Group as={Row} controlId="formBenchmarkCRVSF">
                    <Form.Label column sm={3}>Benchmark Cost / SF</Form.Label>
                    <Col sm={8}>
                        <Form.Control type="Text" plaintext readOnly placeholder={'$' + asset.facilityUse.benchmarkCRV} />
                    </Col>
                </Form.Group>
                <Table striped responsive>
                    <thead>
                        <tr>
                                <th>System</th>
                                <th />
                            <th>Requirements Priority 1-3</th>
                            <th />
                            <th>Requirements Priority 4-5</th>
                            <th />
                            <th>Recurring Costs &lt;3 Years</th>
                            <th />
                            <th>Total System Deferred Renewal</th>
                            <th>Total System Value (Benchmark)</th>
                            <th>System Benchmark %</th>
                        </tr>
                    </thead>
                    <tbody>
                        {systemTable.map(system =>
                            <tr key={system.id}>
                                <td><a href={system.link} >{system.system}</a></td>
                                <td>{system.narrative.trim().length > 0 && <JournalCheck />}</td>
                                <td class="text-end">{utils.getDollarString(system.requirementsPriority123)}</td>
                                <td class="text-end">{system.requirementsPriority123Count}</td>
                                <td class="text-end">{utils.getDollarString(system.requirementsPriority45)}</td>
                                <td class="text-end">{system.requirementsPriority45Count}</td>
                                <td class="text-end">{utils.getDollarString(system.recurringCosts3)}</td>
                                <td class="text-end">{system.recurringCosts3Count}</td>
                                <td class="text-end">{utils.getDollarString(system.totalSystemDeferredRenewal)}</td>
                                <td class="text-end">{utils.getDollarString(system.totalSystemValue)}</td>
                                <td class="text-end">{system.systemBenchmark.toFixed(2) + '%'}</td>
                            </tr>
                            )}
                    </tbody>
                    <tfoot>
                        <tr>
                                <th>Total:</th>
                                <th></th>
                                <th class="text-end">{utils.getDollarString(utils.getSum(systemTable.map(q => q.requirementsPriority123)))}</th>
                                <th class="text-end">{utils.getSum(systemTable.map(q => q.requirementsPriority123Count))}</th>
                                <th class="text-end">{utils.getDollarString(utils.getSum(systemTable.map(q => q.requirementsPriority45)))}</th>
                                <th class="text-end">{utils.getSum(systemTable.map(q => q.requirementsPriority45Count))}</th>
                                <th class="text-end">{utils.getDollarString(utils.getSum(systemTable.map(q => q.recurringCosts3)))}</th>
                                <th class="text-end">{utils.getSum(systemTable.map(q => q.recurringCosts3Count))}</th>
                                <th class="text-end">{utils.getDollarString(utils.getSum(systemTable.map(q => q.totalSystemDeferredRenewal)))}</th>
                                <th class="text-end">{utils.getDollarString(utils.getSum(systemTable.map(q => q.totalSystemValue)))}</th>
                                <th class="text-end">{utils.getSum(systemTable.map(q => q.systemBenchmark)).toFixed(2) + '%'}</th>
                            </tr>
                    </tfoot>
                </Table>
            </div>
        </React.Fragment>
    );
}

export default AssetSystemCosts;