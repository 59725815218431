import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import utils from '../utils';

const AssetScenarioPlanningModel = (param) => {

    const [loading, setLoading] = useState(true);
    const [scenario, setScenario] = useState([]);

    useEffect(() => {
        setScenario(param.scenario);
        setLoading(false);
    }, [loading, param]);

    return loading ? (
        <p><em>Loading...</em></p>
    ) : (
        <React.Fragment>
            <Table striped>
                <thead>
                    <tr>
                        <th>Year</th>
                        <th>Discrete Recurring Costs</th>
                        <th>Accumulated Recurring Costs</th>
                        <th>Accumulated Requirements (1-3)</th>
                        <th>Accumulated Deterioration</th>
                        <th>Deferred Renewal</th>
                        <th>CRV</th>
                        <th>FCI</th>
                        <th>Asset Deferred Renewal Funding</th>
                    </tr>
                </thead>
                <tbody>
                    {scenario.scenarioYears.map((scenarioYear, index) =>
                        <tr key={scenarioYear.year}>
                            <td class="text-end">{scenarioYear.year}</td>
                            <td class="text-end">{utils.getDollarString(scenarioYear.discrete)}</td>
                            <td class="text-end">{utils.getDollarString(scenarioYear.accum)}</td>
                            <td class="text-end">{utils.getDollarString(scenarioYear.accumReq)}</td>
                            <td class="text-end">{utils.getDollarString(scenarioYear.accumDet)}</td>
                            <td class="text-end">{utils.getDollarString(scenarioYear.modDeferred)}</td>
                            <td class="text-end">{utils.getDollarString(scenarioYear.crv)}</td>
                            <td class="text-end">{scenarioYear.fci.toFixed(2)}</td>
                            <td class="text-end">{utils.getDollarString(scenarioYear.deferredRenewalFunding)}</td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </React.Fragment>
    );
}

export default AssetScenarioPlanningModel;