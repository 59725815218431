import React, { useEffect, useState, useRef } from 'react';
import authService from './api-authorization/AuthorizeService'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import { BoxArrowUpLeft } from 'react-bootstrap-icons';
import { Pencil } from 'react-bootstrap-icons';
import { ArrowBarDown } from 'react-bootstrap-icons';
import { Trash3 } from 'react-bootstrap-icons';
//import 'bootstrap-icons/font/bootstrap-icons.css';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import utils from '../utils';

const AssetScenarioPlanningScenarios = (param) => {

    const [loading, setLoading] = useState(true);
    const [asset, setAsset] = useState(param.asset);
    const [showModal, setShowModal] = useState(false);
    const [validated, setValidated] = useState(false);
    const [editVals, setEditVals] = useState([]);
    const [showDelete, setShowDelete] = useState(false);
    const [showFundingModal, setShowFundingModal] = useState(false);
    const [fundingValidated, setFundingValidated] = useState(false);
    const [scenarioValidated, setScenarioValidated] = useState(0);
    const [formDOMs, setFormDOMs] = useState([]);
    let clickedIndex = -1;
    let clickedAction = 0;    

    useEffect(() => {
        setLoading(false);
    }, [loading, param, asset]);

    const handleAddScenario = () => {
        async function addData(scenario) {

            const token = await authService.getAccessToken();

            const response = await fetch('api/scenarios', {
                method: 'POST',
                headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
                body: JSON.stringify(scenario),
            });

            const data = await response.json();
            data.state = 1;

            asset.scenarios[1] = data;

            param.updateScenarios();

            handleShowEdit(0);
        };

        if (asset.scenarios[1].state === 0) {
            asset.scenarios[1].name = 'Spending Model';

            let newScenario = structuredClone(asset.scenarios[0]);
            asset.scenarios[1].scenarioYears.forEach((scenarioYear, index) => {
                scenarioYear.deferredRenewalFunding = newScenario.scenarioYears[index].deferredRenewalFunding;
            });

            addData(asset.scenarios[1]);
        }

        handleShowEdit(0);
    }

    const handleShowEdit = (index) => {

        let name = '';

        if (index > 0) {
            index++;
        }

        if (index !== 0) {
            name = asset.scenarios[index].name
        }

        setEditVals({
            index: index,
            name: name
        });

        setShowModal(true);
        setValidated(false);
    }

    const handleSaveChanges = (event) => {
        async function editData(scenario) {
            const token = await authService.getAccessToken();
            await fetch('api/scenarios/' + scenario.id, {
                method: 'PUT',
                headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
                body: JSON.stringify(scenario),
            });

            await setShowModal(false);
            await setValidated(false);

            param.updateScenarios();
        };

        async function addData(scenario) {

            const token = await authService.getAccessToken();

            const response = await fetch('api/scenarios', {
                method: 'POST',
                headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
                body: JSON.stringify(scenario),
            });

            const data = await response.json();
            data.state = 1;

            let newAsset = asset;
            await newAsset.scenarios.push(data);

            await setAsset(newAsset);

            await setShowModal(false);
            await setValidated(false);

            param.updateScenarios();
        };

        const form = event.currentTarget;

        event.preventDefault();

        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
            return;
        }

        if (editVals.index !== 0) {
            asset.scenarios[editVals.index].name = editVals.name;
            editData(asset.scenarios[editVals.index]);
        } else {
            let scenario = {
                name: editVals.name,
                scenarioYears: asset.scenarios[0].scenarioYears.map(scenarioYear => { return { ...scenarioYear } }),
                funding: 0,
                id: 0,
                state: 0,
                assetID: asset.id,
            };

            scenario.scenarioYears.forEach(scenarioYear => {
                scenarioYear.id = undefined;
                scenarioYear.scenarioID = undefined;
            });

            addData(scenario);
        }
    }

    const handleDeleteClose = () => setShowDelete(false);

    const handleDeleteShow = (index) => {

        index++;

        editVals.index = index;
        setShowDelete(true);
    }

    const handleDeleteScenario = () => {
        deleteScenario(editVals.index);
    }

    const deleteScenario = (index) => {
        async function deleteScenarioData(id) {
            const token = await authService.getAccessToken();
            await fetch('api/scenarios/' + id, {
                method: 'DELETE',
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
            });

            let newScenarios = await asset.scenarios.filter(scenario => scenario.id !== id);
            asset.scenarios = newScenarios;

            await setShowDelete(false);

            param.updateScenarios();
        };

        deleteScenarioData(asset.scenarios[index].id);
    };

    const handleShowFunding = (index) => {
        const formDOM = formDOMs[index - 1];
        formDOM.reset();

        if (index > 0) {
            index++;
        }

        setEditVals({
            index: index,
            funding: asset.scenarios[index].funding
        });

        setShowFundingModal(true);
        setFundingValidated(false);
    }

    const handleModelAction = (event) => {
        const form = event.currentTarget;

        event.preventDefault();

        if (form.checkValidity() === false) {
            event.stopPropagation();
            setScenarioValidated(clickedIndex);
            return;
        }

        setScenarioValidated(0);

        if (clickedIndex > 0) {
            clickedIndex++;
        }

        asset.scenarios[1].name = 'Spending Model';

        if (asset.scenarios.length > 2) {
            asset.scenarios[1].state = 1;
        }

        // Just copy deferred renewal funding values to spending model so ids don't get overwritten
        let newScenario = structuredClone(asset.scenarios[clickedIndex]);
        asset.scenarios[1].scenarioYears.forEach((scenarioYear, index) => {
            scenarioYear.deferredRenewalFunding = newScenario.scenarioYears[index].deferredRenewalFunding;
        });

        param.scenario.scenarioYears = newScenario.scenarioYears;

        param.updateScenarios();

        param.switchTab();

        handleSaveScenarioChanges(1);
    }

    const handleSaveFundingChanges = (event) => {
        const form = event.currentTarget;

        event.preventDefault();

        if (form.checkValidity() === false) {
            event.stopPropagation();
            setFundingValidated(true);
            return;
        }

        if (editVals.index === 0 || editVals.index > formDOMs.length) {
            return;
        }

        asset.scenarios[editVals.index].funding = editVals.funding;

        let accumFunding = 0;
        asset.scenarios[editVals.index].scenarioYears.forEach(scenarioYear => {
            scenarioYear.modDeferred = scenarioYear.deferred - accumFunding;
            scenarioYear.deferredRenewalFunding = editVals.funding;

            scenarioYear.fci = scenarioYear.modDeferred / scenarioYear.crv;

            accumFunding = accumFunding + editVals.funding;
        });

        const formDOM = formDOMs[editVals.index - 1];
        formDOM.reset();

        setShowFundingModal(false);
        setFundingValidated(false);

        param.updateScenarios();

        handleSaveScenarioChanges(editVals.index);
    }

    const updateScenario = (index) => {

        const form = formDOMs[index - 1];

        if (form.checkValidity() === false) {
            setScenarioValidated(index);
            return;
        }

        setScenarioValidated(0);

        let accumFunding = 0;
        asset.scenarios[index].scenarioYears.forEach(scenarioYear => {
            scenarioYear.modDeferred = scenarioYear.deferred - accumFunding;
            scenarioYear.fci = scenarioYear.modDeferred / scenarioYear.crv;

            accumFunding = accumFunding + scenarioYear.deferredRenewalFunding;
        });

        form.reset();

        param.updateScenarios();
    }

    const addFormDOM = (form, cardIndex) => {
        if (cardIndex >= formDOMs.length) {
            let existingFormDOMs = formDOMs;

            existingFormDOMs.push(form);

            setFormDOMs(existingFormDOMs);
        }
    }

    const handleSaveScenarioChanges = (index) => {
        async function saveScenarioData(scenario) {
            const token = await authService.getAccessToken();
            const response = await fetch('api/scenarios/' + scenario.id, {
                method: 'PUT',
                headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
                body: JSON.stringify(scenario),
            });

            return response;
        };

        async function addData(scenario) {

            const token = await authService.getAccessToken();

            const response = await fetch('api/scenarios', {
                method: 'POST',
                headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
                body: JSON.stringify(scenario),
            });

            const data = await response.json();
            data.state = 1;

            let newAsset = asset;
            await newAsset.scenarios.push(data);

            await setAsset(newAsset);

            await setShowModal(false);
            await setValidated(false);

            param.updateScenarios();
        };

        if (asset.scenarios[index].state === 0) {
            addData(asset.scenarios[index], true);
        } else {
            saveScenarioData(asset.scenarios[index]);
        }
    }

    return loading ? (
        <p><em>Loading...</em></p>
    ) : (
        <React.Fragment>
            <Container>
                <Row>
                    <Stack direction="horizontal">
                        <Button type="button" variant="primary" className="ms-auto" onClick={() => { handleAddScenario() }}>
                            Add Scenario
                        </Button>
                    </Stack>
                </Row>
                <p></p>
                <Row xs={1} md={2} className="g-4">
                    {asset.scenarios.length === 0 ?
                        null
                        :
                        asset.scenarios.filter((scenario, index) => index !== 1).map((scenario, cardIndex) => (
                            <Col key={cardIndex}>
                                <Form ref={(form) => addFormDOM(form, cardIndex)} noValidate validated={scenarioValidated === cardIndex} onSubmit={handleModelAction}>
                                    <Card>
                                        <Card.Header>
                                            <Stack direction="horizontal" className="align-items-center">
                                                {scenario.name}
                                                {cardIndex === 0 ?
                                                    <Button variant="outline-primary" className="ms-auto" type="submit" onClick={() => { clickedIndex = cardIndex; clickedAction = 1 }}>
                                                        <BoxArrowUpLeft />
                                                    </Button>
                                                    :
                                                    <ButtonGroup className="ms-auto">
                                                        <Button variant="outline-primary" onClick={() => { handleShowEdit(cardIndex) }}>
                                                            <Pencil />
                                                        </Button>
                                                        <Button variant="outline-primary" type="submit" onClick={() => { clickedIndex = cardIndex; clickedAction = 1 }}>
                                                            <BoxArrowUpLeft />
                                                        </Button>
                                                        <Button variant="outline-primary" onClick={() => { handleShowFunding(cardIndex) }}>
                                                            <ArrowBarDown />
                                                        </Button>
                                                        {/*<Button variant="outline-primary" type="submit" onClick={() => { clickedIndex = cardIndex; clickedAction = 2 }}>
                                                            <i class='bi bi-floppy' />
                                                        </Button>*/}
                                                        <Button variant="outline-primary" onClick={() => { handleDeleteShow(cardIndex) }}>
                                                            <Trash3 />
                                                        </Button>
                                                    </ButtonGroup>
                                                }
                                            </Stack>
                                        </Card.Header>
                                        <Card.Body>
                                            <Table striped>
                                                <thead>
                                                    <tr>
                                                        <th>Fiscal Year</th>
                                                        <th>Accumulated Deferred Renewal</th>
                                                        <th>FCI</th>
                                                        <th>Asset Deferred Renewal Funding</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {scenario.scenarioYears.map((scenarioYear, rowIndex) =>
                                                        <tr key={scenarioYear.year}>
                                                            <td class="text-end" style={{ verticalAlign: 'middle' }}>{scenarioYear.year}</td>
                                                            <td class="text-end" style={{ verticalAlign: 'middle' }}>{utils.getDollarString(scenarioYear.modDeferred)}</td>
                                                            <td class="text-end" style={{ verticalAlign: 'middle' }}>{scenarioYear.fci.toFixed(2)}</td>
                                                            <td class="text-end">
                                                                    <InputGroup>
                                                                        <InputGroup.Text>$</InputGroup.Text>
                                                                        <Form.Control type="Text" disabled={cardIndex === 0} readOnly={cardIndex === 0} defaultValue={scenarioYear.deferredRenewalFunding}
                                                                            onBlur={({ target: { value } }) => {
                                                                                scenarioYear.deferredRenewalFunding = Number(value);

                                                                                if (cardIndex > 0) {
                                                                                    cardIndex++;
                                                                                }

                                                                                updateScenario(cardIndex);
                                                                                handleSaveScenarioChanges(cardIndex);
                                                                            }
                                                                            }
                                                                            pattern="^(?:(?:0{1,2}|[1-9]\d*)?\.\d{1,2}|[1-9]\d*|0)$"
                                                                        />
                                                                    </InputGroup>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>

                                            </Table>

                                        </Card.Body>
                                    </Card>
                                </Form>
                            </Col>
                        ))
                    }
                </Row>
            </Container>
            <Modal show={showModal} onHide={() => { setShowModal(false) }} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Add/Edit Scenario</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={validated} onSubmit={handleSaveChanges}>
                        <div className="d-grid gap-3">
                            <Form.Group as={Row} controlId="formEditName">
                                <Form.Label column sm={6}>Scenario Name</Form.Label>
                                <Col sm={4}>
                                    <Form.Control type="Text" required defaultValue={editVals.name}
                                        onChange={({ target: { value } }) => editVals.name = value}>
                                    </Form.Control>
                                </Col>
                            </Form.Group>
                            <Stack direction="horizontal" gap={1}>
                                <Button type="submit" >
                                    Save Changes
                                </Button>
                                <Button className="ms-auto" variant="link" onClick={() => { setShowModal(false) }} >
                                    Cancel
                                </Button>
                            </Stack>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
            <Modal show={showFundingModal} onHide={() => { setShowFundingModal(false) }} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Set Level Funding</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={fundingValidated} onSubmit={handleSaveFundingChanges}>
                        <div className="d-grid gap-3">
                            <Form.Group as={Row} controlId="formEditFunding">
                                <Form.Label column sm={4}>Funding</Form.Label>
                                <Col sm={6}>
                                    <InputGroup>
                                        <InputGroup.Text>$</InputGroup.Text>
                                        <Form.Control type="Text" required defaultValue={editVals.funding}
                                            onChange={({ target: { value } }) => editVals.funding = Number(value)}
                                            pattern="^(?:(?:0{1,2}|[1-9]\d*)?\.\d{1,2}|[1-9]\d*|0)$" >
                                        </Form.Control>
                                        <InputGroup.Text>per year</InputGroup.Text>
                                    </InputGroup>
                                </Col>
                            </Form.Group>
                            <Stack direction="horizontal" gap={1}>
                                <Button type="submit" >
                                    Save Changes
                                </Button>
                                <Button className="ms-auto" variant="link" onClick={() => { setShowFundingModal(false) }} >
                                    Cancel
                                </Button>
                            </Stack>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
            <Modal show={showDelete} onHide={handleDeleteClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Scenario</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this scenario?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleDeleteScenario}>
                        Yes
                    </Button>
                    <Button variant="primary" onClick={handleDeleteClose}>
                        No
                    </Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    );
}

export default AssetScenarioPlanningScenarios;