import React, { useEffect, useState, useRef } from 'react';
import authService from './api-authorization/AuthorizeService';
import Stack from 'react-bootstrap/Stack';
import FormControl from 'react-bootstrap/FormControl';
import Form from 'react-bootstrap/Form';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import { Paperclip, Building } from 'react-bootstrap-icons';

const AssetSystemAttachments = (props) => {   
    const [assetId] = useState(props.assetId);
    const [systemTypeId] = useState(parseInt(props.systemTypeId));

    const [attachmentTypes, setAttachmentTypes] = useState([]);
    const [attachments, setAttachments] = useState([]);
    const [currentAttachment, setCurrentAttachment] = useState(null);
    const [validatedAttachment, setValidatedAttachment] = useState(false);
    const [attachmentTypeIDs, setAttachmentTypeIDs] = useState([]);
    const [modifyingAttachment, setModifyingAttachment] = useState(false);
    const [viewingAttachment, setViewingAttachment] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [loaded, setLoaded] = useState(false);

    const [attachmentsForDisplay, setAttachmentsForDisplay] = useState([]);
    const [attachmentPages, setAttachmentPages] = useState(1);
    const [attachmentPageNumber, setAttachmentPageNumber] = useState(1);
    const [attachmentPageSize, setAttachmentPageSize] = useState(10);

    useEffect(() => {
        async function populateData() {
            const token = await authService.getAccessToken();
            const response = await fetch('api/attachmenttypes/', {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });

            const data = await response.json();
            await setAttachmentTypes(data);

            var ids = data.map(at => (at.id));
            await setAttachmentTypeIDs(ids);

            await getAttachments();
        }
        if (!loaded) {
            populateData()
            setLoaded(true);
        }
    }, [loaded]);

    const getAttachments = async () => {
        const token = await authService.getAccessToken();
        const response = await fetch(`api/attachments/getAssetSystemAttachments?assetID=${assetId}&systemTypeID=${systemTypeId}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        data.forEach((attachment) => {
            if (attachment.dataUri != null) { attachment.data_url = attachment.dataUri }
            else { attachment.data_url = attachment.dataType + 'base64,' + attachment.data }
        });
        await setAttachments(data);
        await populateAttachmentsForDisplay(data, 1, attachmentPageSize, null);
    };

    const uploadedAttachment = (event) => {
        currentAttachment.name = event.target.files[0].name;

        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onloadend = function () {
            var base64data = reader.result;

            var newAttachment = currentAttachment;
            newAttachment.data_url = base64data;

            setCurrentAttachment(newAttachment);
        }
    }

    const handleCancelModifyAttachment = () => {
        setModifyingAttachment(false);
    }

    const handleDeleteClose = () => setShowDelete(false);
    const handleDeleteShow = () => setShowDelete(true);

    const handleDeleteAttachment = () => {
        handleDeleteClose();
        deleteAttachment(currentAttachment);
    }

    const deleteAttachment = (attachment) => {
        async function deleteAttachmentData(id) {
            const token = await authService.getAccessToken();
            await fetch(`api/attachments/deleteAssetSystemAttachment?id=${id}`, {
                method: 'DELETE',
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
            });
            await getAttachments();
            await setModifyingAttachment(false);
        };

        deleteAttachmentData(attachment.id);
    };

    const handleAddAttachment = () => {

        var data = {
            assetID: assetId,
            systemTypeID: systemTypeId,
            data: '',
            dataType: '',
            description: '',
            name: '',
            attachmentTypeID: '',
        };

        setCurrentAttachment(data);
        setModifyingAttachment(true);
    }

    const handleSaveAttachment = (event) => {
        const form = event.currentTarget;

        event.preventDefault();

        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidatedAttachment(true);
            return;
        }

        setValidatedAttachment(false);

        saveAttachment(currentAttachment);
    };

    const saveAttachment = (attachment) => {
        async function saveAttachmentData(attachment) {
            const token = await authService.getAccessToken();
            await fetch('api/attachments/updateAssetSystemAttachemnt', {
                method: 'PUT',
                headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
                body: JSON.stringify(attachment),
            });
            await getAttachments();
            await setModifyingAttachment(false);
        };

        async function addAttachment(attachment) {

            const token = await authService.getAccessToken();
            await fetch('api/attachments/addAssetSystemAttachemnt', {
                method: 'POST',
                headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
                body: JSON.stringify(attachment),
            });
            await getAttachments();
            await setModifyingAttachment(false);
        };

        var splitData = attachment.data_url.split("base64,");

        attachment.data = splitData[1];
        attachment.dataType = splitData[0];

        if (attachment.id === undefined) {
            addAttachment(attachment);
        } else {
            saveAttachmentData(attachment);
        }
    };

    const handleView = (attachment) => (event) => {
        setCurrentAttachment(attachment);
        setModifyingAttachment(true);
        setViewingAttachment(true);
    }

    const handleBackGallery = () => {
        setViewingAttachment(false);
        setModifyingAttachment(false);
    }

    const handleEditAttachment = () => {
        setViewingAttachment(false);
    }

    const goToAttachmentPage = async (pageNumber) => {
        await setAttachmentPageNumber(pageNumber);
        await populateAttachmentsForDisplay(attachments, pageNumber, attachmentPageSize, attachmentTypeIDs);
    };

    const updateAttachmentPageSize = async (event) => {
        await populateAttachmentsForDisplay(attachments, 1, event.target.value, attachmentTypeIDs);
    };

    const populateAttachmentsForDisplay = async (attachmentSource, pageNumber, pageSize, typeIDs) => {
        await setAttachmentsForDisplay(attachmentSource.filter((a) => typeIDs == null || typeIDs.includes(a.attachmentTypeID)).slice(pageSize * (pageNumber - 1), pageSize * pageNumber));
        await setAttachmentPageSize(pageSize);
        await setAttachmentPageNumber(pageNumber);
        if (typeIDs != null) {
            await setAttachmentTypeIDs(typeIDs);
        }
        await setAttachmentPages(Math.ceil(attachmentSource.filter((a) => typeIDs == null || typeIDs.includes(a.attachmentTypeID)).length / pageSize));
    };

    let minPage = attachmentPageNumber === 1 ? 1 : attachmentPageNumber - 1;
    const maxPage = attachmentPages < minPage + 2 ? attachmentPages : minPage + 2;
    if (maxPage === attachmentPageNumber && maxPage > 2) {
        minPage = maxPage - 2;
    }
    let attachmentPageNumbers = [];
    for (let i = minPage - 1; i < maxPage; i++) {
        attachmentPageNumbers.push(i + 1);
    }

    return (
        <div>
            {!modifyingAttachment ?
                <Container>
                    <Row>
                        <Stack direction="horizontal" gap={3}>
                            <ToggleButtonGroup type="checkbox" defaultValue={attachmentTypeIDs}
                                onChange={async (value) => {
                                    await populateAttachmentsForDisplay(attachments, 1, attachmentPageSize, value);
                                    setAttachmentTypeIDs(value)
                                }}>
                                {
                                    attachmentTypes.map(attachmentType =>
                                        <ToggleButton id={"tgb" + attachmentType.id} variant="outline-primary" value={attachmentType.id}>
                                            {attachmentType.name + 's'}
                                        </ToggleButton>
                                    )
                                }
                            </ToggleButtonGroup>
                            <Button type="button" variant="primary" className="ms-auto" onClick={handleAddAttachment}>Add Attachment</Button>
                        </Stack>
                    </Row>
                    <p></p>
                    <Row xs={1} md={3} className="g-4">
                        {attachments.length === 0 ?
                            <Building color="lightgray" size={300} />
                            :
                            attachmentsForDisplay.map((attachment, index) => (
                                <Col key={index}>
                                    <Card>
                                        {(attachment.data_url.startsWith('data:image') ||
                                            attachment.data_url.includes('.jpg?') ||
                                            attachment.data_url.includes('.jpeg?') ||
                                            attachment.data_url.includes('.png?') ||
                                            attachment.data_url.includes('.bmp?') ||
                                            attachment.data_url.includes('.gif?')) ?
                                            <Card.Img variant="top" height="200" src={attachment.data_url} style={{
                                                objectFit: 'cover'
                                            }} />
                                            :
                                            <Card.Header className="text-center" height="200">
                                                <Paperclip color="lightgray" size={183} />
                                            </Card.Header>
                                        }
                                        <Card.Body>
                                            <Card.Subtitle className="text-truncate">{attachment.name}</Card.Subtitle>
                                            <p></p>
                                            <Card.Text className="text-truncate">
                                                {attachment.description}
                                            </Card.Text>
                                            <Card.Link href={attachment.data_url} download={attachment.name}>Download</Card.Link>
                                            <Card.Link style={{ cursor: "pointer" }} onClick={handleView(attachment)}>View</Card.Link>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))
                        }
                    </Row>
                    {attachments.length > 0 &&
                    <Row className="justify-content-center justify-content-sm-between align-items-sm-center mt-5">
                        <div class="col-sm mb-2 mb-sm-0">
                            <div class="d-flex justify-content-center justify-content-sm-start align-items-center">
                                <select class="js-select form-select form-select-borderless w-auto" aria-label="" onChange={updateAttachmentPageSize} value={attachmentPageSize}>
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                    <option value="30">30</option>
                                    <option value="50">50</option>
                                </select>
                                <span>&nbsp;Attachments per page</span>
                            </div>
                        </div>
                        <div class="col-sm-auto">
                            <nav>
                                <ul class="pagination">
                                    <li className={`page-item ${attachmentPageNumber === 1 ? 'disabled' : ''}`} >
                                        <a class="page-link" aria-label="Previous" onClick={async () => { await goToAttachmentPage(attachmentPageNumber - 1) }}>
                                            <span aria-hidden="true">&laquo;</span>
                                        </a>
                                    </li>
                                    {attachmentPageNumbers.map(pageNumber =>
                                        <li className={`page-item ${pageNumber === attachmentPageNumber ? 'active' : ''}`}>
                                            <a class="page-link" onClick={async () => { await goToAttachmentPage(pageNumber) }}>{pageNumber}</a>
                                        </li>
                                    )}
                                    <li className={`page-item ${attachmentPageNumber === attachmentPages ? 'disabled' : ''}`}>
                                        <a class="page-link" aria-label="Next" onClick={async () => { await goToAttachmentPage(attachmentPageNumber + 1) }}>
                                            <span aria-hidden="true">&raquo;</span>
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        </Row>
                    }
                </Container>
                :
                <Form noValidate validated={validatedAttachment} onSubmit={handleSaveAttachment}>
                    <div className="d-grid gap-2">
                        {viewingAttachment ?
                            <Row>
                                <Stack direction="horizontal" gap={0}>
                                    <Button type="button" variant="link" onClick={handleBackGallery}><b>Back to Gallery</b></Button>
                                    <Button type="button" variant="primary" className="ms-auto" onClick={handleEditAttachment}>Edit Attachment</Button>
                                </Stack>
                            </Row>
                            :
                            null
                        }

                        <Form.Group as={Row} controlId="formAttachmentType">
                            <Form.Label column sm={2}>Type</Form.Label>
                            <Col sm={3}>
                                <Form.Select type="Text" disabled={viewingAttachment} defaultValue={currentAttachment.attachmentTypeID}
                                    onChange={({ target: { value } }) => currentAttachment.attachmentTypeID = Number(value)} required>
                                    <option value='' disabled>Select...</option>
                                    {
                                        attachmentTypes.map(attachmentType =>
                                            <option value={attachmentType.id}>{attachmentType.name}</option>
                                        )
                                    }
                                </Form.Select>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formAttachmentDesc">
                            <Form.Label column sm={2}>Description</Form.Label>
                            <Col sm={5}>
                                <Form.Control required as="textarea" maxlength="1024" rows={3} disabled={viewingAttachment} defaultValue={currentAttachment.description} placeholder="Document Description"
                                    onChange={({ target: { value } }) => currentAttachment.description = value}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formAttachmentFile">
                            <Form.Label column sm={2}>File</Form.Label>
                            <Col sm={5}>
                                {viewingAttachment ?
                                    <Form.Control type="Text" plaintext disabled value={currentAttachment.name} />
                                    :
                                    <div>
                                        {currentAttachment.name.length > 0 ?
                                            <Form.Control type="Text" plaintext disabled value={currentAttachment.name} />
                                            :
                                            null
                                        }

                                        <FormControl required={currentAttachment.name.length === 0} type="file" onChange={uploadedAttachment} />
                                    </div>
                                }
                            </Col>
                        </Form.Group>
                        {!viewingAttachment ?
                            <Row>
                                <Stack direction="horizontal" gap={1}>
                                    <Button type="submit">Save Changes</Button>
                                    <Button type="button" variant="link" onClick={handleCancelModifyAttachment}>Cancel</Button>
                                    {currentAttachment.name.length > 0 ?
                                        <Button type="button" variant="link" className="ms-auto" onClick={handleDeleteShow}>Delete Attachment</Button>
                                        :
                                        null
                                    }
                                    <Modal show={showDelete} onHide={handleDeleteClose}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Delete Attachment</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>Are you sure you want to delete this attachment?</Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="secondary" onClick={handleDeleteAttachment}>
                                                Yes
                                            </Button>
                                            <Button variant="primary" onClick={handleDeleteClose}>
                                                No
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                </Stack>
                            </Row>
                            :
                            null
                        }
                        {viewingAttachment && (currentAttachment.data_url.startsWith('data:image') ||
                            currentAttachment.data_url.toLowerCase().includes('.jpg?') ||
                            currentAttachment.data_url.toLowerCase().includes('.jpeg?') ||
                            currentAttachment.data_url.toLowerCase().includes('.png?') ||
                            currentAttachment.data_url.toLowerCase().includes('.bmp?') ||
                            currentAttachment.data_url.toLowerCase().includes('.gif?')) ?
                            <Row>
                                <Col sm={2} />
                                <Col sm={10}>
                                    <Image rounded src={currentAttachment.data_url} alt="" style={{ maxWidth: '100%' }} />
                                </Col>
                            </Row>
                            :
                            <div></div>
                        }
                    </div>
                </Form>
            }
        </div>
    );
}

export default AssetSystemAttachments;
