import React, { Component } from 'react';
import ItemsList from './ItemsList.js';

export class AssetRequirements extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const columns = [
            { id: 'reqId', name: 'Req. ID', sortable: true, filter: null, selectId: null, labelFormat: 'linkToItem', aggregate: false },
            { id: 'system', name: 'System', sortable: true, filter: 'select', selectId: 'systemId', labelFormat: null, aggregate: false },
            { id: 'type', name: 'Type', sortable: true, filter: 'select', selectId: 'typeId', labelFormat: null, aggregate: false },
            { id: 'requirement', name: 'Requirement', sortable: true, filter: null, selectId: null, labelFormat: null, aggregate: false },
            { id: 'action', name: 'Action', sortable: true, filter: null, selectId: null, labelFormat: null, aggregate: false },
            { id: 'status', name: 'Status', sortable: true, filter: 'select', selectId: 'statusId', labelFormat: null, aggregate: false },
            { id: 'priority', name: 'Priority', sortable: true, filter: 'select', selectId: 'priorityId', labelFormat: null, aggregate: false },
            { id: 'project', name: 'Project', sortable: true, filter: null, selectId: null, labelFormat: 'linkToProject', aggregate: false },
            { id: 'cost', name: 'Cost', sortable: true, filter: null, selectId: null, labelFormat: 'dollar', aggregate: true },
        ];

        const mapFunction = (q) => {
            return {
                id: q.id,
                reqId: q.id,
                system: q.system,
                systemId: q.systemId,
                type: q.type,
                typeId: q.typeId,
                requirement: q.requirement,
                action: q.action,
                status: q.status,
                statusId: q.statusId,
                priority: q.priority,
                priorityId: q.priorityId,
                project: q.project,
                projectId: q.projectId,
                cost: q.cost
            }
        };

        return (
            <div class="card">
                <div class="card-header">
                    <div class="row align-items-center">
                        <div class="col-12 text-end">
                            <a href={`/requirements/add?assetId=${this.props.assetId}` } class="btn btn-primary">Add Requirement</a>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <ItemsList columns={columns}
                        itemName=""
                        addItemUrl=""
                        mapFunction={mapFunction}
                        getItemsUrl={`api/assets/requirements?id=${this.props.assetId}`}
                        uniqueIdColumn="reqId"
                        showHeader={false}
                        itemUrlBase='requirements'
                        itemUrlParams={`assetId=${this.props.assetId}`}
                        shouldUpdate={true}
                    />
                </div>
            </div>
        );
    }
}
