import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import authService from '../api-authorization/AuthorizeService';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import utils from '../../utils';
import InputGroup from 'react-bootstrap/InputGroup';
import { NumericFormat } from 'react-number-format';

const ProjectMetrics = (props) => {
    const [project] = useState(props.project);
    const [actual, setActual] = useState({
        projectID: project.id,
        startDate: '',
        completion: '',
        designerCosts: '',
        constructionCosts: '',
        otherSoftCosts: '',
        enablingCosts: '',
        totalProjectCosts: ''
    });
    const [metrics, setMetrics] = useState({
        assetFCI: 0.0,
        netEffectOfProjectOnFCI: 0.0,
        projectCapitalEfficiency: 0.0,
        deferredRenewalPercentage: 0.0,
        recurringCostsPercentage: 0.0
    });

    const [originalActual, setOriginalActual] = useState({});

    const [editMode, setEditMode] = useState(false);

    useEffect(() => {

        async function populate() {
            await populateItems();
        }

        populate();
    }, [project]);

    const populateItems = async () => {
        const token = await authService.getAccessToken();
        let response = await fetch(`api/projects/getActual?projectID=${project.id}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        let item = await response.json();
        if (item.hasOwnProperty('id')) {
            for (let key in item) {
                if (item[key] === null) item[key] = '';
            }
            if (item.startDate !== '') item.startDate = utils.getDateString(new Date(item.startDate));
            if (item.completion !== '') item.completion = utils.getDateString(new Date(item.completion));
            await setActual(item);
        }
        response = await fetch(`api/projects/getMetrics?projectId=${project.id}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        item = await response.json()
        await setMetrics(item);
    }

    const handleSave = async (event) => {
        event.preventDefault();

        const actualCopy = utils.getCopy(actual);
        for (let key in actualCopy) {
            if (actualCopy[key] === '') actualCopy[key] = null;
        }

        const token = await authService.getAccessToken();
        await fetch('api/projects/putActual', {
            method: 'PUT',
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(actualCopy)
        });

        await populateItems();
        await setEditMode(false);
    };

    return (
        <Container>
            <Card className="mb-2">
                <Card.Header>Metrics</Card.Header>
                <Card.Body>
                    <Row className="align-items-center">
                        <Col className="fw-bold col-3 p-2 text-middle">Asset FCI</Col>
                        <Col className="col-9 p-2">{metrics.assetFCI.toFixed(2)}</Col>
                    </Row>
                    <Row className="align-items-center">
                        <Col className="fw-bold col-3 p-2 text-middle">Net effect of project on FCI</Col>
                        <Col className="col-9 p-2">{metrics.netEffectOfProjectOnFCI === null ? 'N/A' : metrics.netEffectOfProjectOnFCI.toFixed(2)}</Col>
                    </Row>
                    <Row className="align-items-center">
                        <Col className="fw-bold col-3 p-2 text-middle">Project capital efficiency</Col>
                        <Col className="col-9 p-2">{metrics.projectCapitalEfficiency === null ? 'N/A' : utils.getPercentString(metrics.projectCapitalEfficiency)}</Col>
                    </Row>
                    <Row className="align-items-center">
                        <Col className="fw-bold col-3 p-2 text-middle">Deferred renewal percentage</Col>
                        <Col className="col-9 p-2">{metrics.deferredRenewalPercentage === null ? 'N/A' : utils.getPercentString(metrics.deferredRenewalPercentage)}</Col>
                    </Row>
                    <Row className="align-items-center">
                        <Col className="fw-bold col-3 p-2 text-middle">Recurring costs percentage</Col>
                        <Col className="col-9 p-2">{metrics.recurringCostsPercentage === null ? 'N/A' : utils.getPercentString(metrics.recurringCostsPercentage)}</Col>
                    </Row>
                </Card.Body>
            </Card>
            <Card className="mb-2">
                <Card.Header>KPIs</Card.Header>
                <Card.Body>
                    <Row className="align-items-center">
                        <Col className="fw-bold col-3 p-2 text-middle">Project duration metric</Col>
                        <Col className="col-9 p-2">{metrics.projectDurationMetric === null ? 'N/A' : utils.getPercentString(metrics.projectDurationMetric)}</Col>
                    </Row>
                    <Row className="align-items-center">
                        <Col className="fw-bold col-3 p-2 text-middle">Design contingency metric</Col>
                        <Col className="col-9 p-2">{metrics.designContingencyMetric === null ? 'N/A' : utils.getPercentString(metrics.designContingencyMetric)}</Col>
                    </Row>
                    <Row className="align-items-center">
                        <Col className="fw-bold col-3 p-2 text-middle">Construction contingency metric</Col>
                        <Col className="col-9 p-2">{metrics.constructionContingencyMetric === null ? 'N/A' : utils.getPercentString(metrics.constructionContingencyMetric)}</Col>
                    </Row>
                    <Row className="align-items-center">
                        <Col className="fw-bold col-3 p-2 text-middle">Design cost metric</Col>
                        <Col className="col-9 p-2">{metrics.designCostMetric === null ? 'N/A' : utils.getPercentString(metrics.designCostMetric)}</Col>
                    </Row>
                    <Row className="align-items-center">
                        <Col className="fw-bold col-3 p-2 text-middle">Total other soft costs metric</Col>
                        <Col className="col-9 p-2">{metrics.totalOtherSoftCostsMetric === null ? 'N/A' : utils.getPercentString(metrics.totalOtherSoftCostsMetric)}</Col>
                    </Row>
                    <Row className="align-items-center">
                        <Col className="fw-bold col-3 p-2 text-middle">Construction costs metric</Col>
                        <Col className="col-9 p-2">{metrics.constructionCostsMetric === null ? 'N/A' : utils.getPercentString(metrics.constructionCostsMetric)}</Col>
                    </Row>
                    <Row className="align-items-center">
                        <Col className="fw-bold col-3 p-2 text-middle">Enabling costs metric</Col>
                        <Col className="col-9 p-2">{metrics.enablingCostsMetric === null ? 'N/A' : utils.getPercentString(metrics.enablingCostsMetric)}</Col>
                    </Row>
                    <Row className="align-items-center">
                        <Col className="fw-bold col-3 p-2 text-middle">Project cost metric</Col>
                        <Col className="col-9 p-2">{metrics.projectCostMetric === null ? 'N/A' : utils.getPercentString(metrics.projectCostMetric)}</Col>
                    </Row>
                </Card.Body>
            </Card>
            <Card className="mb-2">
                <Card.Header>Planned vs. Actual</Card.Header>
                <Card.Body>
                    <Form noValidate onSubmit={handleSave}>
                        <Row className="align-items-center">
                            <Col className="fw-bold col-3 p-2 text-middle">Actual Project Start Date</Col>
                            <Col className="col-4 p-2">
                                <Form.Control type="date" value={actual.startDate} disabled={!editMode} min="1000-01-01" max="9999-12-31" onChange={(e) => {
                                    const item = utils.getCopy(actual);
                                    item.startDate = e.target.value;
                                    setActual(item);
                                }}>
                                </Form.Control>
                            </Col>
                        </Row>
                        <Row className="align-items-center">
                            <Col className="fw-bold col-3 p-2 text-middle">Actual Project Completion</Col>
                            <Col className="col-4 p-2">
                                <Form.Control type="date" value={actual.completion} disabled={!editMode} min="1000-01-01" max="9999-12-31" onChange={(e) => {
                                    const item = utils.getCopy(actual);
                                    item.completion = e.target.value;
                                    setActual(item);
                                }}>
                                </Form.Control>
                            </Col>
                        </Row>
                        <Row className="align-items-center">
                            <Col className="fw-bold col-3 p-2 text-middle">Actual Designer Costs</Col>
                            <Col className="col-4 p-2">
                                <InputGroup>
                                    <InputGroup.Text>$</InputGroup.Text>
                                    <NumericFormat className="form-control" decimalScale={2} fixedDecimalScale={true} value={actual.designerCosts}
                                        thousandSeparator="," disabled={!editMode} onValueChange={async (values, sourceInfo) => {
                                            const item = utils.getCopy(actual);
                                            item.designerCosts = values.floatValue;
                                            await setActual(item);
                                        }
                                        } />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row className="align-items-center">
                            <Col className="fw-bold col-3 p-2 text-middle">Actual Construction Costs</Col>
                            <Col className="col-4 p-2">
                                <InputGroup>
                                    <InputGroup.Text>$</InputGroup.Text>
                                    <NumericFormat className="form-control" decimalScale={2} fixedDecimalScale={true} value={actual.constructionCosts}
                                        thousandSeparator="," disabled={!editMode} onValueChange={async (values, sourceInfo) => {
                                            const item = utils.getCopy(actual);
                                            item.constructionCosts = values.floatValue;
                                            await setActual(item);
                                        }
                                        } />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row className="align-items-center">
                            <Col className="fw-bold col-3 p-2 text-middle">Actual Other Soft Costs</Col>
                            <Col className="col-4 p-2">
                                <InputGroup>
                                    <InputGroup.Text>$</InputGroup.Text>
                                    <NumericFormat className="form-control" decimalScale={2} fixedDecimalScale={true} value={actual.otherSoftCosts}
                                        thousandSeparator="," disabled={!editMode} onValueChange={async (values, sourceInfo) => {
                                            const item = utils.getCopy(actual);
                                            item.otherSoftCosts = values.floatValue;
                                            await setActual(item);
                                        }
                                        } />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row className="align-items-center">
                            <Col className="fw-bold col-3 p-2 text-middle">Actual Enabling Costs</Col>
                            <Col className="col-4 p-2">
                                <InputGroup>
                                    <InputGroup.Text>$</InputGroup.Text>
                                    <NumericFormat className="form-control" decimalScale={2} fixedDecimalScale={true} value={actual.enablingCosts}
                                        thousandSeparator="," disabled={!editMode} onValueChange={async (values, sourceInfo) => {
                                            const item = utils.getCopy(actual);
                                            item.enablingCosts = values.floatValue;
                                            await setActual(item);
                                        }
                                        } />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row className="align-items-center">
                            <Col className="fw-bold col-3 p-2 text-middle">Actual Total Project Costs</Col>
                            <Col className="col-4 p-2">
                                <InputGroup>
                                    <InputGroup.Text>$</InputGroup.Text>
                                    <NumericFormat className="form-control" decimalScale={2} fixedDecimalScale={true} value={actual.totalProjectCosts}
                                        thousandSeparator="," disabled={!editMode} onValueChange={async (values, sourceInfo) => {
                                            const item = utils.getCopy(actual);
                                            item.totalProjectCosts = values.floatValue;
                                            await setActual(item);
                                        }
                                        } />
                                </InputGroup>
                            </Col>
                        </Row>
                        {editMode ?
                            <Row className="p-2 mt-2">
                                <Col className="col-3">
                                    <Button type="submit">Save Changes</Button>
                                </Col>
                                <Col className="col-3">
                                    <a class="btn btn-secondary" onClick={async () => {
                                        await setActual(utils.getCopy(originalActual));
                                        await setEditMode(false);
                                    }}>Cancel</a>
                                </Col>
                            </Row>
                            :
                            <Row className="p-2 mt-2">
                                <Col className="col-3">
                                    <a class="btn btn-primary" onClick={async () => {
                                        await setOriginalActual(utils.getCopy(actual));
                                        await setEditMode(true);
                                    }}>
                                        Edit
                                    </a>
                                </Col>
                            </Row>
                        }
                    </Form>
                </Card.Body>
            </Card>
        </Container>
    );
}

export default ProjectMetrics