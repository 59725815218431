import ApiAuthorzationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { Assets } from "./components/Assets";
//import { default as Asset } from "./components/Asset";
import Asset from './components/Asset';
import Requirements from './components/Requirements';
import RecurringCosts from './components/RecurringCosts';
import RecurringCost from './components/RecurringCost';
import Requirement from './components/Requirement';
import Project from './components/project/Project';
import Settings from './components/settings/Settings'
import { Projects } from './components/project/Projects';
import { Navigate } from 'react-router-dom';
import AssetSystem from './components/AssetSystem';
import RequirementTemplate from './components/settings/RequirementTemplate';

const AppRoutes = [
    {
        index: true,
        requireAuth: true,
        element: <Navigate replace to='/assets' />
    },
    {
        path: '/assets/:id',
        requireAuth: true,
        element: <Asset />
    },
    {
        path: '/assets',
        requireAuth: true,
        element: <Assets />
    },
    {
        path: '/requirements',
        requireAuth: true,
        element: <Requirements />
    },
    {
        path: '/requirements/:id',
        requireAuth: true,
        element: <Requirement />
    },
    {
        path: '/recurringCosts',
        requireAuth: true,
        element: <RecurringCosts />
    },
    {
        path: '/recurringCosts/:id',
        requireAuth: true,
        element: <RecurringCost />
    },
    {
        path: '/projects',
        requireAuth: true,
        element: <Projects />
    },
    {
        path: '/projects/:id',
        requireAuth: true,
        element: <Project />
    },
    {
        path: '/settings',
        requireAuth: true,
        element: <Settings />
    },
    {
        path: '/assets/:assetId/system/:systemTypeId',
        requireAuth: true,
        element: <AssetSystem />
    },
    {
        path: '/settings/requirementTemplate/:id',
        requireAuth: true,
        element: <RequirementTemplate />
    },
    ...ApiAuthorzationRoutes
];

export default AppRoutes;
