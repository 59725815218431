import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import authService from '../api-authorization/AuthorizeService';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import utils from '../../utils';
import ItemsList from '../ItemsList.js';

const RequirementTemplate = () => {
    const { id } = useParams();

    const navigate = useNavigate();
    const updateList = useRef(true);

    const [loading, setLoading] = useState(true);
    const [validated, setValidated] = useState(false);
    const [requirementTemplate, setRequirementTemplate] = useState({});
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        if (loading) {
            async function populateItem() {
                const token = await authService.getAccessToken();
                const response = await fetch(`api/settings/requirementTemplate?id=${id}`, {
                    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                });
                const data = await response.json();

                await setRequirementTemplate(data);

                setLoading(false);
            }

            populateItem();
        }
    }, [loading, id]);

    const handleSave = async (event) => {
        const form = event.currentTarget;

        event.preventDefault();

        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
            return;
        }

        const token = await authService.getAccessToken();
        await fetch('api/settings/requirementTemplate', {
            method: 'PUT',
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(requirementTemplate),
        });

        navigate('/settings?tabKey=requirementTemplates');
    };

    const handleDelete = async () => {
        const token = await authService.getAccessToken();
        await fetch(`api/settings/requirementTemplate?id=${requirementTemplate.id}`, {
            method: 'DELETE',
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        });

        navigate('/settings?tabKey=requirementTemplates');
    };

    const handleDeleteCost = async (id) => {
        const costType = id.split("_")[0];
        const costId = id.split("_")[1];
        const token = await authService.getAccessToken();
        await fetch(`api/settings/requirementTemplate${costType}Cost?id=${costId}`, {
            method: 'DELETE',
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        });
        updateList.current = true;
    }

    const shouldUpdateList = () => {
        if (updateList.current) {
            updateList.current = false;
            return true;
        }
        return false;
    };

    const columns = [
        { id: 'costType', name: 'Cost Type', sortable: true, filter: "select", selectId: "costType", labelFormat: null, aggregate: false },
        { id: 'description', name: 'Description', sortable: true, filter: null, selectId: null, labelFormat: null, aggregate: false },
        { id: 'unit', name: 'Unit', sortable: true, filter: null, selectId: null, labelFormat: null, aggregate: false },
        { id: 'unitCost', name: 'Unit Cost', sortable: true, filter: null, selectId: null, labelFormat: 'dollar', aggregate: false },
        { id: 'deleteItem', name: '', sortable: false, filter: null, selectId: null, labelFormat: 'deleteItem', aggregate: false }
    ];

    const mapFunction = (q) => {
        return {
            id: q.id,
            costType: q.costType,
            description: q.description,
            unit: q.unit,
            unitCost: q.unitCost,
            deleteItem: true
        }
    };

    return loading ? (
        <p><em>Loading...</em></p>
    ) : (
        <React.Fragment>
            <div>
                <Card className="mb-2">
                    <Card.Body>
                        <Card.Title>
                            <Breadcrumb>
                                <Breadcrumb.Item href="/settings?tabKey=requirementTemplates">Settings</Breadcrumb.Item>
                                <Breadcrumb.Item active>Edit Requirement Template</Breadcrumb.Item>
                            </Breadcrumb>
                        </Card.Title>                        
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Body>
                        <Form noValidate validated={validated} onSubmit={handleSave}>
                                <Row className="align-items-center">
                                    <Col className="fw-bold col-2 p-2">
                                        <Form.Label>Name:</Form.Label>
                                    </Col>
                                    <Col className="col-10 p-2">
                                        <Form.Label>{requirementTemplate.name}</Form.Label>
                                    </Col>
                                </Row>
                                <Row className="align-items-center">
                                    <Col className="fw-bold col-2 p-2">
                                        <Form.Label>Last saved by:</Form.Label>
                                    </Col>
                                    <Col className="col-10 p-2">
                                        <Form.Label>{requirementTemplate.lastSavedBy}</Form.Label>
                                    </Col>
                                </Row>
                                <Row className="align-items-center">
                                    <Col className="fw-bold col-3 p-2">
                                        <Form.Label>Allow this template to be overwrriten:</Form.Label>
                                    </Col>
                                    <Col className="col-9 p-2">
                                        <Form.Check type="switch" checked={requirementTemplate.overwrite} onChange={async (e) => {
                                            const item = utils.getCopy(requirementTemplate);
                                            item.overwrite = e.target.checked;
                                            await setRequirementTemplate(item);
                                        }} />
                                    </Col>
                                </Row>
                                <Row className="align-items-center">
                                    <Col className="fw-bold col-4 p-2">
                                        <Form.Label>Description</Form.Label>
                                    </Col>
                                    <Col className="fw-bold col-4 p-2">
                                        <Form.Label>Proposed Action</Form.Label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="col-4 p-2">
                                        <Form.Control as="textarea" required value={requirementTemplate.description} rows={4} onChange={(e) => {
                                            const item = utils.getCopy(requirementTemplate);
                                            item.description = e.target.value;
                                            setRequirementTemplate(item);
                                        }} />
                                    </Col>
                                    <Col className="col-4 p-2">
                                        <Form.Control as="textarea" required value={requirementTemplate.action} rows={4} onChange={(e) => {
                                            const item = utils.getCopy(requirementTemplate);
                                            item.action = e.target.value;
                                            setRequirementTemplate(item);
                                        }} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="fw-bold col-4 p-2">
                                        <Form.Label>Costs</Form.Label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="col-12 p-2">
                                        <ItemsList columns={columns}
                                            itemName="Cost"
                                            addItemUrl=""
                                            mapFunction={mapFunction}
                                            getItemsUrl={`api/settings/requirementTemplateCosts?id=${id}`}
                                            uniqueIdColumn="id"
                                            showHeader={false}
                                            itemUrlBase=""
                                            shouldUpdate={true}
                                            shouldUpdateList={shouldUpdateList}
                                            deleteItemMethod={handleDeleteCost}
                                        />
                                    </Col>
                                </Row>
                            <Row className="p-3">
                                <Col className="col-2">
                                    <Button type="submit">Save Changes</Button>
                                </Col>
                                <Col className="col-2">
                                    <Button variant="outline-primary" onClick={() => {
                                            navigate('/settings?tabKey=requirementTemplates');
                                    }}>Cancel</Button>
                                </Col>
                                <Col className="col-4 text-end">
                                    <Button variant="outline-danger" onClick={() => {
                                        setShowDeleteModal(true);
                                    }}>Delete</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Card.Body>
                </Card>               
                <Modal size="sm" show={showDeleteModal} onHide={() => { setShowDeleteModal(false) }} backdrop="static">
                    <Modal.Header closeButton>
                        <Modal.Title>Delete</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to delete this requirement template?</Modal.Body>
                    <Modal.Footer>
                        <Container>
                            <Row>
                                <Col className="col-6">
                                    <Button onClick={() => {
                                        setShowDeleteModal(false);
                                        handleDelete();
                                    }}>Yes</Button>
                                </Col>
                                <Col className="col-6 text-end">
                                    <Button variant="secondary" onClick={() => { setShowDeleteModal(false) }}>No</Button>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Footer>
                </Modal>
            </div>
        </React.Fragment>
    );
}

export default RequirementTemplate;
