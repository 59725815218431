import React, { Component } from 'react';
import authService from '../api-authorization/AuthorizeService'
import 'bootstrap-icons/font/bootstrap-icons.css';
import ItemsList from '../ItemsList.js';
import { GanttList } from '../GanttList.js';
import Card from 'react-bootstrap/Card';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import Tab from 'react-bootstrap/Tab';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';

export class Projects extends Component {
    static displayName = Projects.name;

    constructor(props) {
        super(props);
        const d = new Date();
        let minYear = d.getFullYear();

        this.state = {
            key: "allProjects",
            years: [],
            columns2: [{ id: 'projectTitle', name: 'Project Title', sortable: true, filter: 'search', selectId: null, labelFormat: 'linkToItem', aggregate: false, minWidth: '120px' },
                { id: 'value', name: 'Value', sortable: true, filter: null, selectId: null, labelFormat: 'dollar', aggregate: true },
                { id: 'startYear', name: 'Fiscal Start Year', sortable: true, filter: null, selectId: null, labelFormat: 'textBox', aggregate: false, defaultFilter: '', min: minYear, max: minYear + 10, minWidth: '100px' },
                { id: 'quarter', name: 'Start Quarter', sortable: false, filter: null, selectId: null, labelFormat: 'select', aggregate: false, selections: ["Q1", "Q2", "Q3", "Q4"], minWidth: '100px' },
                { id: 'duration', name: 'Duration (months)', sortable: true, filter: null, selectId: null, labelFormat: 'textBox', aggregate: false, min: 1, max: 120, minWidth: '80px' },
            ],
            secondaryItems: [],
        };

        this.getSecondaryItems();
    }

    componentDidMount() {

    }

    async getSecondaryItems() {
        const token = await authService.getAccessToken();
        const response = await fetch("api/capitalplans", {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const items = await response.json();

        const d = new Date();
        let minYear = d.getFullYear();
        let maxYear = d.getFullYear();

        items.forEach((item, index) => {

            if (index === 0) {
                minYear = item.fiscalYear;
                maxYear = item.fiscalYear;
            } else {
                maxYear = item.fiscalYear;
            }
        })

        for (let curYear = minYear; curYear <= maxYear; curYear++) {
            this.state.years.push(curYear);
        }

        this.state.secondaryItems.push(
            { id: 'subtable1', duration: 'Funding',  },
            { id: 'subtable2', duration: 'Total submitted projects' },
            { id: 'subtable3', duration: 'Over/under budget' },
            { id: 'subtable4', duration: 'Total on-hold projects' },
            { id: 'subtable5', duration: 'Total draft projects' },
        );

        this.state.years.forEach((year, index) => {
            this.state.columns2.push({ id: 'year' + index, name: year, sortable: false, filter: null, selectId: null, labelFormat: 'html', aggregate: false, defaultFilter: '' });

            this.state.secondaryItems.forEach(item => {
                if (item.duration === 'Funding') {
                    item['year' + index] = items.filter(item => item.fiscalYear === year).reduce((accumulator, curValue) =>
                        accumulator + curValue.investmentAmount, 0,
                    );
                } else {
                    item['year' + index] = 0;
                }
            })
        })
    }

    render() {
        const columns = [
            { id: 'projectTitle', name: 'Project Title', sortable: true, filter: 'search', selectId: null, labelFormat: 'linkToItem', aggregate: false },
            { id: 'type', name: 'Type', sortable: true, filter: 'select', selectId: 'type', labelFormat: null, aggregate: false },
            { id: 'description', name: 'Description', sortable: true, filter: null, selectId: null, labelFormat: null, aggregate: false },
            { id: 'value', name: 'Value', sortable: true, filter: null, selectId: null, labelFormat: 'dollar', aggregate: true },
            { id: 'startYear', name: 'Fiscal Start Year', sortable: true, filter: 'yearRange', selectId: null, labelFormat: null, aggregate: false, defaultFilter: '' },
            { id: 'duration', name: 'Duration (months)', sortable: true, filter: null, selectId: null, labelFormat: null, aggregate: false },
            { id: 'status', name: 'Status', sortable: true, filter: 'select', selectId: 'statusId', labelFormat: null, aggregate: false },
            { id: 'lastUpdated', name: 'Last Updated', sortable: true, filter: null, selectId: null, labelFormat: 'date', aggregate: false },
        ];

        const mapFunction = (q) => {
            return {
                id: q.id,
                projectTitle: q.projectTitle,
                type: q.type,
                typeID: q.type,
                description: q.description,
                value: q.value,
                startYear: q.startYear,
                duration: q.duration,
                status: q.status,
                statusId: q.statusID,
                lastUpdated: new Date(q.lastUpdated),
            }
        }

        const mapFunction2 = (q) => {
            let durationQuarters = Math.ceil(q.duration / 3);
            let startQuarter = Number(q.quarter.replace("Q", ""));
            let remainingQuarters = durationQuarters;
            let quarterIndex = startQuarter - 1;

            let mappedFields = {
                id: q.id,
                projectTitle: q.projectTitle,
                type: q.type,
                typeID: q.type,
                description: q.description,
                value: q.value,
                startYear: q.startYear,
                quarter: q.quarter,
                duration: q.duration,
                status: q.status,
                statusId: q.statusID,
                lastUpdated: new Date(q.lastUpdated),
            };

            this.state.columns2.forEach(c => {
                if (c.id.startsWith("year")) {
                    let year = Number(c.name);

                    if (year >= q.startYear && remainingQuarters > 0) {
                        let blocks = "";
                        if (quarterIndex > 0) {
                            blocks = "<i class='bi bi-square' style='color: lightgray;'></i>".repeat(quarterIndex);
                        }

                        while (quarterIndex < 4) {

                            if (remainingQuarters > 0) {
                                blocks += "<i class='bi bi-square-fill' style='color: #003DB8;'></i>";
                            } else {
                                blocks += "<i class='bi bi-square' style='color: lightgray;'></i>";
                            }

                            remainingQuarters--;
                            quarterIndex++;
                        }

                        quarterIndex = 0;

                        q[c.id] = blocks;
                    } else {
                        q[c.id] = "<i class='bi bi-square' style='color: lightgray;'></i>".repeat(4);
                    }

                    mappedFields[c.id] = q[c.id];
                }
            });

            return mappedFields;
        }

        const handleOnSelect = (key) => {
            this.setState({ key });
        }

        const handleOnChange = (item, columnID, value) => {
            item[columnID] = value;

            let startQuarter = Number(item.quarter.replace("Q", ""));
            let remainingQuarters = Math.ceil(item.duration / 3);
            let quarterIndex = startQuarter - 1;

            this.state.columns2.forEach(c => {
                if (c.id.startsWith("year")) {
                    let year = Number(c.name);

                    if (year >= item.startYear && remainingQuarters > 0) {
                        let blocks = "";
                        if (quarterIndex > 0) {
                            blocks = "<i class='bi bi-square' style='color: lightgray;'></i>".repeat(quarterIndex);
                        }

                        while (quarterIndex < 4) {

                            if (remainingQuarters > 0) {
                                blocks += "<i class='bi bi-square-fill' style='color: #003DB8;'></i>";
                            } else {
                                blocks += "<i class='bi bi-square' style='color: lightgray;'></i>";
                            }

                            remainingQuarters--;
                            quarterIndex++;
                        }

                        quarterIndex = 0;

                        item[c.id] = blocks;
                    } else {
                        item[c.id] = "<i class='bi bi-square' style='color: lightgray;'></i>".repeat(4);
                    }
                }
            });
        }

        return (
            <div>
                <div class="card mb-2">
                    <div class="card-body">
                        <span class="h5">Projects</span>
                    </div>
                </div>
                <Card>
                    <Tab.Container id="scenario-tabs" defaultActiveKey="allProjects" activeKey={this.state.key}>
                        <Card.Header>
                            <Stack direction="horizontal" gap={3}>
                                <ToggleButtonGroup name="projects" type="radio" defaultValue={1}>
                                    <ToggleButton id="tb1" variant={this.checked ? 'primary' : 'outline-primary'} value={1} onClick={() => handleOnSelect("allProjects")} >
                                            All Projects
                                    </ToggleButton>
                                    <ToggleButton id="tb2" variant={this.checked ? 'primary' : 'outline-primary'} value={2} onClick={() => handleOnSelect("capitalPlan")} >
                                            Capital Plan
                                    </ToggleButton>
                                    </ToggleButtonGroup>
                                <Button href="projects/add" variant="primary" className="ms-auto" >Add Project</Button>
                            </Stack>
                        </Card.Header>
                        <Card.Body>
                            <Tab.Content>
                                <Tab.Pane eventKey="allProjects">
                                    <ItemsList columns={columns}
                                        itemName="Project"
                                        addItemUrl="projects/add"
                                        mapFunction={mapFunction}
                                        getItemsUrl="api/projects"
                                        uniqueIdColumn="id"
                                        showHeader={false}
                                        itemUrlBase="projects"
                                        shouldUpdate={true}
                                    />
                                </Tab.Pane>
                                <Tab.Pane eventKey="capitalPlan">
                                    <GanttList columns={this.state.columns2}
                                        itemName="Project"
                                        addItemUrl="projects/add"
                                        mapFunction={mapFunction2}
                                        secondaryItems={this.state.secondaryItems}
                                        getItemsUrl="api/projects"
                                        uniqueIdColumn="id"
                                        showHeader={false}
                                        showFooter={false}
                                        itemUrlBase="projects"
                                        onChangeMethod={handleOnChange}
                                    />
                                </Tab.Pane>
                            </Tab.Content>
                        </Card.Body>
                    </Tab.Container>
                </Card>
            </div>
        );
    }
}