import React, { Component } from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './Assets.css';
import ItemsList from './ItemsList.js';

export class Assets extends Component {
    static displayName = Assets.name;

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const columns = [
            { id: 'code', name: 'Code', sortable: true, filter: null, selectId: null, labelFormat: null, aggregate: false },
            { id: 'facilityName', name: 'Facility Name', sortable: true, filter: 'search', selectId: null, labelFormat: 'linkToItem', aggregate: false },
            { id: 'campus', name: 'Campus', sortable: true, filter: 'select', selectId: 'campusId', labelFormat: null, aggregate: false },
            { id: 'facilityType', name: 'Facility Type', sortable: true, filter: 'select', selectId: 'facilityTypeId', labelFormat: null, aggregate: false },
            { id: 'fci3', name: 'FCI (3)', sortable: true, filter: null, selectId: null, labelFormat: null, aggregate: false },
            { id: 'fci5', name: 'FCI (5)', sortable: true, filter: null, selectId: null, labelFormat: null, aggregate: false },
            { id: 'fci10', name: 'FCI (10)', sortable: true, filter: null, selectId: null, labelFormat: null, aggregate: false },
            { id: 'totalReq', name: 'Total Req (1-3)', sortable: true, filter: null, selectId: null, labelFormat: 'dollar', aggregate: true },
            { id: 'totalRecurring', name: 'Total Recurring', sortable: true, filter: null, selectId: null, labelFormat: 'dollar', aggregate: true }
        ];

        const mapFunction = (q) => {
            return {
                id: q.id,
                code: q.code,
                facilityName: q.facilityName,
                campus: q.campus,
                campusId: q.campusID,
                facilityType: q.facilityType,
                facilityTypeId: q.facilityTypeID,
                fci3: q.fcI3.toFixed(2),
                fci5: q.fcI5.toFixed(2),
                fci10: q.fcI10.toFixed(2),
                totalReq: q.totalRequirements,
                totalRecurring: q.totalRecurring
            }
        }

        return (
            <div>
                <div class="card mb-2">
                    <div class="card-body">
                        <span class="h5">Assets</span>
                    </div>
                </div>
                <ItemsList columns={columns}
                    itemName="Asset"
                    addItemUrl="assets/add"
                    mapFunction={mapFunction}
                    getItemsUrl="api/assets"
                    uniqueIdColumn="code"
                    showHeader={true}
                    itemUrlBase="assets"
                    shouldUpdate={true}
                />
            </div>
        );
    }
}
