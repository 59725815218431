import React, { useEffect, useState, useRef } from 'react';
import authService from './api-authorization/AuthorizeService';
import Button from 'react-bootstrap/Button';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './ItemsList.css';
import utils from '../utils'
import { XCircle } from 'react-bootstrap-icons';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

const ItemsList = (props) => {
    let itemUrl = `${props.itemUrlBase}/<itemId>`;
    if (props.itemUrlParams != null && props.itemUrlParams.trim().length > 0) {
        itemUrl = `${itemUrl}?${props.itemUrlParams}`;
    }

    const loaded = useRef(false);
    const [compObj, setCompObj] = useState({
        items: [],
        itemUrl: itemUrl,
        itemsForDisplay: [],
        sortColumn: props.uniqueIdColumn,
        sortDirection: 'asc',
        pages: 1,
        pageNumber: 1,
        pageSize: 10,
        selectValues: props.columns.filter(q => q.filter === 'select').reduce((a, q) => (a[q.id] = '-1', a), {}),
        selectIds: props.columns.filter(q => q.filter === 'select').reduce((a, q) => (a[q.id] = q.selectId, a), {}),
        selectOptions: props.columns.filter(q => q.filter === 'select').reduce((a, q) => (a[q.id] = [], a), {}),
        searchValues: props.columns.filter(q => q.filter === 'search').reduce((a, q) => (a[q.id] = '', a), {}),
        dateRangeValues: props.columns.filter(q => q.filter === 'dateRange').reduce((a, q) => (a[q.id] = { from: '', to: '' }, a), {}),
        yearRangeValues: props.columns.filter(q => q.filter === 'yearRange').reduce((a, q) => (a[q.id] = { from: q.defaultFilter, to: q.defaultFilter }, a), {}),
        filteredItems: []
    });
    const [deleteItemId, setDeleteItemId] = useState(0);
    const [showDeleteModal, setShowDeleteModal] = useState(false);


    useEffect(() => {
        const updateList = props.shouldUpdateList && props.shouldUpdateList();
        if (updateList || !loaded.current) {
            const initialize = async () => {
                await getItems();
                loaded.current = true;
            };
            initialize();
        }
    });

    const sort = async (column) => {
        const tempObj = utils.getCopy(compObj);
        tempObj.sortColumn = column;
        tempObj.sortDirection = 'asc';
        const icon = document.getElementById(`column_${column}`);
        if (icon.classList.contains('bi-chevron-up')) {
            tempObj.sortDirection = 'desc';
        }
        await populateItemsForDisplay(tempObj);
    };

    const goToPage = async (pageNumber) => {
        const tempObj = utils.getCopy(compObj);
        tempObj.pageNumber = pageNumber;
        await populateItemsForDisplay(tempObj);
    };

    const updatePageSize = async (event) => {
        const tempObj = utils.getCopy(compObj);
        tempObj.pageSize = event.target.value;
        tempObj.pageNumber = 1;
        await populateItemsForDisplay(tempObj);
    };

    const search = async (event) => {
        if (event.key === 'Enter') {
            const tempObj = utils.getCopy(compObj);
            const key = event.target.id.substring(7);
            tempObj.searchValues[key] = event.target.value;
            await populateItemsForDisplay(tempObj);
        }
    }

    const clearSearch = async (event) => {
        if (event.target.value === '') {
            const tempObj = utils.getCopy(compObj);
            const key = event.target.id.substring(7);
            tempObj.searchValues[key] = '';
            await populateItemsForDisplay(tempObj);
        }
    }

    const updateSelect = async (event) => {
        const tempObj = utils.getCopy(compObj);
        const key = event.target.id.substring(7);
        tempObj.selectValues[key] = event.target.value;
        tempObj.pageNumber = 1;
        await populateItemsForDisplay(tempObj);
    };

    const updateDateRange = async (event) => {
        const tempObj = utils.getCopy(compObj);
        if (event.target.id.startsWith('dateRange_from')) {
            // from range was updated
            const key = event.target.id.substring(15);
            tempObj.dateRangeValues[key]['from'] = event.target.value;
            const toDateElement = document.getElementById(`dateRange_to_${key}`);
            toDateElement.min = event.target.value;
        } else {
            // to range was updated
            const key = event.target.id.substring(13);
            tempObj.dateRangeValues[key]['to'] = event.target.value;
            const fromDateElement = document.getElementById(`dateRange_from_${key}`);
            fromDateElement.max = event.target.value;
        }
        tempObj.pageNumber = 1;
        await populateItemsForDisplay(tempObj);
    }

    const updateYearRange = async (event) => {
        const tempObj = utils.getCopy(compObj);
        const value = event.target.value === '-1' ? '' : event.target.value;
        if (event.target.id.startsWith('yearRange_from')) {
            // from range was updated
            const key = event.target.id.substring(15);
            tempObj.yearRangeValues[key]['from'] = value;
        } else {
            // to range was updated
            const key = event.target.id.substring(13);
            tempObj.yearRangeValues[key]['to'] = value;
        }
        tempObj.pageNumber = 1;
        await populateItemsForDisplay(tempObj);
    }

    const getSum = (values, labelFormat) => {
        let sum = 0;
        if (values.length > 0) {
            sum = values.reduce(function (a, b) {
                return a + b;
            });
        }
        return getLabel(sum, labelFormat);
    };

    const getLabel = (value, labelFormat) => {
        if (labelFormat === 'dollar') {
            return utils.getDollarString(value);
        } else if (labelFormat === 'date') {
            if (value instanceof Date) {
                return value.toLocaleDateString();
            } else {
                return (new Date(value)).toLocaleDateString();
            }
        }
        return value;
    };

    let minPage = compObj.pageNumber === 1 ? 1 : compObj.pageNumber - 1;
    const maxPage = compObj.pages < minPage + 2 ? compObj.pages : minPage + 2;
    if (maxPage === compObj.pageNumber && maxPage > 2) {
        minPage = maxPage - 2;
    }
    let pageNumbers = [];
    for (let i = minPage - 1; i < maxPage; i++) {
        pageNumbers.push(i + 1);
    }

    const currentYear = new Date().getFullYear();
    const years = [];
    for (let y = currentYear; y <= currentYear + 30; y++) {
        years.push(y);
    }

    const getItems = async () => {
        const tempObj = utils.getCopy(compObj);
        tempObj.items = [];
        tempObj.loading = true;
        const token = await authService.getAccessToken();
        const response = await fetch(props.getItemsUrl, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const json = await response.json();
        tempObj.items = json.map(props.mapFunction);
        tempObj.pages = Math.ceil(tempObj.items.length / tempObj.pageSize);
        tempObj.loading = false;     
        await populateItemsForDisplay(tempObj);
    }

    const populateItemsForDisplay = async (tempObj) => {
        let filteredItems = [...tempObj.items];
        for (const key in tempObj.searchValues) {
            filteredItems = filteredItems.filter(q => tempObj.searchValues[key] === '' || q[key].toUpperCase().includes(tempObj.searchValues[key].toUpperCase()));
        }
        for (const key in tempObj.selectValues) {
            filteredItems = filteredItems.filter(q => tempObj.selectValues[key] === '-1' || q[tempObj.selectIds[key]].toString() === tempObj.selectValues[key]);
        }
        for (const key in tempObj.dateRangeValues) {
            filteredItems = filteredItems.filter(q => tempObj.dateRangeValues[key]['from'] === '' || q[key].getTime() >= new Date(`${tempObj.dateRangeValues[key]['from']}T00:00:00`).getTime());
            filteredItems = filteredItems.filter(q => tempObj.dateRangeValues[key]['to'] === '' || q[key].getTime() <= new Date(`${tempObj.dateRangeValues[key]['to']}T00:00:00`).getTime());
        }
        for (const key in tempObj.yearRangeValues) {
            filteredItems = filteredItems.filter(q => tempObj.yearRangeValues[key]['from'] === '' || q[key] >= tempObj.yearRangeValues[key]['from']);
            filteredItems = filteredItems.filter(q => tempObj.yearRangeValues[key]['to'] === '' || q[key] <= tempObj.yearRangeValues[key]['to']);
        }

        tempObj.itemsForDisplay = filteredItems.sort((a, b) => {
            let aComp = a[tempObj.sortColumn], bComp = b[tempObj.sortColumn];
            if (typeof (aComp) === 'string') {
                aComp = aComp.toUpperCase();
                bComp = bComp.toUpperCase();
            }
            if (aComp instanceof Date) {
                aComp = aComp.getTime();
                bComp = bComp.getTime();
            }
            if (aComp < bComp) {
                return tempObj.sortDirection === 'asc' ? -1 : 1;
            }
            if (aComp > bComp) {
                return tempObj.sortDirection === 'asc' ? 1 : -1;
            }
            return 0;
        }).slice(tempObj.pageSize * (tempObj.pageNumber - 1), tempObj.pageSize * tempObj.pageNumber);        

        for (const key in tempObj.selectOptions) {
            tempObj.selectOptions[key] = getDistinctItems(filteredItems.map((q) => { return { id: q[tempObj.selectIds[key]], label: q[key] } }));
        }
        tempObj.pages = Math.ceil(filteredItems.length / tempObj.pageSize);

        tempObj.filteredItems = [...filteredItems];

        setCompObj(tempObj);
    }

    const getDistinctItems = (items) => {
        const ids = [];
        return items.filter((q) => {
            if (ids.indexOf(q.id) >= 0) return false;
            ids.push(q.id);
            return true;
        }).sort((a, b) => {
            if (a.label.toUpperCase() < b.label.toUpperCase()) return -1;
            if (a.label.toUpperCase() > b.label.toUpperCase()) return 1;
            return 0;
        });
    }

    return (
        <div class="card">
            {props.showHeader &&
                <div class="card-header d-md-flex justify-content-md-end">
                    {(props.addItemMethod === undefined || props.addItemMethod === null) ?
                        <a href={props.addItemUrl} class="btn btn-primary">Add {props.itemName}</a>
                        :
                        <Button type="button" variant="primary" onClick={props.addItemMethod}>Add {props.itemName}</Button>
                    }
                </div>
            }
            <div class="card-body">
                <table className="table table-striped" aria-labelledby="tableLabel">
                    <thead>
                        <tr key="columnHeaders">
                            {props.columns.map(c =>
                                <th key={`column_${c.id}`} className="sortColumnHeader" onClick={() => { if (c.sortable) { sort(c.id) } }}>
                                    {c.name} <i id={`column_${c.id}`} className={`${compObj.sortColumn === c.id ? (compObj.sortDirection === 'asc' ? 'bi bi-chevron-up' : 'bi bi-chevron-down') : ''}`}></i>
                                </th>
                            )}
                        </tr>
                        <tr key="columnFilters">
                            {props.columns.map(c =>
                                <th key={`select_${c.id}`}>
                                    {c.filter === 'search' && <input id={`search_${c.id}`} type="search" class="form-control" placeholder="Search" onKeyDown={search} onChange={clearSearch} defaultValue={compObj.searchValues[c.id]} />}
                                    {c.filter === 'select' && <select id={`select_${c.id}`} class="form-select" aria-label="" value={compObj.selectValues[c.id]} onChange={updateSelect}>
                                        <option key="-1" value="-1">All</option>
                                        {compObj.selectOptions[c.id].map(o => <option key={`select_${c.id}_${o.id}`} value={o.id}>{o.label}</option>)}
                                    </select>
                                    }
                                    {c.filter === 'dateRange' &&
                                        <div class="container-sm">
                                            <div class="row align-items-start">
                                                <div class="col-5">
                                                    <input id={`dateRange_from_${c.id}`} type="date" class="form-control" onChange={updateDateRange}></input>
                                                </div>
                                                <div class="col-5">
                                                    <input id={`dateRange_to_${c.id}`} type="date" class="form-control" onChange={updateDateRange}></input>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {c.filter === 'yearRange' &&
                                        <div class="container-sm">
                                            <div class="row align-items-start">
                                                <div class="col-5">
                                                    <select id={`yearRange_from_${c.id}`} class="form-select" onChange={updateYearRange} value={compObj.yearRangeValues[c.id]['from']}>
                                                        <option value="" key="from">From</option>
                                                        <option value="-1" key="from_all">All</option>
                                                        {years.map(year => <option value={year} key={`from_${year}`} disabled={compObj.yearRangeValues[c.id]['to'] !== '' && year > compObj.yearRangeValues[c.id]['to']}>{year}</option>)}
                                                    </select>
                                                </div>
                                                <div class="col-5">
                                                    <select id={`yearRange_to_${c.id}`} class="form-select" onChange={updateYearRange} value={compObj.yearRangeValues[c.id]['to']}>
                                                        <option value="" key="to">To</option>
                                                        <option value="-1" key="to_all">All</option>
                                                        {years.map(year => <option value={year} key={`to_${year}`} disabled={compObj.yearRangeValues[c.id]['from'] !== '' && year < compObj.yearRangeValues[c.id]['from']}>{year}</option>)}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {compObj.itemsForDisplay.map(item =>
                            <tr key={item[props.uniqueIdColumn]}>
                                {props.columns.map(column =>
                                    <td key={column.id} className={column.labelFormat === 'small' ? 'small-text' : ''}>                                        
                                        {column.labelFormat === 'linkToItem' && <a href={itemUrl.replace('<itemId>', item.id)}>{item[column.id]}</a>}
                                        {column.labelFormat === 'clickToItem' && <a class="link" style={{ cursor: "pointer" }} onClick={(e) => props.editItemMethod(`${item.id}`, e)}>{item[column.id]}</a>}
                                        {column.labelFormat === 'linkToProject' && <a href={`projects/${item['projectId']}`}>{item['project']}</a>}
                                        {column.labelFormat === 'deleteItem' && <Button variant="link" onClick={() => {
                                            setDeleteItemId(item.id);
                                            setShowDeleteModal(true);
                                        }}><XCircle color="red" /></Button>}
                                        {column.labelFormat !== 'linkToItem' && column.labelFormat !== 'clickToItem' && column.labelFormat !== 'linkToProject' && column.labelFormat !== 'deleteItem' && getLabel(item[column.id], column.labelFormat)}
                                    </td>
                                )}
                            </tr>
                        )}
                    </tbody>
                    <tfoot>
                        {props.columns.find(q => q.aggregate) !== undefined &&
                            <tr key="footer"> {
                                props.columns.map(c =>
                                    <th key={c.id}>
                                        {c.id === props.uniqueIdColumn && 'Total:'}
                                        {c.aggregate && getSum(compObj.filteredItems.map(q => q[c.id]), c.labelFormat)}
                                    </th>
                                )
                            }
                            </tr>
                        }
                    </tfoot>
                </table>
            </div>
            <div class="card-footer">
                <div class="row justify-content-center justify-content-sm-between align-items-sm-center">
                    <div class="col-sm mb-2 mb-sm-0">
                        <div class="d-flex justify-content-center justify-content-sm-start align-items-center">
                            <select class="js-select form-select form-select-borderless w-auto" aria-label="" onChange={updatePageSize} value={compObj.pageSize}>
                                <option value="10" key="ps_10">10</option>
                                <option value="20" key="ps_20">20</option>
                                <option value="30" key="ps_30">30</option>
                                <option value="50" key="ps_50">50</option>
                            </select>
                            <span>&nbsp;Items per page</span>
                        </div>
                    </div>
                    <div class="col-sm-auto">
                        <nav aria-label="Page navigation example">
                            <ul class="pagination">
                                <li key="prevPage" className={`page-item ${compObj.pageNumber === 1 ? 'disabled' : ''}`} >
                                    <a class="page-link" aria-label="Previous" onClick={() => { goToPage(compObj.pageNumber - 1) }}>
                                        <span aria-hidden="true">&laquo;</span>
                                    </a>
                                </li>
                                {pageNumbers.map(pageNumber =>
                                    <li key={pageNumber} className={`page-item ${pageNumber === compObj.pageNumber ? 'active' : ''}`}>
                                        <a class="page-link" onClick={() => { goToPage(pageNumber) }}>{pageNumber}</a>
                                    </li>
                                )}
                                <li key="nextPage" className={`page-item ${compObj.pageNumber === compObj.pages ? 'disabled' : ''}`}>
                                    <a class="page-link" aria-label="Next" onClick={() => { goToPage(compObj.pageNumber + 1) }}>
                                        <span aria-hidden="true">&raquo;</span>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
            <Modal size="sm" show={showDeleteModal} onHide={() => { setShowDeleteModal(false) }} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Delete {props.itemName}</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to remove this {props.itemName}?</Modal.Body>
                <Modal.Footer>
                    <Container>
                        <Row>
                            <Col className="col-6">
                                <Button onClick={async () => {                                    
                                    await props.deleteItemMethod(deleteItemId);
                                    await setShowDeleteModal(false);
                                }}>Yes</Button>
                            </Col>
                            <Col className="col-6 text-end">
                                <Button variant="secondary" onClick={() => { setShowDeleteModal(false) }}>No</Button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default ItemsList;