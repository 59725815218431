import React, { useEffect, useState } from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import ItemsList from './ItemsList.js';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import authService from './api-authorization/AuthorizeService'

const Requirements = () => {

    const [loading, setLoading] = useState(true);
    const [requirementReportUrl, setRequirementReportUrl] = useState('');

    useEffect(() => {
        if (loading) {
            async function populateItem() {
                const token = await authService.getAccessToken();
                const response = await fetch('api/requirements/reportUrl', {
                    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                });

                const url = await response.text();
                await setRequirementReportUrl(url);
                await setLoading(false);
            }
            populateItem();
        }
    }, [loading]);

    const columns = [
        { id: 'reqId', name: 'Req. ID', sortable: true, filter: null, selectId: null, labelFormat: 'linkToItem', aggregate: false },
        { id: 'facilityName', name: 'Facility Name', sortable: true, filter: 'search', selectId: null, labelFormat: null, aggregate: false },
        { id: 'system', name: 'System', sortable: true, filter: 'select', selectId: 'systemId', labelFormat: null, aggregate: false },
        { id: 'type', name: 'Type', sortable: true, filter: 'select', selectId: 'typeId', labelFormat: null, aggregate: false },
        { id: 'requirement', name: 'Requirement', sortable: true, filter: null, selectId: null, labelFormat: null, aggregate: false },
        { id: 'action', name: 'Action', sortable: true, filter: null, selectId: null, labelFormat: null, aggregate: false },
        { id: 'status', name: 'Status', sortable: true, filter: 'select', selectId: 'statusId', labelFormat: null, aggregate: false },
        { id: 'priority', name: 'Priority', sortable: true, filter: 'select', selectId: 'priorityId', labelFormat: null, aggregate: false },
        { id: 'project', name: 'Project', sortable: true, filter: null, selectId: null, labelFormat: 'linkToProject', aggregate: false },
        { id: 'cost', name: 'Cost', sortable: true, filter: null, selectId: null, labelFormat: 'dollar', aggregate: true },
    ];

    const mapFunction = (q) => {
        return {
            id: q.id,
            reqId: q.id,
            facilityName: q.facilityName,
            system: q.system,
            systemId: q.systemID,
            type: q.type,
            typeId: q.typeID,
            requirement: q.requirement,
            action: q.action,
            status: q.status,
            statusId: q.statusID,
            priority: q.priority,
            priorityId: q.priorityID,
            project: q.project,
            projectId: q.projectID,
            cost:q.cost
        }
    }

    return (
        <div>
            <div class="card mb-2">
                <div class="card-body">
                    <Row>
                        <Col>
                            <span class="h5">Requirements</span>
                        </Col>
                        <Col className="text-end">
                            <a href={requirementReportUrl} rel="noreferrer" target="_blank">View Requirement Report</a>
                        </Col>
                    </Row>     
                </div>
            </div>
            <ItemsList columns={columns}
                itemName="Requirement"
                addItemUrl="requirements/add"
                mapFunction={mapFunction}
                getItemsUrl="api/requirements"
                uniqueIdColumn="reqId"
                showHeader={true}
                itemUrlBase="requirements"
                shouldUpdate={true}
            />
        </div>
    );
}

export default Requirements;